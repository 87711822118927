<template>
  <VChart
      v-if="!loading"
      :option="pie"
      :update-options="{
      notMerge: true,
      lazyUpdate: true,
      silent: true
    }"
      autoresize
      :loading="loading"
      ref="echart"
      @click="onClickSegment"
  />
</template>

<script>
import { use } from "echarts/core"
import { SVGRenderer } from "echarts/renderers"
import { PieChart } from "echarts/charts"
import { GraphicComponent, LegendComponent, TooltipComponent, TitleComponent } from 'echarts/components'
import VChart from "vue-echarts"
import Color from "color"
import { mapGetters } from 'vuex'

use([
  PieChart,
  SVGRenderer,
  GraphicComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent
])

export default {
  components: { VChart },

  props: {
    canvasSegments: Object,
    isDelta: {
      type: Boolean,
      value: false
    },  
    isTarget: Boolean
  },

  data() {
    return {
      pie: {
        title: {
          show: true
        },
        tooltip: { show: false },
        legend: false,
        itemStyle: {
          borderWidth: 4,
          borderColor: "#ffffff"
        },
        series: [
          {
            id: "direct",
            name: "direct",
            type: "pie",
            radius: ["0%", "26%"],
            selectedOffset: 0,
            height: 'auto',
            width: 'auto',
            labelLine: {
              show: false
            },
            label: {
              show: true,
              position: 'inside',
              overflow: 'none',
              lineOverflow: 'none',
              verticalAlign: 'top',
              height: 65,
              align: 'center',
              width: 40,
              formatter: arg => {
                let labelTemplate = [];
                if (this.isTarget || !this.getEuroActive) {
                  if (arg.data.point) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );

                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{point|${arg.data.point}}`);
                  } else {
                    const styleName = 'lineHeight' + "White"
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                } else {
                  if (arg.data.equity_impact) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{equity_impact|${arg.data.equity_impact}}`);
                  } else {
                    const styleName = 'lineHeightWhite'
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                }
                return labelTemplate.join("\n");
              },
              rich: {
                lineHeightWhite: {
                  lineHeight: 15,
                  fontWeight: "bold",
                  fontSize: 13,
                  height: 20,
                  color: "white",
                },
                lineHeightBlack: {
                  lineHeight: 15,
                  fontWeight: "bold",
                  fontSize: 13,
                  height: 20,
                  color: "black",
                },
                point: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                },
                equity_impact: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                }
              }
            },
            data: this.canvasSegments["direct"],
            itemStyle: {
              borderWidth: 2,
              borderColor: "#ffffff",
              color: "#383C38"
            },
            startAngle: 180,
            zlevel: 3
          },
          {
            id: "indirect",
            name: "indirect",
            type: "pie",
            radius: ["26%", "60%"],
            height: 'auto',
            width: 'auto',
            selectedOffset: 0,
            labelLine: {
              show: false
            },
            label: {
              show: true,
              position: "inside",
              formatter: arg => {
                let labelTemplate = [];
                if (this.isTarget || !this.getEuroActive) {
                  if (arg.data.point) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );

                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{point|${arg.data.point}}`);
                  } else {
                    const styleName = 'lineHeightWhite'
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                } else {
                  if (arg.data.equity_impact) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{equity_impact|${arg.data.equity_impact}}`);
                  } else {
                    const styleName = `${arg.seriesName === "goodway" ? "lineHeightBlack" : "lineHeightWhite"}`
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                }
                return labelTemplate.join("\n");
              },
              rich: {
                lineHeightWhite: {
                  lineHeight: 15,
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 13
                },
                lineHeightBlack: {
                  lineHeight: 15,
                  color: "black",
                  fontWeight: "bold",
                  fontSize: 13
                },
                point: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                },
                equity_impact: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                }
              }
            },
            data: this.canvasSegments["indirect"],
            itemStyle: {
              borderWidth: 2,
              borderColor: "#ffffff",
              color: "#8A8C8D"
            },
            startAngle: 90,
            zlevel: 2
          },
          {
            id: "goodway",
            name: "goodway",
            type: "pie",
            radius: ["60%", "98%"],
            height: 'auto',
            width: 'auto',
            selectedOffset: 0,
            labelLine: {
              show: false
            },
            label: {
              show: true,
              position: "inside",
              overflow: 'none',
              formatter: arg => {
                let labelTemplate = [];
                if (this.isTarget || !this.getEuroActive) {
                  if (arg.data.point > 0 || arg.data.point < 0) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );

                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{point|${arg.data.point}}`);
                  } else {
                    const styleName = `${arg.seriesName === "goodway" ? "lineHeightBlack" : "lineHeightWhite"}`
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                } else {
                  if (arg.data.equity_impact) {
                    let colorTheme
                    if (arg.data.itemStyle) {
                      colorTheme = Color(arg.data.itemStyle.color);
                    } else {
                      colorTheme = Color(arg.color)
                    }
                    const styleName = 'lineHeight' + (colorTheme.isLight() ? "Black" : "White")
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                    labelTemplate.push(`{hr|}`);
                    labelTemplate.push(`{equity_impact|${arg.data.equity_impact}}`);
                  } else {
                    const styleName = `${arg.seriesName === "goodway" ? "lineHeightBlack" : "lineHeightWhite"}`
                    labelTemplate.push(
                        `{${styleName}|${arg.data.name}}`
                    );
                  }
                }
                return labelTemplate.join("\n");
              },
              rich: {
                lineHeightWhite: {
                  lineHeight: 15,
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 13
                },
                lineHeightBlack: {
                  lineHeight: 15,
                  color: "black",
                  fontWeight: "bold",
                  fontSize: 13
                },
                point: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                },
                equity_impact: {
                  fontWeight: "bold",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#000",
                  textBorderWidth: 0,
                  textBorderColor: "transparent",
                  backgroundColor: "#EBEBEB",
                  width: 'auto',
                  padding: [5, 7, 5, 7],
                  align: "center",
                  borderWidth: 1,
                  borderColor: "#595A5B"
                }
              }
            },
            data: this.canvasSegments["goodway"],
            itemStyle: {
              borderWidth: 2,
              borderColor: "#ffffff",
              color: "#D0D1D1"
            },
            startAngle: 90,
            zlevel: 1
          }
        ],
        graphic: {
          id: 'echart',
          elements: [
            {
              type: 'image',
              style: {
                image: ''
              }
            },
            {
              type: 'text',
              id: 'text',
              invisible: true,
              textConfig: {
                inside: true
              }
            }
          ]
        }
      },

      updateData: {
        series: [
          {
            data: this.canvasSegments["direct"],
          },
          {
            data: this.canvasSegments["indirect"],
          },
          {
            data: this.canvasSegments["goodway"],
          },
        ],
      },

      selectedSegment: {},

      loading: true,
    };
  },

  mounted() {
    this.loading = false
  },

  computed: mapGetters(["getEuroActive"]),

  methods: {
    updatedData() {
      this.$refs.echart.setOption(this.updateData, {
        notMerge: false,
        lazyUpdate: true,
        silent: true,
      });
    },

    onClickSegment(arg) {
      if (
          this.selectedSegment.seriesIndex != undefined &&
          this.selectedSegment.dataIndex != undefined
      ) {
        this.$refs.echart.dispatchAction({
          type: "downplay",
          seriesIndex: this.selectedSegment.seriesIndex,
          dataIndex: this.selectedSegment.dataIndex,
        });
      }

      this.$refs.echart.dispatchAction({
        type: "highlight",
        seriesIndex: arg.seriesIndex,
        dataIndex: arg.dataIndex,
      });
      this.selectedSegment = arg;

      this.$emit("onClickSegment", arg);
    },

    unHighlightSegment() {
      if (
          this.selectedSegment.seriesIndex != undefined &&
          this.selectedSegment.dataIndex != undefined
      ) {
        this.$refs.echart.dispatchAction({
          type: 'downplay',
          seriesIndex: this.selectedSegment.seriesIndex,
          dataIndex: this.selectedSegment.dataIndex
        });
        this.selectedSegment = {};
      }
    }
  }
};
</script>
