<template>
  <div class="client-list">
    <div class="client-list__header">
      <a-button
          v-if="[this.$userRole.EXCUBATE_ADMIN, this.$userRole.EXCUBATE_EMPLOYEE].includes(this.$store.getters.getUserRole)"
          class="editable-add-btn"
          type="primary"
          size="large"
          :style="{'margin-bottom': '30px'}"
          @click="handleAdd"
      >
        <a-icon type="plus"/>
        Add Client
      </a-button>
    </div>
    <a-table class="client-list__content"
             :columns="columns"
             :dataSource="data"
             :loading="loading"
    >
      <span slot="clientName" slot-scope="client_name, record">
        <a @click.prevent="portfolioList(record._id)">{{ record.client_name }}</a>
      </span>
      <span slot="action" slot-scope="record">
        <div class="action-group">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Edit Client</span>
            </template>
            <a-button type="primary" size="default" @click.prevent="editRecord(record._id)">
              <a-icon type="edit"/>
            </a-button>
          </a-tooltip>
        </div>
      </span>
      <template slot="customLogo" slot-scope="logo">
        <div class="client__logo-container">
          <img class="client__logo"
               :src="logo"
               @error="errorImg"
               alt="logo"
          >
        </div>
      </template>
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
              v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>{{ text }}</template>
      </template>
    </a-table>
  </div>
</template>

<script>
import placehorderLogo from '../../assets/placehorder-logo.png';

const data = [];

export default {
  name: 'ClientList',
  data() {
    return {
      data,
      columns: [
        {
          title: "",
          dataIndex: "logo",
          scopedSlots: {
            customRender: "customLogo"
          }
        },
        {
          title: "Client Name",
          dataIndex: "client_name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName"
          },
          onFilter: (value, record) => {
            return record.client_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "Excubate Contact",
          dataIndex: "excubate_contact",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.excubate_contact
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Excubate Email",
          dataIndex: "excubate_email",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.excubate_email
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Client Contact",
          dataIndex: "client_contact",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.client_contact
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Client Email",
          dataIndex: "client_email",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.client_email
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        }
      ],
      loading: true,
      searchText: null,
      editRecordRight: [
        this.$userRole.EXCUBATE_ADMIN,
      ].includes(this.$store.getters.getUserRole)
    };
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    async fetch() {
      this.loading = true;
      const res = await this.$http.get("/admin/api/rest/client");
      res.data = res.data.map(value => {
        value.key = value._id;
        if (!Array.isArray(value.excubate_user)) {
          value.excubate_contact = value.excubate_user.user_name;
          value.excubate_email = value.excubate_user.email;
        } else {
          value.excubate_contact = value.excubate_user
              .map(value => value.user_name)
              .join("; ");
          value.excubate_email = value.excubate_user
              .map(value => value.email)
              .join("; ");
        }

        if (!Array.isArray(value.client_user)) {
          value.client_contact = value.client_user.user_name;
          value.client_email = value.client_user.email;
        } else {
          value.client_contact = value.client_user
              .map(value => value.user_name)
              .join("; ");
          value.client_email = value.client_user
              .map(value => value.email)
              .join("; ");
        }

        if (value.logo && !value.logo.includes('base64')) {
          value.logo = process.env.VUE_APP_HOST ?
              process.env.VUE_APP_HOST + value.logo
              : value.logo
        } else {
          if (value.logo === null || value.logo === undefined) {
            value.logo = placehorderLogo
          }
        }

        return value;
      });
      this.data = res.data;
      this.loading = false;
    },
    errorImg(event) {
      event.target.src = placehorderLogo
    },
    handleAdd() {
      this.$router.push({ name: "add-client" });
    },
    editRecord(id) {
      this.$router.push({ name: "edit-client", params: { client_id: id } });
    },
    portfolioList(id) {
      this.$router.push({
        name: "client-portfolio-list",
        params: { client_id: id }
      });
    }
  },
  created() {
    this.fetch();
    if (this.editRecordRight)
      this.columns.push({
        title: "Action",
        scopedSlots: { customRender: "action" }
      });
  }
};
</script>

<style lang="less">
.client-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  &__content {
    width: 100%;
    height: 100%;

    & .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & table {
      width: 100% !important;
    }

    & .client__logo {
      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      max-width: 90px;
      height: 40px;
    }
  }
}
</style>
