<template>
  <div
      class="project__canvas__segment__form"
      v-if="visible"
  >
    <div class="project__canvas__segment__form__header">
      <p class="project__canvas__segment__form__title">
        {{ formData.title }}
      </p>
      <button class="project__canvas__segment__form__close"
              type="button"
              aria-label="Close"
              @click="closeForm"
      >
        <a-icon class="project__canvas__segment__form__close-svg"
                type="close"
        />
      </button>
    </div>
    <a-form
        class="project__canvas__segment__form__content"
        id="form"
        :form="form"
    >
      <div
          class="project__canvas__segment__form__content__block"
          v-for="block in formData.data.body"
          :key="block.id"
      >
        <h3 class="project__canvas__segment__form__content__block-title">
          {{ block.title }}
        </h3>
        <div class="project__canvas__segment__form__content__block-conteiner">
          <ProjectCanvasSegmentFormItem
              v-for="(data, name) in block.data"
              :key="name"
              :name="name"
              :data="data"
              :isEdit="isEdit"
          />
        </div>
      </div>
      <div class="project__canvas__segment__form__content__footer">
        <ProjectCanvasSegmentFormItem ref="FormItem"
                                      v-for="(data, name) in formData.data.footer"
                                      :key="name"
                                      :name="name"
                                      :data="data"
                                      :isEdit="isEdit"
        />
      </div>
    </a-form>
    <div class="project__canvas__segment__form__footer">
      <div class="project__canvas__segment__form__footer__conteiner">
        <a-button
            class="project__canvas__segment__form__footer__btn"
            v-if="isEdit"
            @click="resetForm"
        >
          Reset Changes
        </a-button>
      </div>
      <div class="project__canvas__segment__form__footer__conteiner">
        <a-button class="project__canvas__segment__form__footer__btn"
                  v-if="isEdit"
                  @click="closeForm"
        >
          Cancel
        </a-button>
        <a-button class="project__canvas__segment__form__footer__btn"
                  v-if="isEdit"
                  type="primary"
                  :loading="confirmLoading"
                  @click.prevent="handleSubmit"
        >
          Submit
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCanvasSegmentFormItem from './ProjectCanvasSegmentFormItem'
import { mapState } from 'vuex'
import numeral from 'numeral'

export default {
  name: 'ProjectCanvasSegmentForm',

  components: { ProjectCanvasSegmentFormItem },

  props: {
    confirmLoading: Boolean,
    isEdit: Boolean,
  },

  data() {
    return {
      visible: false,
      formData: null,
      initialPoints: null
    }
  },

  computed: {
    ...mapState(['SectorMetaData']),
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "form_in_modal" });
  },

  mounted() {
    const element = document.getElementById('form')
    element?.scrollTo(0, 0)
  },

  beforeUpdate() {
    if (this.form.isFieldsTouched()) {
      this.recalculatePoints();
    }
  },
  methods: {
    recalculatePoints() {
      const fieldsValue = this.form.getFieldsValue()
      for (const key in fieldsValue) {
        for (const bodyItem of this.formData.data.body) {
          if (bodyItem.data[key]) {
            if (bodyItem.data[key].prefix.includes('%')) {
              this.SectorMetaData[this.formData.key].data[key] = numeral(fieldsValue[key]).value() / 100
            } else {
              this.SectorMetaData[this.formData.key].data[key] = numeral(fieldsValue[key]).value()
            }
          }
        }
      }
      this.calculated()
      if (this.formData?.data_index === 0) {

        if (fieldsValue.point && fieldsValue.point !== 'null') {
          this.formData.data.footer.assigned_points.value += parseInt(fieldsValue.point) - parseInt(this.formData.data.footer.point.value)
          this.formData.data.footer.point.value = parseInt(fieldsValue.point)
        } else {
          this.formData.data.footer.assigned_points.value += parseInt(0) - parseInt(this.formData.data.footer.point.value)
          this.formData.data.footer.point.value = parseInt(0)
        }
        this.formData.data.footer.available_points.value = 100 - this.formData.data.footer.assigned_points.value
      }
    },
    calculated() {
      if (this.formData?.data_index === 0) {
        for (const bodyItem of this.formData?.data.body) {
          for (const bodyItemKey in bodyItem.data) {
            if (bodyItem.data[bodyItemKey].calculated.length > 1) {
              if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                bodyItem.data[bodyItemKey].value =
                    100 * this.$store.getters[`SectorMetaData/${this.formData.key}__${bodyItemKey}`]
              } else {
                bodyItem.data[bodyItemKey].value =
                    this.$store.getters[`SectorMetaData/${this.formData.key}__${bodyItemKey}`]
              }
            }
          }
        }
        for (const footerKey in this.formData.data.footer) {
          if (this.formData.data.footer[footerKey].calculated.length > 1) {
            if (this.formData.data.footer[footerKey].prefix.includes('%')) {
              this.formData.data.footer[footerKey].value =
                  100 * this.$store.getters[`SectorMetaData/${this.formData.key}__${footerKey}`]
            } else {
              this.formData.data.footer[footerKey].value =
                  this.$store.getters[`SectorMetaData/${this.formData.key}__${footerKey}`]
            }
          }
        }
      }
    },
    showForm(formData) {
      this.visible = true
      this.formData = formData
      for (const bodyItem of this.formData?.data.body) {
        for (const bodyItemKey in bodyItem.data) {
          if (bodyItem.data[bodyItemKey].isEdit) {
            this.form.setFieldsValue({
              [bodyItemKey]: bodyItem.data[bodyItemKey].value
            })
          }
        }
      }
      this.initialPoints = this.formData.data.footer.point ? String(this.formData.data.footer.point.value) : null;
      for (const footerKey in this.formData.data.footer) {
        if (this.formData.data.footer[footerKey].isEdit) {
          this.form.setFieldsValue({
            [footerKey]: this.formData.data.footer[footerKey].value
          })
        }
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.formData.data_index !== 0) {
            for (const key in values) {
              values[key] = String(numeral(values[key]).value())
            }
          }

          this.$emit("submit", values);
        }
      })
    },
    closeForm() {
      this.visible = false;
      this.resetForm();
      this.$emit("closeForm");
    },
    hideForm() {
      this.visible = false;
      this.resetForm();
    },
    resetForm() {
      this.form.resetFields()

      // Points should be reset manually because recalculatePoints() re-initializes them again, so original values
      // will be lost
      this.form.setFieldsValue({
        'point': this.initialPoints
      });

      // Force assigned and available points recalculation after form reset
      this.recalculatePoints();
    }
  }
};
</script>

<style lang="less">
.project__canvas__segment__form {
  width: 470px;
  overflow: hidden;
  height: 555px;
  flex-grow: 1;
  border-radius: 5px;
  background-color: #F7F9FC;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__header {
    width: 100%;
    height: 40px;
    padding: 15px;
    background-color: #595A5B;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin: 0;
  }

  &__close {
    cursor: pointer;
    background: none;
    border: none;
    padding: 10px;
    margin-right: -15px;

    &-svg svg {
      fill: #FFFFFF;
    }
  }

  &__content {
    width: 100%;
    padding: 30px 8px 35px 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__block {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;

      &-conteiner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        background-color: #FFFFFF;
        padding: 20px 15px;
      }

      &-title {
        width: 100%;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 8px;
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    border-top: 1px solid #D8D8D8;

    &__conteiner {
      display: flex;
      justify-content: flex-start;
      align-content: center;
    }

    &__btn {
      &:nth-of-type(2) {
        margin-left: 15px;
      }
    }
  }
}
</style>
