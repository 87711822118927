const LOG_TYPE = [
    {
        type: 'auth_success',
        text: 'Log In'
    },
    {
        type: 'auth_fail',
        text: 'Auth Fail'
    },
    {
        type: 'sign_up',
        text: 'Sign Up'
    },
    {
        type: 'email_verified',
        text: 'Email Verified'
    },
    {
        type: 'request_reset_password',
        text: 'Request Reset Password'
    },
    {
        type: 'reset_password',
        text: 'Reset Password'
    },
    {
        type: 'updated_canvas',
        text: 'Updated Canvas'
    }
]

export { LOG_TYPE }
