<template>
  <div class="forgot-password__verify__content">
    <svg class="forgot-password__verify__content-title-svg"
         width="156px"
         height="89px"
         viewBox="0 0 156 89"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
          d="M148.571429,0.614098117 L7.05714286,0.614098117 C5.83822368,0.61271468 4.63997118,0.928908038 3.58043771,1.53153124 C3.32665436,1.67557053 3.08229083,1.83560797 2.84882743,2.0106741 C1.05615138,3.33972195 -3.34759079e-07,5.4396418 -3.34759079e-07,7.67124552 L-3.34759079e-07,81.2141027 C0.00346292326,85.1102198 3.16102577,88.2677826 7.05714286,88.2712455 L148.571429,88.2712455 C152.467519,88.2677192 155.625045,85.1101935 155.628571,81.2141027 L155.628571,7.67124552 C155.625045,3.77515472 152.467519,0.617628964 148.571429,0.614098117 Z M154.885714,81.2141027 C154.880717,84.6993142 152.05664,87.5233907 148.571429,87.5283884 L7.05714286,87.5283884 C3.57190426,87.523456 0.747789546,84.6993413 0.742857143,81.2141027 L0.742857143,7.67124552 C0.753673613,4.93439218 2.51696608,2.5124393 5.118204,1.66153124 C5.170256,1.6466741 5.21848971,1.62810267 5.27054914,1.61324552 C5.85073146,1.44268727 6.45241043,1.35695688 7.05714286,1.35695688 L148.571429,1.35695688 C152.056641,1.36195546 154.880719,4.18603318 154.885714,7.67124552 L154.885714,81.2141027 Z"
          id="Shape"
          fill="#3F3D56"
      />
      <path
          d="M77.6595746,44.4114221 C77.0959169,44.4114221 76.5414921,44.2682651 76.0479943,43.9959235 L2.117648,3.30490581 C1.93795547,3.20599515 1.87246882,2.98014265 1.97137946,2.80045011 C2.0702901,2.62075757 2.2961426,2.5552709 2.47583514,2.65418152 L76.4061851,43.3451992 C77.1721268,43.7681224 78.0996884,43.7763053 78.8729723,43.3669612 L154.020245,3.72457924 C154.13762,3.66234285 154.279301,3.6675506 154.391791,3.73823609 C154.504281,3.80892158 154.57044,3.93431538 154.565568,4.06707004 C154.560136,4.1998247 154.484458,4.31971412 154.366829,4.38146924 L79.219556,44.0238512 C78.7388654,44.278105 78.2033653,44.4114221 77.6595746,44.4114221 Z"
          id="Path"
          fill="#3F3D56"
      />
      <path
          d="M23.6104626,69.6997612 L13.2104626,69.6997612 C11.5693879,69.6997612 10.239034,68.3694074 10.239034,66.7283327 C10.239034,65.087258 11.5693879,63.7569041 13.2104626,63.7569041 L23.6104626,63.7569041 C25.2515373,63.7569041 26.5818911,65.087258 26.5818911,66.7283327 C26.5818911,68.3694074 25.2515373,69.6997612 23.6104626,69.6997612 Z"
          id="Path"
          fill="#3F3D56"
      />
      <path
          d="M35.8676054,79.7283327 L13.2104626,79.7283327 C11.5693879,79.7283327 10.239034,78.3979788 10.239034,76.7569041 C10.239034,75.1158294 11.5693879,73.7854755 13.2104626,73.7854755 L35.8676054,73.7854755 C37.5086801,73.7854755 38.839034,75.1158294 38.839034,76.7569041 C38.839034,78.3979788 37.5086801,79.7283327 35.8676054,79.7283327 Z"
          id="Path"
          fill="#3F3D56"
      />
      <circle id="Oval"
              fill="#75C845"
              cx="77.6299234"
              cy="44.4426147"
              r="11.8857143"
      />
    </svg>
    <p class="forgot-password__verify__content-title">
      Check your mail
    </p>
    <p class="forgot-password__verify__content-title-sub">
      We’ve sent you an e-mail with a link to reset your password.
      Please check your spam folder.<br>If you didn’t get the mail, please reach out to support@valuecanvas.digital.
    </p>
  </div>
</template>

<script>

export default {
  name: 'ForgotPasswordVerify'
};
</script>

<style lang="less">
.forgot-password__verify {
  &__content {
    width: 100%;
    min-height: 321px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    &-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.75px;
      color: black;
      margin: 0;
      text-align: center;
      margin-bottom: 12px;

      &-svg {
        margin-bottom: 35px;
      }

      &-sub {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: rgba(42, 47, 55, 0.6);
        margin: 0;
        text-align: center;
      }
    }
  }
}
</style>
