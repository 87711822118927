<template>
  <a-modal
      title="Open project?"
      class="projects-pop-confirm"
      v-model="visible"
      on-ok="handleOk"
      on-cancel="handleCancel"
  >
    <template slot="footer">
      <a-button
          class="projects-pop-confirm__footer-btn projects-pop-confirm__footer-cancel-btn"
          type="default"
          @click="handleCancel"
      >
        No
      </a-button>
      <a-button
          class="projects-pop-confirm__footer-btn"
          type="primary"
          @click="handleOk"
          :loading="loading"
      >
        Yes
      </a-button>
    </template>
    <div class="projects-pop-confirm__content">
      {{ titleConfirm }}
    </div>
  </a-modal>
</template>

<script>

export default {
  name: 'ProjectRedirectModal',

  props: {
    title: String,
    textBtnOk: String,
  },

  data() {
    return {
      visible: false,
      loading: false,
      projectInfo: {
        project_name: ''
      },
      params: null,
    };
  },

  computed: {
    titleConfirm() {
      return `${this.title} '${this.projectInfo.project_name}' Project now?`
    }
  },

  methods: {
    handleKnowMore(params) {
      this.visible = true;
      this.params = params;
      this.loadData()
    },

    async handleOk() {
      const { params } = this
      this.$router.push({
        name: "dpo-projects-assement-canvas",
        params,
      });

      this.visible = false;
    },

    handleCancel() {
      this.visible = false;
    },

    async loadData() {
      try {
        const response = await this.$http.get(`/admin/api/rest/project/${this.params.project_id}`)

        if (response.data) {
          this.projectInfo = response.data
        }
      } catch (error) {
        this.$message.error(error.response.data.message)
      }
    },
  },
};
</script>

<style lang="less">
.projects-pop-confirm {
  background-color: white;
  width: max-content;
  height: max-content;

  & .ant-modal {
    width: 600px !important;
    height: max-content !important;
  }

  & .ant-modal-header {
    background-color: white;
    border: none;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      text-align: left;
    }
  }

  & .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 28px;
    border: none;
  }

  & .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    width: 100%;
    height: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
  }

  &__footer {
    &-cancel-btn {
      border: none;
      color: black !important;

      &:hover {
        color: #75c845 !important;
      }
    }

    &-btn {
      height: 36px !important;
      padding: 10px 45px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
