const data = {
    default: {
        data_index: 0,
        title: "",
        data: {
            body: [],
            footer: {
                assigned_points: {
                    label: 'Assigned Points',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [],
                    type: '',
                    prefix: '',
                },
                available_points: {
                    label: 'Available Points',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        '100',
                        '-',
                        'assigned_points'
                    ],
                    type: '',
                    prefix: '',
                },
                point: {
                    label: 'Points Awarded',
                    value: '',
                    labelInfo: '',
                    isEdit: true,
                    calculated: [],
                    type: 'number',
                    prefix: '',
                },
                explanation: {
                    label: 'Explanation for Score',
                    value: '',
                    labelInfo: '',
                    isEdit: true,
                    calculated: [],
                    type: 'textarea',
                    prefix: '',
                },
                metrics: {
                    label: 'Relevant Metrics',
                    value: '',
                    labelInfo: '',
                    isEdit: true,
                    calculated: [],
                    type: 'textarea',
                    prefix: '',
                },
                comments: {
                    label: 'Comments',
                    value: '',
                    labelInfo: '',
                    isEdit: true,
                    calculated: [],
                    type: 'textarea',
                    prefix: '',
                }
            }
        }
    },
    direct_revenue: {
        data_index: 14,
        title: 'Direct Revenue',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        price_product: {
                            label: 'Price for product/service per unit p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        },
                        estimated_amount_product_sales: {
                            label: 'Estimated amount of products sold',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        }
                    }
                }
            ],
            footer: {
                total_increase_digital_product: {
                    label: 'Total increase in revenue through digital product p.a.',
                    value: null,
                    isEdit: false,
                    calculated: [
                        'price_product',
                        '*',
                        'estimated_amount_product_sales'
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_digital_product'
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'profit_impact',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    direct_cost_reduction: {
        data_index: 13,
        title: 'Direct Cost reduction',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        process_cost: {
                            label: 'Former (process) cost',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        },
                        new_cost_initiative: {
                            label: 'New cost, using initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                total_cost_reduction_initiative: {
                    label: 'Total cost reduction through initiative p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'process_cost',
                        '-',
                        'new_cost_initiative'
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_cost_reduction_initiative',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    core_product_sales: {
        data_index: 12,
        title: 'Core Product Sales',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        sold_products_total_number: {
                            label: 'Total number of products sold p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.sold_products_total_number'
                            ],
                            type: '',
                            prefix: '',
                        },
                        total_revenue: {
                            label: 'Total revenue p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        customers_total_number: {
                            label: 'Total number of customers p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.customers_total_number'
                            ],
                            type: '',
                            prefix: '',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        original_average_revenue_product: {
                            label: 'Original average revenue per product sold',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue',
                                '/',
                                'meta_data.sold_products_total_number'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        average_number_products_customer: {
                            label: 'Average number of products per customer',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.sold_products_total_number',
                                '/',
                                'meta_data.customers_total_number'
                            ],
                            type: '',
                            prefix: '',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        increase_revenue_digital_product: {
                            label: 'Increase in revenue per product through digital product',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        },
                        increase_number_products_sold_digital_initiative: {
                            label: 'Increase in number of products sold through digital initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        },
                        number_new_customers_digital_initiativ: {
                            label: 'Number of new customers attained through digital initiative p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        new_total_number_products_sold: {
                            label: 'New total number of products sold',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                [
                                    'increase_number_products_sold_digital_initiative',
                                    '+',
                                    'average_number_products_customer'
                                ],
                                '*',
                                [
                                    'meta_data.customers_total_number',
                                    '+',
                                    'number_new_customers_digital_initiativ'
                                ]
                            ],
                            type: '',
                            prefix: '',
                        },
                        new_average_revenue_product: {
                            label: 'New average revenue per product',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'original_average_revenue_product',
                                '+',
                                'increase_revenue_digital_product'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                total_increase_revenue_digital_product: {
                    label: 'Total increase in revenue through digital product p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'new_total_number_products_sold',
                        '*',
                        'new_average_revenue_product',
                        '-',
                        'meta_data.total_revenue'
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_revenue_digital_product',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'profit_impact',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    customer_satisfaction_and_retention: {
        data_index: 11,
        title: 'Customer Satisfaction & Retention',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        customers_total_number: {
                            label: 'Total number of customers p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.customers_total_number'
                            ],
                            type: '',
                            prefix: '',
                        },
                        total_revenue: {
                            label: 'Total revenue p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        customer_retention_rate: {
                            label: 'Customer retention rate',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.customer_retention_rate'
                            ],
                            type: '',
                            prefix: '%',
                        },
                        average_revenue_customer: {
                            label: 'Average revenue per customer p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue',
                                '/',
                                'meta_data.customers_total_number'
                            ],
                            type: '',
                            prefix: '€',
                        },
                    }
                },
                {
                    title: 'Recommendation effect',
                    data: {
                        customer_recommendations_amount: {
                            label: 'Amount of customer recommendations p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.customer_recommendations_amount'
                            ],
                            type: '',
                            prefix: '',
                        },
                        number_additional_recommendations_customers: {
                            label: 'Number of additional recommendations customers will make p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        },
                        percentage_additional_recommendations_customers: {
                            label: 'Percentage of additional recommendations customers will make p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'number_additional_recommendations_customers',
                                '/',
                                'meta_data.customer_recommendations_amount'
                            ],
                            type: '',
                            prefix: '%',
                        },
                        recommendations_resulting_sale_percentage: {
                            label: 'Increase in recommendations resulting in sale p.a. in %',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        recommendations_resulting_sale_total: {
                            label: 'Recommendations resulting in sale p.a. in total',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                [
                                    'meta_data.customer_recommendations_amount',
                                    '+',
                                    'number_additional_recommendations_customers'
                                ],
                                '*',
                                'meta_data.customers_total_number',
                                '*',
                                'recommendations_resulting_sale_percentage'
                            ],
                            type: '',
                            prefix: '',
                        },
                    }
                },
                {
                    title: 'Retention effect',
                    data: {
                        percentage_point_increase_retention_rate: {
                            label: 'Percentage point increase of retention rate p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        expected_additionally_retained_customers: {
                            label: 'Expected additionally retained customers p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                [
                                    'meta_data.customer_retention_rate',
                                    '+',
                                    'percentage_point_increase_retention_rate'
                                ],
                                '*',
                                'meta_data.customers_total_number',
                                '-',
                                'meta_data.customers_total_number',
                                '*',
                                'meta_data.customer_retention_rate'
                            ],
                            type: '',
                            prefix: '',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        expected_revenue_retention_customers: {
                            label: 'Expected revenue through retention of customers',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'expected_additionally_retained_customers',
                                '*',
                                'average_revenue_customer'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        expected_revenue_new_customers: {
                            label: 'Expected revenue generated through new customers',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'recommendations_resulting_sale_total',
                                '*',
                                'average_revenue_customer'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                total_increase_revenue_digital_product: {
                    label: 'Total increase in revenue through digital product p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'expected_revenue_retention_customers',
                        '+',
                        'expected_revenue_new_customers',
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_revenue_digital_product',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'profit_impact',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    employee_effectiveness: {
        data_index: 8,
        title: 'Employee Effectiveness',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        fte_total_number: {
                            label: 'Total number of FTE (employees)',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.fte_total_number'
                            ],
                            type: '',
                            prefix: '',
                        },
                        amount_employees_working_digital_solution_percentage: {
                            label: 'Share of employees working with digital solution in %',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        amount_employees_working_digital_solution: {
                            label: 'Number of employees working with digital solution',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.fte_total_number',
                                '*',
                                'amount_employees_working_digital_solution_percentage'
                            ],
                            type: '',
                            prefix: '',
                        },
                        increase_employee_effectiveness_solution: {
                            label: 'Increase in employee effectiveness through solution',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        number_redundant_employees_digital_solution: {
                            label: 'Number of redundant employees due to digital solution',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'amount_employees_working_digital_solution',
                                '*',
                                'increase_employee_effectiveness_solution'
                            ],
                            type: '',
                            prefix: '',
                        },
                    }
                },
                {
                    title: '',
                    data: {
                        average_annual_salary_affected_division: {
                            label: 'Average annual salary of affected division',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        },
                    }
                },
                {
                    title: '',
                    data: {
                        total_FTE_saved_digital_solution: {
                            label: 'Total FTE cost saved due to digital solution p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'number_redundant_employees_digital_solution',
                                '*',
                                'average_annual_salary_affected_division'
                            ],
                            type: '',
                            prefix: '€',
                        },
                    }
                }
            ],
            footer: {
                total_increase_revenue_digital_product: {
                    label: 'Total increase in revenue through digital product p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'number_redundant_employees_digital_solution',
                        '*',
                        'average_annual_salary_affected_division'
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_revenue_digital_product',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'profit_impact',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    equipment_utilization: {
        data_index: 10,
        title: 'Equipment Utilization',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        asset_total_cost: {
                            label: 'Total asset cost p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.asset_total_cost'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        asset_utilization: {
                            label: 'Asset utilization in %',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.asset_utilization'
                            ],
                            type: '',
                            prefix: '%',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        new_asset_utilization_initiative: {
                            label: 'New asset utilization through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [
                                'meta_data.asset_utilization'
                            ],
                            type: '',
                            prefix: '%',
                        },
                    }
                },
                {
                    title: '',
                    data: {
                        asset_cost_utilization_one_percent: {
                            label: 'Asset cost per 1% of utilization',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.asset_total_cost',
                                '/',
                                [
                                    'meta_data.asset_utilization',
                                    '*',
                                    '100'
                                ]
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                value_additional_asset_utilization_initiative: {
                    label: 'Value of additional asset utilization through initiative',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        [
                            'new_asset_utilization_initiative',
                            '-',
                            'meta_data.asset_utilization'
                        ],
                        '*',
                        '100',
                        '*',
                        'asset_cost_utilization_one_percent',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'value_additional_asset_utilization_initiative',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    process_speed_and_quality: {
        data_index: 9,
        title: 'Process Speed and Quality',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        total_process_cost: {
                            label: 'Total process cost p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '€',
                        },
                        reduction_process_cost_initiative_percentage: {
                            label: 'Reduction in process cost p.a. through initiative in %',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                    }
                }
            ],
            footer: {
                total_cost_reduction_digital_product: {
                    label: 'Total cost reduction through digital product p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'reduction_process_cost_initiative_percentage',
                        '*',
                        'total_process_cost'
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_cost_reduction_digital_product',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    brand_awareness: {
        data_index: 7,
        title: 'Brand Awareness',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        total_estimated_market_size: {
                            label: 'Total estimated target market size',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        },
                        estimated_brand_awareness_market: {
                            label: 'Estimated brand awareness in target market',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                    }
                },
                {
                    title: '',
                    data: {
                        number_people_know_brand: {
                            label: 'Number of people that know the brand',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'total_estimated_market_size',
                                '*',
                                'estimated_brand_awareness_market'
                            ],
                            type: '',
                            prefix: '',
                        },
                        total_revenue: {
                            label: 'Total Revenue',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        sales_person_knows_brand: {
                            label: 'Sales per person that knows the brand',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue',
                                '/',
                                'number_people_know_brand'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        number_people_know_brand_initiative: {
                            label: 'Number of people that get to know the brand through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        },
                        correction_factor: {
                            label: 'Correction factor',
                            value: '100',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        }
                    }
                }
            ],
            footer: {
                total_increase_revenue_initiative: {
                    label: 'Total increase in revenue through initiative p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'correction_factor',
                        '*',
                        'sales_person_knows_brand',
                        '*',
                        'number_people_know_brand_initiative'
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_revenue_initiative',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'profit_impact',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    employer_branding: {
        data_index: 1,
        title: 'Employer Branding',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        fte_total_number: {
                            label: 'Total number of FTE (employees)',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.fte_total_number'
                            ],
                            type: '',
                            prefix: '',
                        },
                        avg_acquisition_cost_per_hire: {
                            label: 'Average acquisition and onboarding cost per hire',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.avg_acquisition_cost_per_hire'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        employee_churn_rate: {
                            label: 'Employee "churn rate" p.a. %',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.employee_churn_rate'
                            ],
                            type: '',
                            prefix: '%',
                        },
                        number_hires_existing_role: {
                            label: 'Number of hires for existing roles p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.fte_total_number',
                                '*',
                                'meta_data.employee_churn_rate',
                            ],
                            type: '',
                            prefix: '',
                        },
                    }
                },
                {
                    title: '',
                    data: {
                        reduction_employee_churn_rate_initiative: {
                            label: 'Reduction of employee churn rate through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        new_number_hires_existiing_roles: {
                            label: 'New number of hires for existiing roles p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'number_hires_existing_role',
                                '-',
                                'reduction_employee_churn_rate_initiative',
                                '*',
                                'number_hires_existing_role'

                            ],
                            type: '',
                            prefix: '',
                        },
                        reduction_hiring_process_cost: {
                            label: 'Reduction in hiring process cost',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                [
                                    'number_hires_existing_role',
                                    '-',
                                    'new_number_hires_existiing_roles'
                                ],
                                '*',
                                'meta_data.avg_acquisition_cost_per_hire'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                total_decrease_cost_initiative: {
                    label: 'Total decrease in cost through initiative p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        [
                            'number_hires_existing_role',
                            '-',
                            'new_number_hires_existiing_roles'
                        ],
                        '*',
                        'meta_data.avg_acquisition_cost_per_hire'
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_decrease_cost_initiative',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    agile_culture_and_organizational_learning: {
        data_index: 2,
        title: 'Agile Culture & Organizational Learning',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        all_projects_total_cost: {
                            label: 'Total cost across all projects',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.all_projects_total_cost'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        unsuccessful_projects_percentage: {
                            label: 'Percentage of unsuccessful projects',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.unsuccessful_projects_percentage'
                            ],
                            type: '',
                            prefix: '%',
                        },
                        reduction_failed_projects_percentage: {
                            label: 'Reduction of "project waste" (failed projects)',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        }
                    }
                }
            ],
            footer: {
                const_reduction_failed_projects: {
                    label: 'Cost reduction through reduction of "project waste"',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'reduction_failed_projects_percentage',
                        '*',
                        'meta_data.all_projects_total_cost'
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'const_reduction_failed_projects',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    technology_and_data_expertise: {
        data_index: 3,
        title: 'Technology & Data Expertise',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        total_revenue: {
                            label: 'Total Revenue',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        total_cost_base: {
                            label: 'Total Cost Base',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_cost_base'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        revenue_increase: {
                            label: 'Fully fletched data driven company: revenue increase',
                            value: '16',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        cost_reduction: {
                            label: 'Fully fletched data driven company: cost reduction',
                            value: '8',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%-',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        achievement_data_driven_company: {
                            label: 'Achievement on the way to data driven company',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        revenue_increase_initiative: {
                            label: 'Revenue increase through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_revenue',
                                '*',
                                'revenue_increase',
                                '*',
                                'achievement_data_driven_company'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        cost_reduction_initiative: {
                            label: 'Cost reduction through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.total_cost_base',
                                '*',
                                'cost_reduction',
                                '*',
                                'achievement_data_driven_company'
                            ],
                            type: '',
                            prefix: '€-',
                        }
                    }
                }
            ],
            footer: {
                total_increase_revenue_initiative: {
                    label: 'Total increase in revenue through initiative p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.total_revenue',
                        '*',
                        'revenue_increase',
                        '*',
                        'achievement_data_driven_company'
                    ],
                    type: '',
                    prefix: '€',
                },
                profit_impact: {
                    label: 'Profit Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.profit_impact',
                        '*',
                        'total_increase_revenue_initiative',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        [
                            'profit_impact',
                            '+',
                            'cost_reduction_initiative'
                        ],
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    ecological_and_social_sustainability: {
        data_index: 4,
        title: 'Ecological & Social Sustainability',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        penalty_total_cost: {
                            label: 'Total penalty cost p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.penalty_total_cost'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        reduction_penalties_digital_initiative: {
                            label: 'Reduction in penalties through digital initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        total_reduction_penalties: {
                            label: 'Total reduction in penalties',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.penalty_total_cost',
                                '*',
                                'reduction_penalties_digital_initiative'
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        average_sick_days: {
                            label: 'Average sick days per FTE p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        },
                        fte_total_cost: {
                            label: 'Total cost for FTE / Personal p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.fte_total_cost'
                            ],
                            type: '',
                            prefix: '€',
                        },
                        potential_reduction_sick_days_usage_digital_product: {
                            label: 'Potential reduction of sick days by usage of digital product',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '%',
                        },
                        number_official_working_days: {
                            label: 'Number of official working days per FTE p.a.',
                            value: '220',
                            isEdit: true,
                            calculated: [],
                            type: 'days',
                            prefix: '',
                        },
                        cost_reduction_sick_days: {
                            label: 'Cost reduction through less sick days p.a.',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                [
                                    'meta_data.fte_total_cost',
                                    '/',
                                    'number_official_working_days'
                                ],
                                '*',
                                [
                                    'average_sick_days',
                                    '*',
                                    'potential_reduction_sick_days_usage_digital_product'
                                ]
                            ],
                            type: '',
                            prefix: '€',
                        }
                    }
                }
            ],
            footer: {
                total_cost_reduction_initiative: {
                    label: 'Total cost reduction through initiative p.a.',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_reduction_penalties',
                        '+',
                        'cost_reduction_sick_days',
                    ],
                    type: '',
                    prefix: '€',
                },
                equity_impact_industry_multiplier: {
                    label: 'Industry Multiple for Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'meta_data.equity_impact_industry_multiplier',
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_cost_reduction_initiative',
                        '*',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    strategic_bets: {
        data_index: 5,
        title: 'Strategic Bets',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        equity_value_strategic_bet: {
                            label: 'Equity Value of strategic bet',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [],
                            type: '',
                            prefix: '',
                        }
                    }
                }
            ],
            footer: {
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'equity_value_strategic_bet',
                        '*',
                        '1'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    },
    equity_story: {
        data_index: 6,
        title: 'Equity Story',
        data: {
            body: [
                {
                    title: '',
                    data: {
                        equity_impact_industry_multiplier: {
                            label: 'Industry Multiple for Equity Impact',
                            value: '',
                            labelInfo: '',
                            isEdit: false,
                            calculated: [
                                'meta_data.equity_impact_industry_multiplier'
                            ],
                            type: '',
                            prefix: '',
                        }
                    }
                },
                {
                    title: '',
                    data: {
                        new_equity_impact_industry_multiplier: {
                            label: 'New industry multiple to be achieved through initiative',
                            value: '',
                            labelInfo: '',
                            isEdit: true,
                            calculated: [
                                'meta_data.equity_impact_industry_multiplier'
                            ],
                            type: '',
                            prefix: '',
                        }
                    }
                }
            ],
            footer: {
                total_sum_profit_impacts_all_sectors: {
                    label: 'Total sum of profit impacts / cost reduction through all sectors',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'allSumm.equity_impact',
                        '/',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '€',
                },
                improvement_multiple_initiative: {
                    label: 'Improvement in multiple through initiative',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'new_equity_impact_industry_multiplier',
                        '-',
                        'meta_data.equity_impact_industry_multiplier'
                    ],
                    type: '',
                    prefix: '',
                },
                equity_impact: {
                    label: 'Equity Impact',
                    value: '',
                    labelInfo: '',
                    isEdit: false,
                    calculated: [
                        'total_sum_profit_impacts_all_sectors',
                        '*',
                        'improvement_multiple_initiative'
                    ],
                    type: '',
                    prefix: '€',
                }
            }
        }
    }
}

export default data
