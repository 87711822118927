<template>
  <div>
    <a-button
        type="primary"
        shape="primary"
        icon="left"
        size="large"
        @click="handleBack"
        :style="{'margin-bottom': '30px'}"
    ></a-button>
    <a-button
        v-if="isEdit"
        v-show="!finishCanvas"
        type="primary"
        size="large"
        :style="{'margin-bottom': '30px', 'margin-left': '30px'}"
        @click="saveProcess"
    >
      <a-icon type="save"/>
      Save Progress
    </a-button>
    <a-button
        v-if="isEdit"
        v-show="finishCanvas"
        type="primary"
        size="large"
        :style="{'margin-bottom': '30px', 'margin-left': '30px'}"
        @click="completeCanvas"
    >
      <a-icon type="check"/>
      Finished filling in Digital Value Canvas
    </a-button>
    <a-row>
      <a-col :span="24">
        <h1>Define Target State: {{ this.portfolioInfor.portfolio_name }}</h1>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-around" align="top">
      <a-col :span="12">
        <div style="width:100%； min-height: 600px; position: relative;">
          <div
              class="logo-bg-position"
              :style="{

            'background-image': 'url('+(portfolioInfor.client_id['logo'])+')',
            }"
          ></div>
          <echarts-doughnut-chart
              ref="chart"
              :canvasSegments="canvasSegments"
              is-target
              v-on:onClickSegment="onClickSegment"
          ></echarts-doughnut-chart>
        </div>
      </a-col>
      <a-col :span="12">
        <ProjectCanvasSegmentFormNew
            ref="form"
            :isEdit="isEdit"
            :confirmLoading="confirmLoading"
            @submit="handleSubmit"
            @closeForm="closeForm"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart";
import ProjectCanvasSegmentFormNew from "../../components/Project/ProjectCanvas/ProjectCanvasSegmentFormNew.vue";
import { canvasSegments, canvasColors } from "../../staticData/CanvasLabels";
import Color from "color";
import { uniq } from "lodash/array";
import sector_meta_data from '../../staticData/SectorMetaData.js'

export default {
  components: {
    EchartsDoughnutChart,
    ProjectCanvasSegmentFormNew
  },
  data() {
    return {
      isEdit: true,
      portfolio_id: "",
      selectedSegment: {},
      tagsGroup: [],
      canvasSegments,
      formData: {
        title: "Awsome!!",
        point: "",
        comments: "",
        totalAssignedPoint: 0
      },
      sector_meta_data,
      confirmLoading: false,
      finishCanvas: false,
      portfolioInfor: {},
      segmentColors: {}
    };
  },
  async created() {
    this.portfolio_id =
        this.$route.params.portfolio_id || "5e6896afa8bf5e550fd937a7";

    this.initData();
    await this.loadData();
  },
  methods: {
    onClickSegment(args) {
      this.selectedSegment = args.data;

      this.$refs?.form.hideForm()
      this.formData.key = 'default'
      this.formData.data_index = 0
      this.formData.title = this.selectedSegment.name
      this.formData.data = this.sector_meta_data.default.data
      this.formData.data.footer.assigned_points.value = this.$store.state.SectorMetaData.default.data.assigned_points
      this.formData.data.footer.available_points.value = 100 - this.$store.state.SectorMetaData.default.data.assigned_points
      for (const key in this.formData.data.footer) {
        if (this.formData.data.footer[key].isEdit) {
          this.formData.data.footer[key].value = this.selectedSegment[key]
        }
      }
      this.$refs?.form.showForm(this.formData)
    },
    closeForm() {
      this.$refs["chart"].unHighlightSegment();
    },
    async handleSubmit(formValues) {
      this.confirmLoading = true;
      let segment = null
      let series_name = null
      for (const key in this.canvasSegments) {
        segment = this.canvasSegments[key].find(item =>
            item.data_index === this.selectedSegment.data_index
        )
        if (segment) {
          series_name = key
          break;
        }
      }
      if (formValues.point !== undefined) {
        segment.point = formValues.point
      }
      if (formValues?.comments) {
        segment.comments = formValues.comments
      }


      //post data
      const data = {
        portfolio: this.portfolio_id,
        series_name: series_name,
        data_index: segment.data_index,
        comments: segment.comments,
        point: segment.point
      };

      //update segment data on server
      try {
        if (segment._id) {
          await this.$http.put("/admin/api/rest/portfoliocanvas/" + segment._id, data)
        } else {
          //create segment data on server
          const response = await this.$http.post("/admin/api/rest/portfoliocanvas", data)
          segment._id = response.data._id;
        }
        this.$message.success("Update Canvas Success.")
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
      //update segment item style
      // this.updateSegmentStyle(segment);
      this.renderSegments(this.canvasSegments);
      //udate tags
      this.updateTags();

      this.$refs?.chart.updatedData()
      this.confirmLoading = false;
    },

    initData() {
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value._id = null;
          value.point = 0;
          value.comments = "";
          value.metrics = "";
          value.explanation = "";
          value.sector_meta_data = null;
          value.equity_impact = '';
          value.itemStyle = null;
        }
      }
    },

    initSegmentColors() {
      this.segmentColors = {};
      if (this.portfolioInfor.client_id["color_code"]) {
        const colorTheme = Color(
            this.portfolioInfor.client_id["color_code"].trim()
        );
        for (let i = 0; i <= 20; i++)
          this.segmentColors[i] = colorTheme
              .lighten(0.8 - (0.8 / 21) * (i + 1))
              .hex();
      } else {
        this.segmentColors = canvasColors;
      }
    },

    async loadData() {
      this.$http
          .get("/admin/api/rest/portfolio/" + this.portfolio_id)
          .then(response => {
            this.portfolioInfor = response.data;
            this.portfolioInfor.client_id["logo"] = process.env.VUE_APP_HOST
                ? process.env.VUE_APP_HOST + this.portfolioInfor.client_id["logo"]
                : this.portfolioInfor.client_id["logo"];
            this.initSegmentColors();
            //update logo of client on charts
            // this.$refs['chart'].$refs['echart'].options.graphic.elements[0].style.image = this.portfolioInfor.client_id['logo'];

            this.$http
                .get("/admin/api/rest/portfoliocanvas/portfolio-canvas/" + this.portfolio_id)
                .then(response => {
                  const segmentsData = response.data;
                  if (segmentsData) {
                    segmentsData.map(data => {
                      const replaceData = this.canvasSegments[
                          data.series_name
                          ].find(value => value.data_index === data.data_index);
                      replaceData.point = data.point;
                      replaceData.comments = data.comments;
                      replaceData._id = data._id;
                      // this.updateSegmentStyle(replaceData);
                    });
                    this.renderSegments(this.canvasSegments);
                    this.updateTags();
                  }
                })
                .catch(error => {
                  this.$message.error(error.response.data.message);
                });
          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          });
    },
    renderSegments(canvasSegments) {
      // alert('renderSegments');
      let segmentPoints = [
        ...canvasSegments["direct"],
        ...canvasSegments["indirect"],
        ...canvasSegments["goodway"]
      ]
          .filter(segment => segment.point > 0)
          .map(x => x.point);
      segmentPoints = uniq(segmentPoints);
      segmentPoints.sort((a, b) => a - b);


      [
        ...canvasSegments["direct"],
        ...canvasSegments["indirect"],
        ...canvasSegments["goodway"]
      ].map(segment => {
        let itemColor = false;
        if (segment.point > 0) {
          let pointIndex = segmentPoints.indexOf(segment.point);
          if (pointIndex > 0 && pointIndex < segmentPoints.length - 1) {
            pointIndex =
                pointIndex * Math.round(20 / (segmentPoints.length - 1));
            pointIndex = pointIndex > 19 ? 19 : pointIndex;
          } else if (pointIndex == 0 && segmentPoints.length > 1)
            pointIndex = 1;
          else if (pointIndex == segmentPoints.length - 1) pointIndex = 20;
          itemColor = this.segmentColors[pointIndex];

        }

        if (itemColor) segment.itemStyle = {  color: itemColor };
        else segment.itemStyle = null;
      });
    },
    updateSegmentStyle(segment) {
      let itemColor = false;
      if (segment.point > 0) {
        itemColor = this.segmentColors[Math.ceil(segment.point / 5)];
      } else {
        segment.itemStyle = null;
      }
      if (itemColor) segment.itemStyle = {  color: itemColor };
    },
    updateTags() {
      this.tagsGroup = [
        ...this.canvasSegments["direct"],
        ...this.canvasSegments["indirect"],
        ...this.canvasSegments["goodway"]
      ].filter(segment => segment.point > 0);

      let summ = 0
      for (const item of this.tagsGroup) {
        summ += parseFloat(item.point)
      }

      this.$store.state.SectorMetaData.default.data.assigned_points = summ

      if (summ === 100) this.finishCanvas = true;
      else this.finishCanvas = false;
    },
    handleBack() {
      this.$router.go(-1);
    },
    saveProcess() {
      this.$message.success("Save Success.");
    },
    async completeCanvas() {
      await this.$http.post("/admin/api/rest/portfolio/portfolio-dvc-complete", {
        portfolio_id: this.portfolio_id
      });
      this.handleBack();
      this.$message.success("Save Success.");
    }
  }
};
</script>

<style scoped>
.logo-bg-position {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 180px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
}

.echarts {
  width: 100%;
  height: 600px;
}

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
