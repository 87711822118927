<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-button type="primary" shape="primary" icon="left" size="large" @click="handleBack"></a-button>
        <h1 style="text-align: center; width: 100%;">{{ this.id ? 'Edit' : 'New' }} Client</h1>
        <a-spin :spinning="loading">
          <a-form :form="form" @submit.prevent="handleSubmit">
            <a-form-item label="Client Name" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <a-input v-decorator="['client_name', {rules:[{required:true,message:'Required'}]}]"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <span slot="label">
                Excubate Contact&nbsp;
                <a-tooltip title="Please separate multiple entries with a semicolon.">
                  <a-icon type="question-circle-o"/>
                </a-tooltip>
              </span>
              <a-input
                  v-decorator="['excubate_contact', {rules:[{required:true,message:'Required'}]}]"
                  :disabled="!this.editExcubateContact"
              />
            </a-form-item>
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <span slot="label">
                Excubate Email&nbsp;
                <a-tooltip title="Please separate multiple entries with a semicolon.">
                  <a-icon type="question-circle-o"/>
                </a-tooltip>
              </span>
              <a-input
                  v-decorator="['excubate_email', {rules:[{required:true,message:'Required'}]}]"
                  :disabled="!this.editExcubateContact"
              />
            </a-form-item>
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <span slot="label">
                Client Contact&nbsp;
                <a-tooltip title="Please separate multiple entries with a semicolon.">
                  <a-icon type="question-circle-o"/>
                </a-tooltip>
              </span>
              <a-input
                  v-decorator="['client_contact', {rules:[{required:true,message:'Required'}]}]"
                  :disabled="!this.editClientContact"
              />
            </a-form-item>
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <span slot="label">
                Client Email&nbsp;
                <a-tooltip title="Please separate multiple entries with a semicolon.">
                  <a-icon type="question-circle-o"/>
                </a-tooltip>
              </span>
              <a-input
                  v-decorator="['client_email', {rules:[{required:true,message:'Required'}]}]"
                  :disabled="!this.editClientContact"
              />
            </a-form-item>
            <a-form-item
                label="Client Color"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
                :validate-status="colorCodeValidate.validateStatus"
                :help="colorCodeValidate.errorMsg || colorCodeValidate.tips"
            >
              <a-input @change="handleColorChange" v-decorator="['color_code']"/>
            </a-form-item>
            <a-form-item label="Client Logo" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <upload-image ref="client-logo"></upload-image>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
              <a-button type="primary" html-type="submit" :loading="confirmLoading">Submit</a-button>
              <a-button
                  v-if="id&&[$userRole.EXCUBATE_ADMIN].includes($store.getters.getUserRole)"
                  type="danger"
                  :loading="confirmLoading"
                  @click.prevent="deleteClient"
                  :style="{'margin-left': '20px'}"
              >Delete Client
              </a-button>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UploadImage from "../../components/UploadImage";

export default {
  components: {
    UploadImage
  },
  // props: ['title', 'id'],
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this),
      confirmLoading: false,
      loading: false,
      editClientContact: false,
      colorCodeValidate: {
        tips: "HED code: #75c845",
        validateStatus: "success",
        errorMsg: "HED code: #75c845"
      },
      editExcubateContact:
          this.$store.getters.getUserRole == this.$userRole.EXCUBATE_ADMIN
    };
  },
  methods: {
    deleteClient(e) {
      e.preventDefault();

      this.$confirm({
        title: "Do you want to delete this client?",
        content: "",
        onOk: () => {
          return new Promise(resolve => {
            if (this.id) {
              this.confirmLoading = true;
              this.$http
                  .delete("/admin/api/rest/client/" + this.id)
                  .then(res => {
                    if (res.status) {
                      this.$message.success("Delete Client Success");
                      this.handleBack();
                    } else {
                      this.$message.error(res.msg);
                    }
                    this.confirmLoading = false;
                  })
                  .catch(error => {
                    this.$message.error(error.response.data.message);
                    this.confirmLoading = false;
                  });
            }

            resolve();
          }).catch(() => {
            this.loading = false;

          });
        },
        onCancel() {
        }
      });
    },
    handleColorChange(value) {
      let colorValidate = {
        validateStatus: "success",
        errorMsg: null
      };
      if (
          value.target.value != "" &&
          !/^#[0-9A-F]{6}$/i.test(value.target.value)
      ) {

        colorValidate["validateStatus"] = "error";
        colorValidate["errorMsg"] = "HEX Code Invalid";
      }

      this.colorCodeValidate = {
        ...colorValidate,
        value
      };
    },
    async handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err && this.colorCodeValidate.validateStatus == "success") {
          const emailPattern = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
          let emailValidation = true;
          this.confirmLoading = true;
          let model = null;
          values["logo"] = this.$refs["client-logo"].getImageUri();
          if(values['logo']) {
            values["logo"] = values['logo'].replace(process.env.VUE_APP_HOST, '');
          }
          values["excubate_email"] = values["excubate_email"].split("; ");
          values["excubate_contact"] = values["excubate_contact"].split("; ");
          if (
              values["excubate_email"].length != values["excubate_contact"].length
          ) {
            this.$message.error(
                "Excubate Contact not match with Excubate Email"
            );
            this.confirmLoading = false;
            return;
          } else {
            emailValidation = values["excubate_email"].every(element => {
              return emailPattern.test(element);
            });

            if (!emailValidation) {
              this.$message.error("Excubate Email is malformed");
              this.confirmLoading = false;
              return;
            }
          }
          values["excubate_user"] = [];
          values["excubate_email"].forEach((element, index) => {
            values["excubate_user"].push({
              email: element,
              name: values["excubate_contact"][index]
            });
          });

          values["client_email"] = values["client_email"].split("; ");
          values["client_contact"] = values["client_contact"].split("; ");
          if (
              values["client_email"].length != values["client_contact"].length
          ) {
            this.$message.error("Client Contact not match with Client Email");
            this.confirmLoading = false;
            return;
          } else {
            emailValidation = values["client_email"].every(element => {
              return emailPattern.test(element);
            });

            if (!emailValidation) {
              this.$message.error("Client Email is malformed");
              this.confirmLoading = false;
              return;
            }
          }
          values["client_user"] = [];
          values["client_email"].forEach((element, index) => {
            values["client_user"].push({
              email: element,
              name: values["client_contact"][index]
            });
          });


          if (this.id)
            model = await this.$http.put(`/admin/api/rest/client/${this.id}`, values);
          else model = await this.$http.post(`/admin/api/rest/client`, values);

          if (model) this.handleBack();
          this.confirmLoading = false;
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async fetch() {
      //init form value
      let model = {};
      if (this.id) {
        this.loading = true;
        model = await this.$http.get("/admin/api/rest/client/" + this.id);
        // for(let i=1; i<1000; i++)
        //   model = await this.$http.get("/admin/api/rest/client/" + this.id);
        if (!Array.isArray(model.data.excubate_user)) {
          model.data.excubate_email = model.data.excubate_user.email;
          model.data.excubate_contact = model.data.excubate_user.user_name;
        } else {
          model.data.excubate_email = model.data.excubate_user
              .map(value => value.email)
              .join("; ");
          model.data.excubate_contact = model.data.excubate_user
              .map(value => value.user_name)
              .join("; ");
        }
        if (!Array.isArray(model.data.client_user)) {
          model.data.client_email = model.data.client_user.email;
          model.data.client_contact = model.data.client_user.user_name;
        } else {
          model.data.client_email = model.data.client_user
              .map(value => value.email)
              .join("; ");
          model.data.client_contact = model.data.client_user
              .map(value => value.user_name)
              .join("; ");
        }

        if (model.data.logo && model.data.logo.indexOf(";base64,") === -1) {
          model.data.logo = process.env.VUE_APP_HOST
              ? process.env.VUE_APP_HOST + model.data.logo
              : model.data.logo;
        }

        this.$refs["client-logo"].setImageUri(model.data.logo);

        this.form.setFieldsValue(model.data);
        this.loading = false;
      } else {
        if (
            this.$store.getters.getUserRole == this.$userRole.EXCUBATE_EMPLOYEE
        ) {
          model.data = {};
          model.data.excubate_email = this.$store.getters.getUserInfor.email;
          model.data.excubate_contact = this.$store.getters.getUserInfor.user_name;

          this.form.setFieldsValue(model.data);
        }
      }
    }
  },
  async created() {
    this.id = this.$route.params.client_id || "";
    this.editClientContact =
        this.$store.getters.getUserRole == this.$userRole.EXCUBATE_ADMIN ||
        (!this.id &&
            this.$store.getters.getUserRole == this.$userRole.EXCUBATE_EMPLOYEE) ||
        (this.id && this.$store.getters.getUserRole == this.$userRole.CLIENT_DTO);
  },
  async mounted() {
    await this.fetch();
  }
};
</script>
