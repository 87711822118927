<template>
  <div class="login">
    <div class="login__logo">
      <a href="https://www.excubate.de" target="_blank">
        <img class="login__logo-img" src="../assets/excubate-logo-full-high-resolution-min.png"/>
      </a>
    </div>

    <a-form class="login__form"
            :form="form"
            @submit.prevent="handleSubmit"
    >
      <a-form-item>
        <h2 class="login__form__header">
          Log in
        </h2>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
          'email',
          {rules:[{type:'email',message:'Email address is malformed'},{required:true,message:'Required'}]}
        ]"
            placeholder="Email"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
          'password',
          {rules:[{required:true,message:'Required'}]}
        ]"
            type="password"
            placeholder="Password"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
        </a-input>
        <a class="login__form__forgot"
           @click="toForgotPassword"
        >
          Forgot your password?
        </a>
      </a-form-item>
      <a-form-item>
        <a-button class="login__form__log-in"
                  type="primary"
                  html-type="submit"
                  :loading="submitLoading"
        >
          Log in
        </a-button>

        <div v-if="!isPrivateInstance" class="login__form__sign-up">
          <p class="login__form__sign-up-text">
            Dont have an account?
          </p>
          <a class="login__form__sign-up-link"
             @click="toSignUp"
          >
            Sign Up
          </a>
        </div>
      </a-form-item>
    </a-form>
  </div>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      submitLoading: false,
      isPrivateInstance:process.env.VUE_APP_PRIVATE_INSTANCE
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  computed: {
    ...mapGetters({
      getUserRole: 'getUserRole'
    })
  },
  methods: {
    ...mapMutations({
      updateLoginStatus: 'updateLoginStatus',
      updateUserInfor: 'updateUserInfor'
    }),
    toForgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
    toSignUp() {
      this.$router.push({ name: "register" });
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitLoading = true;
          try {
            let response = await this.$http.post("/admin/api/login", values)
            if (response.data.token) {
              localStorage.removeItem('token');
              localStorage.removeItem('user_name');
              localStorage.removeItem('vuex');
              localStorage.token = response.data.token;
              localStorage.user_name = response.data.user.user_name;
              this.updateLoginStatus(true);
              this.updateUserInfor(response.data.user);
              if (this.getUserRole == this.$userRole.DPO || response.data.firstLogin) {
                this.$router.push({
                  name: "tutorial"
                });
              } else {
                this.$router.push({ name: "client-list" })
              }

              this.$message.success("login success.");
            }
            // handle success
            this.submitLoading = false
          } catch (error) {
            // handle error
            if (error.response.data.message.includes('Email')) {
              this.form.setFields({
                email: {
                  value: values.email,
                  errors: [new Error(error.response.data.message)]
                }
              })
            } else {
              this.form.setFields({
                password: {
                  value: values.password,
                  errors: [new Error(error.response.data.message)]
                }
              })
            }
            this.submitLoading = false
          }
        }
      });
    },
  }
};
</script>

<style lang="less">
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

.login {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    &-img {
      height: 100px;
      width: 300px;
    }
  }

  &__form {
    border: 1px solid #001529;
    width: 535px;
    border-radius: 5px;
    padding: 25px 68px;
    background: white;
    box-shadow: 0 2 10 0 rgba(188, 202, 224, 0.5);

    &__header {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.75px;
      color: #000000;
    }

    & .ant-form-item:last-of-type {
      margin-top: 75px;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    &__forgot {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: rgb(0, 76, 162);

      &:hover {
        color: #4f88b2;
      }
    }

    &__log-in {
      width: 100%;
      height: 40px;
    }

    &__sign-up {
      display: flex;
      justify-content: center;
      align-items: center;

      &-text {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: rgba(42, 47, 55, 0.6);
        margin: 0;
        margin-right: 2px;
      }

      &-link {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: rgb(0, 76, 162);

        &:hover {
          color: #4f88b2;
        }
      }
    }
  }
}
</style>
