<template>
  <div class="meta-data__project">
    <div class="meta-data__project__header">
      <h3 class="meta-data__project__header-title">
        Meta Data
      </h3>
      <a-button class="meta-data__project__header-btn"
                type="default"
                size="large"
                @click="toOverwriteMetaData"
      >
        OVERWRITE META DATA
      </a-button>
    </div>
    <div class="meta-data__project__content">
      <MetaDataProjectItem v-for="catalog in data"
                           :key="catalog.id"
                           :title="catalog.title"
                           :data="catalog.data"
      />
    </div>
  </div>
</template>

<script>
import MetaDataProjectItem from './MetaDataProjectItem'

export default {
  name: 'MetaDataProject',
  components: {
    MetaDataProjectItem
  },
  props: {
    project_id: String,
    user_id: String,
    data: Array
  },
  methods: {
    toOverwriteMetaData() {
      this.$router.push({
        name: 'meta-data-project',
        params: {
          project_id: this.project_id,
          user_id: this.user_id
        }
      })
    }
  }
}
</script>

<style lang="less">
.meta-data__project {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  // height: 610px;
  width: 450px;
  flex-grow: 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &-title {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 20px;
      color: #393E46;
      letter-spacing: 0.43px;
      width: max-content;
      height: max-content;
      margin: 0;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      border-color: rgb(87, 172, 50);
      color: rgb(87, 172, 50);
      background-color: #fff;

      & .client__portfolio-list__header__btn-svg {
        fill: rgb(87, 172, 50) !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover .client__portfolio-list__header__btn-svg path {
        fill: #95d46c !important;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
  }
}
</style>
