<template>
  <div>
    <a-row>
      <a-col :span="24">
        The three rings of the Digital Value Canvas consist of a number of value drivers. Click on one to find out more
        about them. You will be asked to assign a total of 100 points to the value drivers that seem most
        relevant/applicable to your digital project. More points imply higher value creation and vice versa.
      </a-col>
    </a-row>
    <a-row style="margin-top: 20px;">
      <a-col :span="24">
        <b>Tip</b>: Try to limit the the number of value drivers and focus on the most important ones. Assign different
        values to different value drivers in order to rank them.
      </a-col>
    </a-row>
    <a-row type="flex" align="middle">
      <a-col :span="12">
        <echarts-doughnut-chart
            ref="chart"
            :canvasSegments="canvasSegments"
            v-on:onClickSegment="onClickSegment"
        ></echarts-doughnut-chart>
      </a-col>
      <a-col :span="12">
        <canvas-introduction-modal
            ref="modal"
            :modalData="modalData"
            :visible="visible"
            @hide="hideModal"
        ></canvas-introduction-modal>
      </a-col>
    </a-row>
    <a-row justify="end">
      <a-col :span="6">
        <a-button
            type="primary"
            shape="primary"
            icon="left"
            size="large"
            @click="dvcintroduction()"
        >Previous
        </a-button>
      </a-col>
      <a-col :span="6" :offset="12">
        <a-button
            type="primary"
            shape="primary"
            icon="right"
            size="large"
            @click="myProject()"
        >
          Start value assessment
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<style scoped>
ul {
  padding-left: 15px;
}
</style>
<script>
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart";
import CanvasIntroductionModal from "../../components/CanvasIntroductionModal";
import { canvasSegments } from "../../staticData/CanvasLabels";
import { mapGetters } from 'vuex'

export default {
  components: {
    EchartsDoughnutChart,
    CanvasIntroductionModal
  },
  data() {
    return {
      canvasSegments,
      isEdit: false,
      project_id: "",
      projectInfor: {},
      modalData: {
        title: "Awsome!!",
        point: "",
        metrics: "",
        explanation: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      getUserInfor: 'getUserInfor'
    })
  },
  async created() {
    this.initSegmentColor();
  },
  methods: {
    initSegmentColor() {
      let segmentBgColor = "";
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value.point = 0;
          value.metrics = "";
          value.explanation = "";
          value._id = null;
          if (key == "goodway") segmentBgColor = "#C8E9B5";
          else if (key == "indirect") segmentBgColor = "#75C945";
          else segmentBgColor = "#2C5018";
          value.itemStyle = { color: segmentBgColor };
        }
      }
    },
    onClickSegment(...args) {
      this.selectedSegment = args[0][0];
      // this.modalData.title = this.dataLabels[ele._datasetIndex][ele._index];
      this.modalData.title = this.selectedSegment.data.name;
      this.modalData.introduction = this.selectedSegment.data.introduction;
      this.showModal();
    },
    showModal() {
      this.$refs["modal"].showForm();
    },
    hideModal() {
      this.$refs["chart"].unHighlightSegment();
    },
    async myProject() {
      if (this.$store.getters.getUserRole == 4) {
          this.dpoProjects();
      } else {
        this.noneDpoProjects();
      }
    },
    async dpoProjects() {
      this.$router.push({
          name: "dpo-projects",
      });
    },
    async noneDpoProjects() {
      const res = await this.$http.get("/admin/api/rest/client");
        this.$router.push({
          name: "portfolio",
          params: {
            portfolio_id: res.data[0].portfolio[0]._id
          }
        });
    },
    dvcintroduction() {
      this.$router.push({
        name: "tutorial-dvc-introduction"
      });
    },
    handleBack() {
      this.$router.go(-1);
    }
  }
};
</script>
