<template>
  <div class="meta-data">
    <div class="meta-data__header">
      <div class="meta-data__header__title">
        <a-button class="meta-data__header__title-btn"
                  type="primary"
                  icon="left"
                  size="large"
                  @click="handleBack"
        />
        <MetaDataTitleSvg class="meta-data__header__title-svg"/>
        <h2 class="meta-data__header__title-name">
          {{ title }}
        </h2>
      </div>
      <div class="meta-data__header__btns">
        <a-button class="meta-data__header__btn meta-data__header__reset-btn"
                  v-if="projectName"
                  type="default"
                  size="large"
                  :disabled="disabledResetBtn"
                  @click="resetMetaData"
        >
          <MetaDataResetSvg class="meta-data__header__btn-svg meta-data__header__reset-svg"/>
          <span class="meta-data__header__btn-text">
            RESET META DATA
          </span>
        </a-button>
        <a-button class="meta-data__header__btn meta-data__header__save-btn"
                  type="primary"
                  size="large"
                  html-type="submit"
                  @click="handleSave"
                  :loading="saveLoading"
        >
          <MetaDataSaveSvg class="meta-data__header__btn-svg"/>
          <span class="meta-data__header__btn-text">
            SAVE
          </span>
        </a-button>
      </div>
    </div>
    <div class="meta-data__info">
      <MetaDataInfoSvg class="meta-data__info-svg"/>
      <p class="meta-data__info-text">
        Meta data needs to be filled out completely to achieve holistic calculation results
      </p>
    </div>
    <a-icon class="meta-data__loading"
            v-if="loading"
            type="loading"
    />
    <a-form class="meta-data__form"
            v-if="!loading"
            :form="form"
            @submit.prevent="handleSave"
            :loading="loading"
    >
      <MetaDataItem v-for="item in data"
                    :key="item.id"
                    :title="item.title"
                    :data="item.data"
                    :resetActive="resetActive"
                    @resetField="resetField"
                    ref="metaDataItem"
      />

    </a-form>
    <div class="display-flex">
      <a-button class="meta-data__header__btn meta-data__header__save-btn"
                type="primary"
                size="large"
                html-type="submit"
                @click="handleSave"
                :loading="saveLoading"
      >
        <MetaDataSaveSvg class="meta-data__header__btn-svg"/>
        <span class="meta-data__header__btn-text">
          SAVE
        </span>
      </a-button>
    </div>
    <MetaDataPopSave ref="defineMetaSave" @dontSave="dontSave"/>
    <a-button class="meta-data__scroll-top-btn"
              :class="{'meta-data__scroll-top-btn--show' : showScroll}"
              type="primary"
              icon="up"
              size="large"
              @click="scrollTop"
    />
  </div>
</template>

<script>
import MetaDataTitleSvg from '../../components/MetaData/MetaDataTitleSvg'
import MetaDataPopSave from '../../components/MetaData/MetaDataPopSave'
import MetaDataSaveSvg from '../../components/MetaData/MetaDataSaveSvg'
import MetaDataItem from '../../components/MetaData/MetaDataItem'
import MetaDataResetSvg from '../../components/MetaData/MetaDataResetSvg'
import data from '../../staticData/MetaData.js'
import MetaDataInfoSvg from '../../components/MetaData/MetaDataInfoSvg'
import { mapGetters, mapMutations } from 'vuex'
import numeral from 'numeral'

export default {
  name: 'MetaData',
  components: {
    MetaDataItem,
    MetaDataTitleSvg,
    MetaDataPopSave,
    MetaDataSaveSvg,
    MetaDataResetSvg,
    MetaDataInfoSvg
  },
  data() {
    return {
      data,
      projectName: '',
      isSave: true,
      saveLoading: false,
      loading: false,
      disabledResetBtn: true,
      client_id: '',
      project_id: '',
      resetActive: false,
      projectData: {},
      showScroll: false,
      elemScrolling: {},
    }
  },
  computed: {
    ...mapGetters({
      getClientMetaData: 'getClientMetaData',
    }),
    title() {
      if (this.projectName) {
        return `Overwrite Meta Data for ${this.projectName}`
      }
      return 'Define Meta Data'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSave) {
      next()
    } else {
      this.$refs.defineMetaSave.handleKnowMore({
        name: to.name,
        params: to.params
      })
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      onFieldsChange: () => {
        if (this.form.isFieldsTouched()) {
          this.isSave = false
          this.disabledResetBtn = false
        } else {
          let isChangeInitValue = false
          for (const key in this.getClientMetaData) {
            if (key === 'create_date' || key === '__v' || key === '_id') continue
            if (this.form.getFieldValue(key) !== this.getClientMetaData[key]) {
              isChangeInitValue = true
              break
            }
          }
          if (isChangeInitValue) {
            this.disabledResetBtn = false
          } else {
            this.disabledResetBtn = true
          }
        }
      }
    });
  },
  async created() {
    await this.loadData()
    this.elemScrolling = document.getElementById('main')
    this.elemScrolling.addEventListener('scroll', this.scrollActive)
  },
  methods: {
    ...mapMutations({
      setClientMetaData: 'setClientMetaData',
    }),
    resetField(nameField) {
      this.form.setFieldsValue({
        [nameField]: numeral(this.getClientMetaData[nameField]).format('0,0.[00]')
      })
      this.isSave = false
    },
    scrollActive(event) {
      if (event.target.scrollTop > 50) {
        this.showScroll = true
      } else {
        this.showScroll = false
      }
    },
    scrollTop() {
      this.elemScrolling.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    resetMetaData() {
      for (const metaDataName of Object.keys(this.getClientMetaData)) {
        if (metaDataName !== '_id' && metaDataName !== '__v') {
          this.form.setFieldsValue({
            [metaDataName]: numeral(this.getClientMetaData[metaDataName]).format('0,0.[00]')
          })
        }
      }

      this.disabledResetBtn = true
      for (const metaDataItem of this.$refs.metaDataItem) {
        for (const item of metaDataItem.$refs.metaDataItem) {
          item.disabledResetBtn = true
        }
      }
      this.isSave = false
    },
    dontSave() {
      this.isSave = true
    },
    handleSave() {
      this.saveLoading = true
      this.form.validateFields(async (err, values) => {
        if (!err) {

          let data = {}
          for (const key in values) {
            values[key] = numeral(values[key]).value()
            if (values[key] !== this.getClientMetaData[key]) {
              data[key] = values[key]
            }
            if (this.project_id) {
              if (values[key] !== this.projectData[key]) {
                data[key] = values[key]
              }
              if (values[key] === this.getClientMetaData[key]) {
                data[key] = null
              }
            }
          }


          let url = `clients/${this.client_id}`

          if (this.project_id) {
            url = `projects/${this.project_id}`
          }

          try {
            await this.$http.put(`/metadata/${url}`, data)
            this.isSave = true
            this.$message.success('Save Complete.')
          } catch (error) {

            this.$message.error(error.response.data.message);
          }
        } else {
          this.$message.error('You have entered the wrong value!')
        }
      });
      this.saveLoading = false
    },
    async loadData() {
      this.loading = true
      this.client_id = this.$route.params?.client_id

      try {
        let response

        //Get Info Project
        if (this.$route.params?.project_id) {
          this.project_id = this.$route.params.project_id
          this.resetActive = true

          response = await this.$http.get(`/admin/api/rest/project/${this.project_id}`)

          this.client_id = response.data.client_id._id
          this.projectName = response.data.project_name
        }

        //Meta Data Client
        response = await this.$http.get(`/metadata/clients/${this.client_id}`)

        this.setClientMetaData(response.data)
        for (const catalog of this.data) {
          for (const item of catalog.data) {
            item.initialValue = parseFloat(response.data[item.key])
          }
        }

        //Meta Data Project
        if (this.project_id) {
          response = await this.$http.get(`/metadata/projects/${this.project_id}`)

          for (const catalog of this.data) {
            for (const item of catalog.data) {
              if (response.data[item.key]) {
                item.initialValue = parseFloat(response.data[item.key])
                this.projectData[item.key] = parseFloat(response.data[item.key])
                this.disabledResetBtn = false
              }
            }
          }
        }

        this.loading = false
      } catch (error) {
        this.$message.error(error.response?.data.message);
        this.loading = false
      }
    },
    handleBack() {
      if (this.isSave) {
        this.$router.go(-1);
      } else {
        this.$refs.defineMetaSave.handleKnowMore('-1')
      }
    }
  }
}
</script>

<style lang="less">
.meta-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__scroll-top-btn {
    position: fixed;
    opacity: 0;
    z-index: -1;
    bottom: 50px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 50px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    transition: all 0.2s ease-in-out;

    &--show {
      opacity: 1;
      z-index: 3;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
      }
    }

    &__btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__svg {
      margin-right: 10px;
    }

    &__btn {
      margin-left: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;
      height: 40px;
      min-width: 185px;
      padding: 0 15px !important;

      &-svg {
        margin-right: 10px;
      }

      &-text {
        flex-grow: 1;
      }
    }

    &__reset {
      &-btn {
        border-color: rgb(87, 172, 50);
        color: rgb(87, 172, 50);
        background-color: #fff;

        & .meta-data__header__reset-svg {
          fill: rgb(87, 172, 50) !important;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &[disabled="disabled"] .meta-data__header__reset-svg, &[disabled="disabled"]:hover .meta-data__header__reset-svg {
          fill: white !important;
        }

        &:hover .meta-data__header__reset-svg {
          fill: #95d46c !important;
        }
      }

      &-svg {
        width: 16, 25px;
        height: 15, 63px;
        fill: rgb(87, 172, 50);
        margin-right: 20px;
      }
    }

    &__save-btn {
      padding-right: 30px;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 640px;
    height: 40px;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #E29E00;
    margin-bottom: 35px;

    &-svg {
      width: 21px;
      height: 21px;
      fill: #FFFFFF;
      margin-right: 10px;
    }

    &-text {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      margin: 0;
    }
  }

  &__loading {
    width: 100%;

    & svg {
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

.display-flex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
