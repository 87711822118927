<template>
  <div class="points-euro-toggle">
    <p class="points-euro-toggle-text">
      Points
    </p>
    <a-switch class="points-euro-toggle-checked"
              default-checked
              :checked="getEuroActive"
              @change="changeTogle"
    />
    <p class="points-euro-toggle-text"
       :class="{ 'points-euro-toggle-text--active': getEuroActive }"
    >
      Euro
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getEuroActive: 'getEuroActive'
    })
  },
  methods: {
    ...mapMutations({
      setEuroActive: 'setEuroActive'
    }),
    changeTogle(checked) {
      this.setEuroActive(checked)
      this.$emit("changeEuroActive");
    },
  }
}
</script>

<style lang="less">
.points-euro-toggle {
  position: absolute;
  top: -42px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-content: center;

  &-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    color: rgb(42, 47, 55);
    margin: 0;

    &--active {
      font-weight: 500;
      color: rgb(0, 132, 226);
    }
  }

  &-checked {
    margin: 0 10px;

    &.ant-switch-checked {
      background-color: rgb(0, 132, 226);
    }
  }
}
</style>
