<template>
  <a-modal
      :title="titleConfirm"
      class="projects-pop-confirm"
      v-model="visible"
      on-ok="handleOk"
      on-cancel="handleCancel"
  >
    <template slot="footer">
      <a-button
          class="projects-pop-confirm__footer-btn projects-pop-confirm__footer-cancel-btn"
          type="default"
          @click="handleCancel"
      >
        СANCEL
      </a-button>
      <a-button
          class="projects-pop-confirm__footer-btn"
          :class="{ 'projects-pop-confirm__footer-btn--delete': activeDelete }"
          type="primary"
          @click="handleOk"
          :loading="loading"
      >
        {{ textBtnOk }}
      </a-button>
    </template>
    <div class="projects-pop-confirm__content">
      Do you really want to {{ textBtnOk.toLowerCase() }} this project?
    </div>
  </a-modal>
</template>

<script>

export default {
  name: 'ProjectsPopConfirm',
  props: {
    title: String,
    textBtnOk: String,
  },
  data() {
    return {
      visible: false,
      loading: false,
      projectInfo: {
        project_name: ''
      },
      record: {
        client_id: '',
        portfolio_id: '',
        project_id: '',
        user_id: ''
      }
    };
  },

  computed: {
    titleConfirm() {
      return `${this.title} ${this.projectInfo.project_name} Project?`
    },

    activeDelete() {
      return this.title === 'Delete'
    }
  },

  methods: {
    handleKnowMore(record) {
      this.visible = true;
      this.record = record;
      this.loadData()
    },

    async handleOk() {
      try {
        await this.$http.post(`/projects/${this.record.project_id}/portfolios/${this.record.portfolio_id}/detach`)
        if (this.projectInfo.portfolio_ids.length <= 1) {
          this.$router.push({
            name: 'portfolio-project-list',
            params: {
              portfolio_id: this.record.portfolio_id
            }
          })
        }
        this.$message.success(`Success project ${this.textBtnOk.toLowerCase()}.`)
      } catch (error) {
        this.$message.error(error.response.data.message)
      }
      this.visible = false;
      this.$emit('update')
    },

    handleCancel() {
      this.visible = false;
    },

    async loadData() {
      try {
        const response = await this.$http.get("/admin/api/rest/project/" + this.record.project_id)
        if (response.data) {
          this.projectInfo = response.data
        }
      } catch (error) {
        this.$message.error(error.response.data.message)
      }
    },
  },
};
</script>

<style lang="less">
.projects-pop-confirm {
  background-color: white;
  width: max-content;
  height: max-content;

  & .ant-modal {
    width: 600px !important;
    height: max-content !important;
  }

  & .ant-modal-header {
    background-color: white;
    border: none;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      text-align: left;
    }
  }

  & .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 28px;
    border: none;
  }

  & .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    width: 100%;
    height: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
  }

  &__footer {
    &-cancel-btn {
      border: none;
      color: black !important;

      &:hover {
        color: #75c845 !important;
      }
    }

    &-btn {
      height: 36px !important;
      padding: 10px 45px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;

      &--delete {
        border-color: #E22639;
        background-color: #E22639;

        &:hover {
          border-color: saturate(#E22639, 40%) !important;
          background-color: saturate(#E22639, 40%) !important;
        }

        &:focus {
          border-color: saturate(#E22639, 40%) !important;
          background-color: saturate(#E22639, 40%) !important;
        }
      }
    }
  }
}
</style>
