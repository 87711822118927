const getDefaultState = () => {
    return {
        default: {
            data_index: 0,
            data: {
                assigned_points: 0,
                point: 0,
            },
        },
        direct_revenue: {
            data_index: 14,
            data: {
                price_product: 0,
                estimated_amount_product_sales: 0,
            },
        },
        direct_cost_reduction: {
            data_index: 13,
            data: {
                process_cost: 0,
                new_cost_initiative: 0,
            },
        },
        core_product_sales: {
            data_index: 12,
            data: {
                increase_revenue_digital_product: 0,
                increase_number_products_sold_digital_initiative: 0,
                number_new_customers_digital_initiativ: 0,
            },
        },
        customer_satisfaction_and_retention: {
            data_index: 11,
            data: {
                number_additional_recommendations_customers: 0,
                recommendations_resulting_sale_percentage: 0,
                percentage_point_increase_retention_rate: 0,
            },
        },
        employee_effectiveness: {
            data_index: 8,
            data: {
                amount_employees_working_digital_solution_percentage: 0,
                increase_employee_effectiveness_solution: 0,
                average_annual_salary_affected_division: 0,
            },
        },
        equipment_utilization: {
            data_index: 10,
            data: {
                new_asset_utilization_initiative: 0,
            },
        },
        process_speed_and_quality: {
            data_index: 9,
            data: {
                total_process_cost: 0,
                reduction_process_cost_initiative_percentage: 0,
            },
        },
        brand_awareness: {
            data_index: 7,
            data: {
                total_estimated_market_size: 0,
                estimated_brand_awareness_market: 0,
                number_people_know_brand_initiative: 0,
                correction_factor: 100,
            },
        },
        employer_branding: {
            data_index: 1,
            data: {
                reduction_employee_churn_rate_initiative: 0,
            },
        },
        agile_culture_and_organizational_learning: {
            data_index: 2,
            data: {
                reduction_failed_projects_percentage: 0,
            },
        },
        technology_and_data_expertise: {
            data_index: 3,
            data: {
                revenue_increase: 16,
                cost_reduction: 8,
                achievement_data_driven_company: 0,
            },
        },
        ecological_and_social_sustainability: {
            data_index: 4,
            data: {
                reduction_penalties_digital_initiative: 0,
                average_sick_days: 0,
                potential_reduction_sick_days_usage_digital_product: 0,
                number_official_working_days: 220,
            },
        },
        strategic_bets: {
            data_index: 5,
            data: {
                equity_value_strategic_bet: 0,
            },
        },
        equity_story: {
            data_index: 6,
            data: {
                new_equity_impact_industry_multiplier: 0,
            },
        },
    }
};

const state = getDefaultState();

const SectorMetaData = {
    namespaced: true,
    state: state,
    getters: {
        direct_revenue__total_increase_digital_product: (state) => {
            let calculated =
                    state.direct_revenue.data.price_product *
                    state.direct_revenue.data.estimated_amount_product_sales;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        direct_revenue__profit_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.direct_revenue__total_increase_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        direct_revenue__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.direct_revenue__profit_impact;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        direct_cost_reduction__total_cost_reduction_initiative: (state) => {
            let calculated =
                    state.direct_cost_reduction.data.process_cost -
                    state.direct_cost_reduction.data.new_cost_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        direct_cost_reduction__equity_impact: (state, getters, rootState) => {
            let calculated =
                    getters.direct_cost_reduction__total_cost_reduction_initiative *
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__original_average_revenue_product: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.total_revenue /
                    rootState.meta_data.sold_products_total_number;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__average_number_products_customer: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.sold_products_total_number /
                    rootState.meta_data.customers_total_number;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__new_total_number_products_sold: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (state.core_product_sales.data
                                    .increase_number_products_sold_digital_initiative +
                            getters.core_product_sales__average_number_products_customer) *
                    (rootState.meta_data.customers_total_number +
                            state.core_product_sales.data.number_new_customers_digital_initiativ);
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__new_average_revenue_product: (state, getters) => {
            let calculated =
                    getters.core_product_sales__original_average_revenue_product +
                    state.core_product_sales.data.increase_revenue_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__total_increase_revenue_digital_product: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    getters.core_product_sales__new_total_number_products_sold *
                    getters.core_product_sales__new_average_revenue_product -
                    rootState.meta_data.total_revenue;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__profit_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.core_product_sales__total_increase_revenue_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        core_product_sales__equity_impact: (state, getters, rootState) => {
            let calculated =
                    getters.core_product_sales__profit_impact *
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__average_revenue_customer: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.total_revenue /
                    rootState.meta_data.customers_total_number;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__percentage_additional_recommendations_customers: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    state.customer_satisfaction_and_retention.data.number_additional_recommendations_customers
                    /
                    rootState.meta_data.customer_recommendations_amount;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__recommendations_resulting_sale_total: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (rootState.meta_data.customer_recommendations_amount +
                            state.customer_satisfaction_and_retention.data
                                    .number_additional_recommendations_customers) *
                    rootState.meta_data.customers_total_number *
                    state.customer_satisfaction_and_retention.data
                            .recommendations_resulting_sale_percentage;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__expected_additionally_retained_customers: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (rootState.meta_data.customer_retention_rate +
                            state.customer_satisfaction_and_retention.data
                                    .percentage_point_increase_retention_rate) *
                    rootState.meta_data.customers_total_number -
                    rootState.meta_data.customers_total_number *
                    rootState.meta_data.customer_retention_rate;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__expected_revenue_retention_customers: (
                state,
                getters
        ) => {
            let calculated =
                    getters.customer_satisfaction_and_retention__expected_additionally_retained_customers *
                    getters.customer_satisfaction_and_retention__average_revenue_customer;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__expected_revenue_new_customers: (
                state,
                getters
        ) => {
            let calculated =
                    getters.customer_satisfaction_and_retention__recommendations_resulting_sale_total *
                    getters.customer_satisfaction_and_retention__average_revenue_customer;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__total_increase_revenue_digital_product: (
                state,
                getters
        ) => {
            let calculated =
                    getters.customer_satisfaction_and_retention__expected_revenue_retention_customers +
                    getters.customer_satisfaction_and_retention__expected_revenue_new_customers;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__profit_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.customer_satisfaction_and_retention__total_increase_revenue_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        customer_satisfaction_and_retention__equity_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.customer_satisfaction_and_retention__profit_impact;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__amount_employees_working_digital_solution: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.fte_total_number *
                    state.employee_effectiveness.data
                            .amount_employees_working_digital_solution_percentage;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__number_redundant_employees_digital_solution: (
                state,
                getters
        ) => {
            let calculated =
                    getters.employee_effectiveness__amount_employees_working_digital_solution *
                    state.employee_effectiveness.data
                            .increase_employee_effectiveness_solution;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__total_FTE_saved_digital_solution: (
                state,
                getters
        ) => {
            let calculated =
                    getters.employee_effectiveness__number_redundant_employees_digital_solution *
                    state.employee_effectiveness.data.average_annual_salary_affected_division;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__total_increase_revenue_digital_product: (
                state,
                getters
        ) => {
            let calculated =
                    getters.employee_effectiveness__number_redundant_employees_digital_solution *
                    state.employee_effectiveness.data
                            .average_annual_salary_affected_division;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__profit_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.employee_effectiveness__total_increase_revenue_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employee_effectiveness__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.employee_effectiveness__profit_impact;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equipment_utilization__asset_cost_utilization_one_percent: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.asset_total_cost /
                    (rootState.meta_data.asset_utilization * 100);
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equipment_utilization__value_additional_asset_utilization_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (state.equipment_utilization.data.new_asset_utilization_initiative -
                            rootState.meta_data.asset_utilization) *
                    100 *
                    getters.equipment_utilization__asset_cost_utilization_one_percent;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equipment_utilization__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.equipment_utilization__value_additional_asset_utilization_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        process_speed_and_quality__total_cost_reduction_digital_product: (
                state
        ) => {
            let calculated =
                    state.process_speed_and_quality.data.reduction_process_cost_initiative_percentage
                    *
                    state.process_speed_and_quality.data.total_process_cost;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        process_speed_and_quality__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.process_speed_and_quality__total_cost_reduction_digital_product;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        brand_awareness__number_people_know_brand: (state) => {
            let calculated =
                    state.brand_awareness.data.total_estimated_market_size *
                    state.brand_awareness.data.estimated_brand_awareness_market;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        brand_awareness__sales_person_knows_brand: (state, getters, rootState) => {
            let calculated =
                    getters.brand_awareness__number_people_know_brand === 0 ? 0 :
                            rootState.meta_data.total_revenue /
                            getters.brand_awareness__number_people_know_brand;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        brand_awareness__total_increase_revenue_initiative: (state, getters) => {
            let calculated =
                    state.brand_awareness.data.correction_factor *
                    getters.brand_awareness__sales_person_knows_brand *
                    state.brand_awareness.data.number_people_know_brand_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        brand_awareness__profit_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.brand_awareness__total_increase_revenue_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        brand_awareness__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.brand_awareness__profit_impact;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employer_branding__number_hires_existing_role: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.fte_total_number *
                    rootState.meta_data.employee_churn_rate;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employer_branding__new_number_hires_existiing_roles: (state, getters) => {
            let calculated =
                    getters.employer_branding__number_hires_existing_role -
                    state.employer_branding.data.reduction_employee_churn_rate_initiative *
                    getters.employer_branding__number_hires_existing_role;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employer_branding__reduction_hiring_process_cost: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (getters.employer_branding__number_hires_existing_role -
                            getters.employer_branding__new_number_hires_existiing_roles) *
                    rootState.meta_data.avg_acquisition_cost_per_hire;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employer_branding__total_decrease_cost_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (getters.employer_branding__number_hires_existing_role -
                            getters.employer_branding__new_number_hires_existiing_roles) *
                    rootState.meta_data.avg_acquisition_cost_per_hire;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        employer_branding__equity_impact: (state, getters, rootState) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.employer_branding__total_decrease_cost_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        agile_culture_and_organizational_learning__const_reduction_failed_projects: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.all_projects_total_cost *
                    state.agile_culture_and_organizational_learning.data
                            .reduction_failed_projects_percentage;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        agile_culture_and_organizational_learning__equity_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.equity_impact_industry_multiplier *
                    getters.agile_culture_and_organizational_learning__const_reduction_failed_projects;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        technology_and_data_expertise__revenue_increase_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.total_revenue *
                    state.technology_and_data_expertise.data.revenue_increase *
                    state.technology_and_data_expertise.data
                            .achievement_data_driven_company;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        technology_and_data_expertise__cost_reduction_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.total_cost_base *
                    state.technology_and_data_expertise.data.cost_reduction *
                    state.technology_and_data_expertise.data
                            .achievement_data_driven_company;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        technology_and_data_expertise__total_increase_revenue_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.total_revenue *
                    state.technology_and_data_expertise.data.revenue_increase *
                    state.technology_and_data_expertise.data
                            .achievement_data_driven_company;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        technology_and_data_expertise__profit_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.profit_impact *
                    getters.technology_and_data_expertise__total_increase_revenue_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        technology_and_data_expertise__equity_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (getters.technology_and_data_expertise__profit_impact +
                            getters.technology_and_data_expertise__cost_reduction_initiative) *
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        ecological_and_social_sustainability__total_reduction_penalties: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    rootState.meta_data.penalty_total_cost
                    *
                    state.ecological_and_social_sustainability.data.reduction_penalties_digital_initiative;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        ecological_and_social_sustainability__cost_reduction_sick_days: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (
                            rootState.meta_data.fte_total_cost
                            /
                            state.ecological_and_social_sustainability.data.number_official_working_days
                    )
                    *
                    (
                            state.ecological_and_social_sustainability.data.average_sick_days
                            *
                            state.ecological_and_social_sustainability.data.potential_reduction_sick_days_usage_digital_product
                    );
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        ecological_and_social_sustainability__total_cost_reduction_initiative: (
                state,
                getters
        ) => {
            let calculated =
                    getters.ecological_and_social_sustainability__total_reduction_penalties +
                    getters.ecological_and_social_sustainability__cost_reduction_sick_days;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        ecological_and_social_sustainability__equity_impact: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    getters.ecological_and_social_sustainability__total_cost_reduction_initiative *
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        strategic_bets__equity_impact: (state) => {
            let calculated = state.strategic_bets.data.equity_value_strategic_bet;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equity_story__total_sum_profit_impacts_all_sectors: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    (getters.strategic_bets__equity_impact +
                            getters.technology_and_data_expertise__equity_impact +
                            getters.agile_culture_and_organizational_learning__equity_impact +
                            getters.employer_branding__equity_impact +
                            getters.brand_awareness__equity_impact +
                            getters.process_speed_and_quality__equity_impact +
                            getters.equipment_utilization__equity_impact +
                            getters.employee_effectiveness__equity_impact +
                            getters.customer_satisfaction_and_retention__equity_impact +
                            getters.core_product_sales__equity_impact +
                            getters.direct_cost_reduction__equity_impact +
                            getters.direct_revenue__equity_impact) /
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equity_story__improvement_multiple_initiative: (
                state,
                getters,
                rootState
        ) => {
            let calculated =
                    state.equity_story.data.new_equity_impact_industry_multiplier -
                    rootState.meta_data.equity_impact_industry_multiplier;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
        equity_story__equity_impact: (state, getters) => {
            let calculated =
                    getters.equity_story__improvement_multiple_initiative *
                    getters.equity_story__total_sum_profit_impacts_all_sectors;
            if (calculated || calculated === 0) {
                return calculated
            }
            return 0;
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        resetMetaData({ commit }) {
            commit('resetState');
        }
    },
};

export default SectorMetaData;
