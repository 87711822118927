<template>
  <div class="user_edit">
    <div class="user_edit__header">
      <a-button class="user_edit__header-btn"
                type="primary"
                icon="left"
                size="large"
                @click="handleBack"
      />
      <UserListEditSvg class="user_edit__header-svg"/>
      <h1 class="user_edit__header-name">
        {{ this.id ? 'Edit' : 'New' }} User
      </h1>
    </div>
    <div class="user_edit__content">
      <a-form class="user_edit__form"
              :form="form"
              @submit.prevent="handleSubmit"
      >
        <a-form-item :colon="false" :required="false">
          <span class="user_edit__form__label" slot="label">
            User Name
          </span>
          <a-input class="user_edit__form__input"
                   :disabled="this.$store.getters.getUserRole != this.$userRole.EXCUBATE_ADMIN"
                   v-decorator="[
              'user_name',
              {
                rules: [
                  {
                    required:true,
                    message:'Required'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item :colon="false" :required="false">
          <span class="user_edit__form__label" slot="label">
            User Email
          </span>
          <a-input class="user_edit__form__input"
                   :disabled="this.$store.getters.getUserRole != this.$userRole.EXCUBATE_ADMIN"
                   v-decorator="[
              'email',
              {
                rules:[
                  {
                    type:'email',
                    message:'Email address is malformed'
                  },
                  {
                    required:true,
                    message:'Required'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item :colon="false" :required="false"
                     v-show="this.$store.getters.getUserRole == this.$userRole.EXCUBATE_ADMIN"
        >
          <span class="user_edit__form__label"
                slot="label"
          >
            Role
          </span>
          <a-select class="user_edit__form__input"
                    v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ],
                initialValue: '1'
              }
            ]"
          >
            <a-select-option value="1">
              Excubate Admin
            </a-select-option>
            <a-select-option value="2">
              Excubate Employee
            </a-select-option>
            <a-select-option value="3">
              Client CDO/DTO
            </a-select-option>
            <a-select-option value="4">
              Project Digital Owner
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :colon="false" :required="false"
                     v-show="this.$store.getters.getUserRole == this.$userRole.EXCUBATE_ADMIN"
        >
          <span class="user_edit__form__label" slot="label">
            Access
          </span>
          <a-select class="user_edit__form__input"
                    v-decorator="[
              'limited_access',
              {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ],
                initialValue: '1'
              }
            ]"
          >
            <a-select-option value="1">
              Basic
            </a-select-option>
            <a-select-option value="2">
              Pro
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :colon="false" :required="false">
          <span class="user_edit__form__label" slot="label">
            {{ editModal ? 'Reset Password' : 'Determine Password' }}
          </span>
          <a-input-password class="user_edit__form__input"
                            v-decorator="[
              'password'
            ]"
          />
        </a-form-item>
        <a-form-item>
          <a-button class="user_edit__form__submit"
                    type="primary"
                    html-type="submit"
                    :loading="confirmLoading"
          >
            Submit
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import UserListEditSvg from '../../components/AdminPanel/UserListEditSvg'

export default {
  name: 'UserEdit',
  components: {
    UserListEditSvg
  },
  data() {
    return {
      editModal: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this),
      emailCheck: {
        status: "success",
        errorMsg: ""
      },
      confirmLoading: false,
      loading: false,
      editExcubateContact:
          this.$store.getters.getUserRole == this.$userRole.EXCUBATE_ADMIN
    };
  },
  methods: {
    async handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (values.limited_access === '1') {
            values.limited_access = true
          } else {
            values.limited_access = false
          }

          this.confirmLoading = true;
          try {
            if (this.id) {
              await this.$http.put(`/admin/api/edit-user/${this.id}`, values);
              this.$message.success("Update User Success");
              this.handleBack();
            } else {
              await this.$http.post(`/admin/api/regist`, values);
              this.$message.success("Add User Success");
              this.handleBack();
            }
          } catch (error) {

            this.$message.error(error?.response?.data.message);
          }

          this.confirmLoading = false;
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async fetch() {
      if (this.id) {
        this.loading = true;
        let response = await this.$http.get("/admin/api/rest/user/" + this.id);
        if (response.data?.limited_access) {
          response.data.limited_access = '1';
        } else {
          response.data.limited_access = '2';
        }

        this.form.setFieldsValue(response.data);
        this.loading = false;
      }
    }
  },
  async created() {
    this.id = this.$route.params.user_id || "";

    this.editModal = this.id ? true : false;
  },
  async mounted() {
    await this.fetch();
  }
};
</script>

<style lang="less">
.user_edit {
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &-btn {
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;
    }

    &-svg {
      width: 24px;
      height: 30px;
      margin-right: 10px;
    }

    &-name {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #2A2F37;
      width: max-content;
      height: max-content;
      margin: 0;
      margin-right: 30px;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 25px 30px;
  }

  &__form {
    width: 400px;

    &__label {
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
    }

    &__input {
      color: rgba(0, 0, 0, 0.6);
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
    }

    &__submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 40px;
      padding: 0;
    }
  }
}
</style>
