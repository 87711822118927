<template>
  <div class="project__attach">
    <a-table
        class="project__attach__table"
        :columns="columns"
        :dataSource="dataAttach"
        :loading="loading"
    >
      <span slot="portfolioTitle">
        <div
            class="project__attach__portfolio-title"
            @click="sortPortfolio"
        >
          Attached to portfolio
          <a-icon
              class="project__attach__portfolio-title-svg"
              :class="{ 'project__attach__portfolio-title-svg--active': portfolioSortActive }"
              type="arrow-down"
          />
        </div>
      </span>

      <span
          slot="portfolio_name"
          slot-scope="portfolio_name, record"
      >
        <a @click.prevent="toProjectList(record)">
          {{ record.portfolio_name }}
        </a>
      </span>

      <a-tooltip
          placement="bottom"
          slot="detach"
          slot-scope="record"
      >
        <template slot="title">
          Detach
        </template>
        <a-button
            class="project__attach__detach-btn"
            type="primary"
            size="default"
            @click="detachToPortfolio({
            client_id: record.client_id,
            portfolio_id: record._id,
            project_id: $route.params.project_id,
            user_id: record.create_user
          })"
        >
          <project-attach-detach-svg class="project__attach__detach-btn-svg"/>
        </a-button>
      </a-tooltip>
    </a-table>

    <projects-pop-confirm
        title="Detach"
        textBtnOk="DETACH"
        ref="popDetachConfirm"
        @update="fetch"
    />
  </div>
</template>

<script>
import moment from "moment";
import ProjectAttachDetachSvg from '../../components/Project/ProjectAttach/ProjectAttachDetachSvg.vue'
import ProjectsPopConfirm from '../../components/Project/ProjectsPopConfirm.vue'

export default {
  name: 'ProjectAttach',

  components: {
    ProjectAttachDetachSvg,
    ProjectsPopConfirm
  },

  data() {
    return {
      dataAttach: [],
      columns: [
        {
          dataIndex: "portfolio_name",
          slots: {
            title: 'portfolioTitle'
          },
          scopedSlots: {
            customRender: "portfolio_name"
          }
        },
        {
          title: "Attaching Date",
          dataIndex: "attach_date"
        },
        {
          title: "Detach",
          scopedSlots: { customRender: "detach" }
        },
      ],
      loading: true,
      client_id: this.$route.params.client_id,
      portfolioSortActive: false,
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    toProjectList(record) {
      this.$router.push({
        name: "portfolio-project-list",
        params: { portfolio_id: record._id }
      });
    },

    sortPortfolio() {
      this.dataAttach.reverse()
      this.portfolioSortActive = !this.portfolioSortActive
    },

    detachToPortfolio(record) {
      this.$refs.popDetachConfirm.handleKnowMore(record)
    },

    async fetch() {
      try {
        this.loading = true;

        const { data } = await this.$http.get(`/admin/api/rest/project/${this.$route.params.project_id}`)

        this.dataAttach = data.portfolio_ids.map(portfolio => ({
          ...portfolio,
          attach_date: moment(portfolio.attach_date).format("YYYY-MM-DD")
        }))
      } catch (error) {
        console.error(error)
      }

      this.loading = false;
    },
  }
};
</script>

<style lang="less">
.project__attach {
  border-radius: 10px;

  &__table {
    background-color: white;
    border-radius: 10px;

    & .ant-table-column-title {
      background-color: #FAFAFA;
      font-family: Helvetica;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-row {
      font-family: Helvetica;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.232143px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-pagination {
      margin-right: 20px;
    }
  }

  &__portfolio {
    &-title {
      cursor: pointer;

      &-svg {
        margin-left: 10px;

        &--active {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__detach {
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 34px;
      padding: 10px 8px;

      &-svg {
        filter: invert(100%);
      }
    }
  }
}
</style>

<style scoped>
.action-group .ant-btn {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
