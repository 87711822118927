<template>
  <div class="project__canvas">
    <PointsEuroToggle @changeEuroActive="changeEuroActive"/>
    <a-icon class="project__canvas__loading"
            v-if="loading"
            type="loading"
    />
    <div class="project__canvas__echarts"
         v-show="!loading"
    >
      <img class="project__canvas__logo project__canvas__logo--top"
           v-show="!isErrorImg"
           :src="projectInfor.client_id.logo"
           @error="errorImg"
           @load="loadImg"
           alt="logo"
      >
      <ExcubateLogoSvg class="project__canvas__logo"/>
      <EchartsDoughnutChart
          class="project__canvas__echart"
          ref="chart"
          :canvasSegments="canvasSegments"
          @onClickSegment="onClickSegment"
      />
    </div>

    <div
        class="project__canvas__form"
        v-if="fromActive && !loading"
    >
      <ProjectCanvasSegmentFormNew
          ref="form"
          :isEdit="isEdit"
          :confirmLoading="confirmLoading"
          @submit="handleSubmit"
          @closeForm="closeForm"
      />
    </div>

    <MetaDataProject
        v-show="!fromActive && !loading && getEuroActive"
        :project_id="$route.params.project_id"
        :user_id="$route.params.user_id"
        :data="meta_data"
    />
  </div>
</template>

<script>
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart"
import ProjectCanvasSegmentFormNew from "../../components/Project/ProjectCanvas/ProjectCanvasSegmentFormNew.vue"
import ExcubateLogoSvg from '../../components/ExcubateLogoSvg'
import MetaDataProject from '../../components/MetaData/MetaDataProject/MetaDataProject'
import PointsEuroToggle from '../../components/MetaData/PointsEuroToggle'
import { canvasSegments, canvasColors } from "@/staticData/CanvasLabels"
import sector_meta_data from '../../staticData/SectorMetaData.js'
import meta_data from '../../staticData/MetaData.js'
import numeral from 'numeral'
import Color from 'color'
import { mapGetters } from 'vuex'
import { cloneDeep } from "lodash/lang"

export default {
  name: 'ProjectCanvas',
  components: {
    EchartsDoughnutChart,
    ProjectCanvasSegmentFormNew,
    ExcubateLogoSvg,
    MetaDataProject,
    PointsEuroToggle
  },
  data() {
    return {
      isEdit: false,
      project_id: "",
      selectedSegment: {},
      tagsGroup: [],
      canvasSegments,
      meta_data,
      sector_meta_data: this.getDefaultSectorMetadata(),
      confirmLoading: false,
      finishCanvas: false,
      projectInfor: {
        client_id: { client_name: "", logo: "" },
        portfolio_id: { portfolio_name: "" },
        project_name: ""
      },
      formData: {},
      segmentColors: {},
      loading: false,
      fromActive: false,
      echartActive: false,
      points: [
        {
          data_index: 0,
          point: 0
        }
      ],
      isErrorImg: false
    };
  },
  computed: {
    ...mapGetters({
      getEuroActive: 'getEuroActive'
    })
  },
  async created() {
    console.log('created');

    this.project_id =
        this.$route.params.project_id || "5e6896afa8bf5e550fd937a7";

    if (this.$route.name == "dpo-projects-assement-canvas-view") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

    this.initData();
    await this.loadData(true);
  },
  methods: {
    
    getDefaultSectorMetadata() {
      return cloneDeep(sector_meta_data);
    },
    loadImg() {
      this.isErrorImg = false
    },
    errorImg() {
      this.isErrorImg = true
    },
    changeEuroActive() {
      this.loading = true
      this.$refs?.form?.closeForm();
      if (this.getEuroActive) {
        for (const key in this.sector_meta_data) {
          for (const segmentKey in this.canvasSegments) {
            for (const segment of this.canvasSegments[segmentKey]) {
              if (this.sector_meta_data[key].data_index === segment.data_index) {
                if (this.sector_meta_data[key].data.footer.equity_impact.value) {
                  segment.equity_impact = numeral(this.sector_meta_data[key].data.footer.equity_impact.value).format('$ -0.[0]a')
                } else {
                  // Clear sector if equity impact is 0
                  segment.equity_impact = null;
                }
              }
            }
          }
        }
      }
      this.renderSegments(this.canvasSegments)
      this.updateTags();
      this.$refs?.chart.updatedData()
      this.loading = false
    },
    onClickSegment(args) {
      this.loading = true
      this.$refs?.form?.hideForm()
      this.selectedSegment = args.data;
      if (this.getEuroActive) {
        for (const key in this.sector_meta_data) {
          if (this.sector_meta_data[key].data_index === this.selectedSegment.data_index) {
            this.formData.key = key
            this.formData.data_index = this.selectedSegment.data_index
            this.formData.title = this.sector_meta_data[key].title
            this.formData.data = this.sector_meta_data[key].data
            break
          }
        }
      } else {
        this.formData.key = 'default'
        this.formData.data_index = 0
        this.formData.title = this.selectedSegment.name
        this.formData.data = this.sector_meta_data.default.data
        this.formData.data.footer.assigned_points.value = this.$store.state.SectorMetaData.default.data.assigned_points
        this.formData.data.footer.available_points.value = 100 - this.$store.state.SectorMetaData.default.data.assigned_points
        for (const key in this.formData.data.footer) {
          if (this.formData.data.footer[key].isEdit) {
            this.formData.data.footer[key].value = this.selectedSegment[key]
          }
        }
      }
      this.fromActive = true

      this.$nextTick().then(() => this.$refs?.form?.showForm(this.formData))

      this.echartActive = true
      this.loading = false
    },

    closeForm() {
      this.fromActive = false
      this.echartActive = false
      this.$refs?.chart.unHighlightSegment()
    },

    async handleSubmit(formValues) {
      this.confirmLoading = true
      let data = {}
      let Segment = {}
      let series_name = ''
      for (const key in this.canvasSegments) {
        Segment = this.canvasSegments[key].find(item =>
            item.data_index === this.selectedSegment.data_index
        )
        if (Segment) {
          series_name = key
          break;
        }
      }

      if (formValues.point !== undefined) {
        Segment.point = formValues.point
      }
      if (formValues?.explanation) {
        Segment.explanation = formValues.explanation
      }
      if (formValues?.metrics) {
        Segment.metrics = formValues.metrics
      }
      if (this.getEuroActive) {
        Segment.sector_meta_data = formValues
        for (const key in this.sector_meta_data) {
          if (this.selectedSegment.data_index === this.sector_meta_data[key].data_index) {
            for (const bodyItem of this.sector_meta_data[key].data.body) {
              for (const bodyItemKey in bodyItem.data) {
                if (bodyItem.data[bodyItemKey].isEdit) {
                  // Avoid submitting null values to the backend
                  if (Segment.sector_meta_data[bodyItemKey] === 'null') {
                    Segment.sector_meta_data[bodyItemKey] = 0;
                  }

                  bodyItem.data[bodyItemKey].value = Segment.sector_meta_data[bodyItemKey]
                  if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                    this.$store.state.SectorMetaData[key].data[bodyItemKey] = numeral(bodyItem.data[bodyItemKey].value).value() / 100
                  } else {
                    this.$store.state.SectorMetaData[key].data[bodyItemKey] = numeral(bodyItem.data[bodyItemKey].value).value()
                  }
                }
              }
            }
            break
          }
        }
      }

      data = {
        project: this.project_id,
        series_name: series_name,
        data_index: Segment.data_index,
        metrics: Segment.metrics,
        explanation: Segment.explanation,
        point: Segment.point,
        sector_meta_data: Segment.sector_meta_data
      }

      try {

        //update segment data on server
        if (Segment._id) {
          await this.$http.put("/admin/api/rest/canvas/" + Segment._id, data)
        } else {
          let response = await this.$http.post("/admin/api/rest/canvas", data)
          Segment._id = response.data._id
        }
        this.loadData(false)
        

        this.initSegmentColors();
        this.$message.success("Update Canvas Success.")
        
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
      //update segment item style
      this.calculatedSectorMetaData();
      this.updateTags();
      this.initSegmentColors();
      this.changeEuroActive();
      this.confirmLoading = false;
    },
    initData() {
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value.point = 0;
          value.metrics = "";
          value.explanation = "";
          value.equity_impact = "";
          value._id = null;
          value.itemStyle = null;
          value.sector_meta_data = null;
        }
      }
    },
    initSegmentColors() {

      this.segmentColors = {};
      if (this.projectInfor.client_id["color_code"]) {
        const colorTheme = Color(
            this.projectInfor.client_id["color_code"].trim()
        );
        for (let i = 0; i <= 20; i++)
          this.segmentColors[i] = colorTheme
              .lighten(0.8 - (0.8 / 21) * (i + 1))
              .hex();
      } else {
        this.segmentColors = canvasColors;
      }
    },
    async resetData() {
      // Sector metadata is duplicated in 3 places: this.canvasSegments, this.sector_meta_data, Vuex store. Need to reset
      // it and points related data everywhere.
      this.canvasSegments.goodway.forEach(segment => {
        segment.explanation = '';
        segment.metrics = '';
        segment.point = 0;
        this.$set(segment, 'sector_meta_data', {});
      });

      this.canvasSegments.indirect.forEach(segment => {
        segment.explanation = '';
        segment.metrics = '';
        segment.point = 0;
        this.$set(segment, 'sector_meta_data', {});
      });

      this.canvasSegments.direct.forEach(segment => {
        segment.explanation = '';
        segment.metrics = '';
        segment.point = 0;
        this.$set(segment, 'sector_meta_data', {});
      });

      this.sector_meta_data = this.getDefaultSectorMetadata();

      await this.$store.dispatch('SectorMetaData/resetMetaData');
    },
    async loadData(eanbleResest = null) {
      if(eanbleResest) {
      this.loading = true
      }
      try {
        if(eanbleResest) {
          await this.resetData();
        }

        //Get Project Info
        let response = await this.$http.get(`/admin/api/rest/project/${this.project_id}`);
        this.projectInfor = response.data
        if (this.projectInfor?.client_id?.logo && !this.projectInfor?.client_id?.logo.includes('base64')) {
          this.projectInfor.client_id.logo = process.env.VUE_APP_HOST ?
              process.env.VUE_APP_HOST + this.projectInfor.client_id.logo
              : this.projectInfor.client_id.logo
        }

        //Get Meta Data Client
        response = await this.$http.get(`/metadata/clients/${this.projectInfor.client_id._id}`)

        for (const catalog of this.meta_data) {
          for (const item of catalog.data) {
            item.initialValue = parseFloat(response.data[item.key])
            if (!item.initialValue) {
              item.initialValue = 0
            }
            if (item.prefix.includes('%')) {
              this.$store.state.meta_data[item.key] = item.initialValue / 100
            } else {
              this.$store.state.meta_data[item.key] = item.initialValue
            }
          }
        }

        //Get Meta Data Project
        response = await this.$http.get(`/metadata/projects/${this.project_id}`)

        for (const catalog of this.meta_data) {
          for (const item of catalog.data) {
            if (response.data[item.key]) {
              item.initialValue = parseFloat(response.data[item.key])
              if (!item.initialValue) {
                item.initialValue = 0
              }
              if (item.prefix.includes('%')) {
                this.$store.state.meta_data[item.key] = item.initialValue / 100
              } else {
                this.$store.state.meta_data[item.key] = item.initialValue
              }
            }
          }
        }

        this.initSegmentColors()

        //Get Segments Data
        response = await this.$http.get(`/admin/api/rest/canvas/project/${this.project_id}`)

        if (response.data.length) {
          for (const data of response.data) {
            for (const item of this.canvasSegments[data.series_name]) {
              if (item.data_index === data.data_index) {

                if (data?.explanation) {
                  item.explanation = data.explanation
                }
                if (data?.metrics) {
                  item.metrics = data.metrics
                }
                if (data?.point) {
                  item.point = data.point
                }
                if (data?._id) {
                  item._id = data._id
                }

                if (data?.sector_meta_data) {
                  item.sector_meta_data = data.sector_meta_data

                  for (const key in this.sector_meta_data) {
                    if (data.data_index === this.sector_meta_data[key].data_index) {
                      for (const bodyItem of this.sector_meta_data[key].data.body) {
                        for (const bodyItemKey in bodyItem.data) {
                          if (bodyItem.data[bodyItemKey].isEdit) {
                            bodyItem.data[bodyItemKey].value = parseFloat(data.sector_meta_data?.[bodyItemKey])
                            if (bodyItem.data[bodyItemKey].value == null) {
                              bodyItem.data[bodyItemKey].value = 0
                            }
                            if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                              this.$store.state.SectorMetaData[key].data[bodyItemKey] = bodyItem.data[bodyItemKey].value / 100
                            } else {
                              this.$store.state.SectorMetaData[key].data[bodyItemKey] = bodyItem.data[bodyItemKey].value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          this.$store.state.SectorMetaData.default.data.assigned_points = 0
        }

        this.calculatedSectorMetaData();
        this.initSegmentColors();
        this.updateTags();

        this.changeEuroActive();

        this.loading = false
      } catch (error) {

        this.$message.error(error?.response?.data.message)
        this.loading = false
      }
    },
     getDensityIndex(point, numberOfColors, highestValue) {

      const maxPointDifference = highestValue; // Assuming 30 is the max point to scale density
      let difference = Math.abs(point - maxPointDifference); // Difference from 30

      // Scale the difference to a value between 0 and numberOfColors - 1
      // You might need to adjust the scaling formula based on how you want to distribute colors
      let index = numberOfColors - 1 - Math.ceil((difference / maxPointDifference) * (numberOfColors - 1));

      // Ensure the index is within bounds
      index = Math.max(0, Math.min(index, numberOfColors - 1));
      return index;
    },
    
    renderSegments(canvasSegments) {
      let ArrayEquity_impact = []
      let SymmEquity_impact = 0;
      let highestValue = this.findHighestPoint(canvasSegments)

      for (const key in canvasSegments) {
        for (const segment of canvasSegments[key]) {

          if (this.getEuroActive) {
            if (numeral(segment.equity_impact).value() !== null) {
              let value = Math.abs(numeral(segment.equity_impact).value())
              SymmEquity_impact += value
              ArrayEquity_impact.push({ value, segment })
            } else {
              segment.itemStyle = null;
            }
          } else {
            if (segment.point > 0) {
              segment.itemStyle = {
                  color: this.segmentColors[this.getDensityIndex(segment.point, Object.keys(this.segmentColors).length, highestValue)] 
              }
            } else {
              segment.itemStyle = null;
            }
          }
        }
      }

      for (const item of ArrayEquity_impact) {
        const color = this.segmentColors[Math.ceil(item.value / SymmEquity_impact * 20)]
        if (color) {
          item.segment.itemStyle = {
              color: color
          }
        } else {
          item.segment.itemStyle = null
        }
      }
    },
    updateSegmentStyle(segment) {
      
      let itemColor = false;
      if (segment.point > 0) {
        itemColor = this.segmentColors[Math.ceil(segment.point / 5)];
      } else {
        segment.itemStyle = null;
      }
      if (itemColor) segment.itemStyle = {  color: itemColor  };
    },
    updateTags() {
      this.tagsGroup = [
        ...this.canvasSegments["direct"],
        ...this.canvasSegments["indirect"],
        ...this.canvasSegments["goodway"]
      ].filter(segment => segment.point > 0);

      let summ = 0
      for (const item of this.tagsGroup) {
        summ += parseFloat(item.point)
      }

      this.$store.state.SectorMetaData.default.data.assigned_points = summ

      if (summ === 100) this.finishCanvas = true;
      else this.finishCanvas = false;
    },
    async saveProcess() {
      let data = []
      for (const key in this.canvasSegments) {
        for (const item of this.canvasSegments[key]) {
          if (item.point > 0) {
            data.push({
              series_name: key,
              data_index: item.data_index,
              metrics: item.metrics,
              explanation: item.explanation,
              point: item.point,
              sector_meta_data: item.sector_meta_data
            })
          }
        }
      }
      this.$http.put(`/canvas/projects/${this.project_id}`, data)
          .then(() => {
            this.$message.success("Create Version Snapshot Success.");
          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          });
    },

    findHighestPoint(obj) {
      let highestPoint = 0;

      function search(obj) {
        for (let key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            search(obj[key]);
          } else if (key === 'point' && typeof obj[key] === 'number') {
            highestPoint = Math.max(highestPoint, obj[key]);
          }
        }
      }

      search(obj);
      return highestPoint;
    },
    calculatedSectorMetaData() {
      const meta_data = [
        ...this.meta_data[0].data,
        ...this.meta_data[1].data,
        ...this.meta_data[2].data,
        ...this.meta_data[3].data,
      ]
      for (const category in this.sector_meta_data) {
        for (const bodyItem of this.sector_meta_data[category].data.body) {
          for (const bodyItemKey in bodyItem.data) {
            if (bodyItem.data[bodyItemKey].calculated.length === 1) {
              for (const item of meta_data) {
                if (item.key === bodyItemKey) {
                  bodyItem.data[bodyItemKey].value = item.initialValue
                  break;
                } else {
                  const meta_data_key = bodyItem.data[bodyItemKey].calculated[0].split('.')[1]

                  // Logic which sets default values for fields with absent entered values. Need to set in 2 places:
                  // Vuex state (SectorMetaData) and this.sector_meta_data.
                  if (item.key === meta_data_key && (bodyItem.data[bodyItemKey].value == null || bodyItem.data[bodyItemKey].value === '')) {
                    bodyItem.data[bodyItemKey].value = item.initialValue

                    // Such logic is needed because percent is stored as XX on backend, but should be used as 0.XX in frontend calculations
                    if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                      this.$store.state.SectorMetaData[category].data[bodyItemKey] = bodyItem.data[bodyItemKey].value / 100;
                    } else {
                      this.$store.state.SectorMetaData[category].data[bodyItemKey] = bodyItem.data[bodyItemKey].value;
                    }

                    break;
                  }
                }
              }
            } else {
              if (bodyItem.data[bodyItemKey].calculated.length > 1) {

                bodyItem.data[bodyItemKey].value = this.$store.getters[`SectorMetaData/${category}__${bodyItemKey}`]

                if (bodyItem.data[bodyItemKey].prefix === '%') {
                  bodyItem.data[bodyItemKey].value *= 100;
                }
              }
            }
          }
        }
        for (const footerKey in this.sector_meta_data[category].data.footer) {
          if (this.sector_meta_data[category].data.footer[footerKey].calculated.length === 1) {
            for (const item of meta_data) {
              if (item.key === footerKey) {
                this.sector_meta_data[category].data.footer[footerKey].value = item.initialValue
                break;
              }
            }
          } else {
            if (this.sector_meta_data[category].data.footer[footerKey].calculated.length > 1) {
              this.sector_meta_data[category].data.footer[footerKey].value = this.$store.getters[`SectorMetaData/${category}__${footerKey}`]
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="less">
.project__canvas {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__loading {
    width: 100%;

    & svg {
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
  }

  &__logo {
    position: absolute;
    z-index: 0;
    bottom: 15px;
    left: 15px;
    max-width: 180px;
    height: 70px;

    &--top {
      bottom: 0;
      top: 15px;
    }
  }

  &__echarts {
    margin-right: 30px;
    flex-grow: 1;
  }

  &__echart {
    flex-grow: 1;
    width: 600px;
    height: 600px;
  }
}

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
