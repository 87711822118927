<template>
  <div>
    <a-table :columns="columns" :dataSource="data" :loading="loading" :pagination="{pageSize: 5}">
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
              v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>{{ text }}</template>
      </template>

      <template slot="screenshotRender" slot-scope="record">
        <a v-if="record.screenshot" target="_blank" :href="record.screenshot">View Screenshot</a>
        <span v-else>NO screenshot</span>
      </template>
      <template slot="descriptionRecord" slot-scope="description">
        <div v-html="description"></div>
      </template>
    </a-table>
  </div>
</template>
<style scoped>
.action-group .ant-btn {
  margin-right: 10px;
  margin-bottom: 5px;
}

.logo-bg-position {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90px;
  height: 35px;
}
</style>
<script>
import moment from "moment";

const data = [];

export default {
  data() {
    return {
      data,
      columns: [
        {
          title: "Date added",
          dataIndex: "create_date"
        },
        {
          title: "Subject",
          dataIndex: "subject",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.subject
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Bug Description",
          dataIndex: "description",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "descriptionRecord"
          },
          onFilter: (value, record) =>
              record.description
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Attached Files",
          scopedSlots: {
            customRender: "screenshotRender"
          }
        },
        {
          title: "Submitted by",
          dataIndex: "user_name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.user_name
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: "Email Address",
          dataIndex: "user_email",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender"
          },
          onFilter: (value, record) =>
              record.user_email
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        }
      ],
      loading: true,
      editRecordRight: [this.$userRole.EXCUBATE_ADMIN].includes(
          this.$store.getters.getUserRole
      )
    };
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    async fetch() {
      this.loading = true;
      const res = await this.$http.get("/admin/api/rest/submitbug");
      res.data = res.data.map(value => {
        value.key = value._id;

        value.create_date = moment(value.create_date).format("YYYY-MM-DD");

        if (value.screenshot && value.screenshot.indexOf(";base64,") === -1) {
          value.screenshot = process.env.VUE_APP_HOST
              ? process.env.VUE_APP_HOST + value.screenshot
              : value.screenshot;
        }

        if (value.description)
          value.description = value.description.replace(/\n/g, "<br />");
        if (value.create_user) {
          value.user_name = value.create_user["user_name"];
          value.user_email = value.create_user["email"];
        }

        return value;
      });
      this.data = res.data;
      this.loading = false;
    }
  },
  created() {
    this.fetch();
  }
};
</script>
