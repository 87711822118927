<template>
  <a-form-item class="project__canvas__segment__form__item"
               :class="{ 'project__canvas__segment__form__item--colum': data.type === 'textarea',
    'project__canvas__segment__form__item--equity-impact': name === 'equity_impact' }"
               v-if="visible"
               :colon="false"
  >
    <div class="project__canvas__segment__form__item__label"
         slot="label"
    >
    <span class="project__canvas__segment__form__item__label-text"
          v-for="word in label"
          :key="word.id"
    >
      {{ word }}
    </span>
      <a-tooltip overlayClassName="meta-data__item__label-tooltip"
                 v-if="data.labelInfo"
                 placement="topLeft"
                 trigger="hover | focus"
      >
      <span slot="title">
        {{ data.labelInfo }}
      </span>
        <MetaDataItemInfoSvg class="meta-data__item__label-info"/>
      </a-tooltip>
    </div>
    <template v-if="isEdit">
      <ProjectCanvasSegmentFormInputNumberMask
          v-if="data.type === 'days' && data.isEdit"
          :maxPoint="365"
          :minPoint="1"
          v-decorator="decorator"
      />
      <ProjectCanvasSegmentFormInputNumberMask
          v-if="data.type === 'number' && data.isEdit"
          :maxPoint="maxPoint"
          :minPoint="0"
          v-decorator="decorator"
      />
      <ProjectCanvasSegmentFormInputMask class="project__canvas__segment__form__item__input"
                                         v-if="data.type === '' && data.isEdit"
                                         :prefix="data.prefix"
                                         v-decorator="decorator"
      />
      <a-input class="project__canvas__segment__form__item__input"
               v-if="data.type === 'textarea' && data.isEdit"
               :type="data.type"
               :autosize="{ minRows: 3}"
               v-decorator="decorator"
      />
    </template>
    <NotSet v-if="isEdit && !data.isEdit && !data.value && data.value !== 0"/>
    <p class="project__canvas__segment__form__item__text"
       :class="{ 'project__canvas__segment__form__item__text--meta-data': isMetaData}"
       v-if="isEdit && !data.isEdit && (data.value || data.value === 0)"
    >
      {{ data.prefix + formatValue }}
    </p>
  </a-form-item>
</template>

<script>
import NotSet from '../../MetaData/NotSet'
import numeral from 'numeral'
import ProjectCanvasSegmentFormInputMask from './ProjectCanvasSegmentFormInputMask'
import ProjectCanvasSegmentFormInputNumberMask from './ProjectCanvasSegmentFormInputNumberMask'

export default {
  name: "ProjectCanvasSegmentFormItem",
  components: {
    NotSet,
    ProjectCanvasSegmentFormInputMask,
    ProjectCanvasSegmentFormInputNumberMask
  },
  props: {
    data: Object,
    name: String,
    isEdit: Boolean
  },
  data() {
    return {
      visible: true,
      maxPoint: 100,
      label: [],
      isMetaData: false
    }
  },
  created() {
    this.maxPoint = 100 -
        this.$store.state.SectorMetaData.default.data.assigned_points +
        this.data.value
    this.label = this.data.label.split(' ')
    if (this.data.calculated.length === 1) {
      this.isMetaData = true
    }
    if (this.data.type === "textarea") {
      if (this.$route.params.project_id && this.name === 'comments') {
        this.visible = false
      } else {
        if (!this.$route.params.project_id && this.name !== 'comments') {
          this.visible = false
        }
      }
    }
  },
  computed: {
    formatValue() {
      if (this.data.type === "textarea") {
        return this.data.value
      }

      if (this.data.label === 'Average annual salary of affected division') {
        return numeral(this.data.value).format('0')
      }

      if (!this.data.prefix.includes('€')) {
        return numeral(this.data.value).format('0,0.[00]')
      }
      return numeral(this.data.value).format('0,0.00')
    },
    decorator() {
      let rules = [
        {
          pattern: /(^\d+)|(^\d+\.*\d+,*\d+$)/,
          message: 'Incorrect value'
        }
      ]

      if (this.data.prefix.includes('%')) {
        rules = [
          {
            pattern: /(^\d?\d$)|(^\d?\d,\d\d?$)|(^100$)/,
            message: 'Incorrect value'
          }
        ]
      }

      if (this.data.type === "number") {
        rules.push([
          {
            required: true,
            message: 'Required'
          }
        ])
      } else {
        if (this.data.type === "textarea") {
          rules = [
            {
              whitespace: true,
              required: true,
              message: 'Incorrect value'
            }
          ]
        }
      }
      const dec = [
        this.name,
        {
          initialValue: this.formatValue,
          rules: rules
        }
      ]
      return dec
    }
  },
  methods: {}
}
</script>

<style lang="less">
.project__canvas__segment__form__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  & .ant-form-item-label {
    width: 65%;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & label {
      width: 100%;
      display: inline-table;

      &::after, &::before {
        display: none;
      }
    }
  }

  & .ant-form-item-control-wrapper {
    width: 35%;
  }

  &--colum {
    flex-direction: column;
    align-self: flex-start;

    & .ant-form-item-label {
      width: 100%;
      margin-right: 0;
    }

    & .ant-form-item-control-wrapper {
      width: 100%;
    }
  }

  &--equity-impact {
    & .project__canvas__segment__form__item__label {
      font-size: 20px;
      letter-spacing: 0.19px;
      line-height: 28px;
    }

    & .project__canvas__segment__form__item__text {
      font-size: 20px;
      letter-spacing: 0.19px;
      line-height: 28px;
    }
  }

  &__label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    &-text {
      width: max-content;
      text-align: left;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #2A2F37;
      flex-shrink: 0;
      white-space: pre-wrap;
    }
  }

  &__input, &__input input {
    width: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: #000000
  }

  &__prefix {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1)
  }

  &__text {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
    margin: 0;

    &--meta-data {
      color: #05825A;
    }
  }
}
</style>
