<template>
  <div class="portfolio__analysis__delta">
    <PointsEuroToggle
        @changeEuroActive="changeEuroActive"
    />
    <a-icon class="portfolio__analysis__delta__loading"
            v-if="loading"
            type="loading"
    />
    <div class="portfolio__analysis__delta-item"
         v-show="!loading"
    >
      <h2 class="portfolio__analysis__delta-item-title">
        Target State Heatmap
      </h2>
      <EchartsDoughnutChart class="portfolio__analysis__delta-item-echarts"
                            ref="target-chart"
                            :canvasSegments="target_canvasSegments"
      />
      <p class="portfolio__analysis__delta-item-text">
        Date created: {{ portfolioInfor.create_date }}
      </p>
    </div>
    <div class="portfolio__analysis__delta-item"
         v-show="!loading"
    >
      <h2 class="portfolio__analysis__delta-item-title">
        Status Quo Portfolio Performance
      </h2>
      <EchartsDoughnutChart class="portfolio__analysis__delta-item-echarts"
                            ref="heatmap-chart"
                            :canvasSegments="heatmap_canvasSegments"
      />
      <p class="portfolio__analysis__delta-item-text">
        Date created: {{ portfolioInfor.start_date }}
      </p>
    </div>
    <div class="portfolio__analysis__delta-item"
         v-show="!loading"
    >
      <h2 class="portfolio__analysis__delta-item-title">
        Delta between Portfolio and Target State
      </h2>
      <EchartsDoughnutChart class="portfolio__analysis__delta-item-echarts"
                            ref="delta-chart"
                            :canvasSegments="delta_canvasSegments"
                            isDelta="true"
      />
    </div>
  </div>
</template>

<script>
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart";
import PointsEuroToggle from '../../components/MetaData/PointsEuroToggle'
import { canvasSegments, canvasColors } from "../../staticData/CanvasLabels";
import Color from "color";
import { cloneDeep } from "lodash/lang";
import { uniq } from "lodash/array";
import moment from "moment";
import sector_meta_data from '../../staticData/SectorMetaData.js';
import meta_data from '../../staticData/MetaData.js';
import { mapGetters } from 'vuex';
import numeral from 'numeral'

export default {
  name: 'PortfolioAnalysisDelta',
  components: {
    EchartsDoughnutChart,
    PointsEuroToggle
  },
  data() {
    return {
      portfolio_id: "",
      selectedSegment: {},
      canvasSegments,
      target_canvasSegments: {},
      heatmap_canvasSegments: {},
      delta_canvasSegments: {},
      portfolioInfor: {},
      segmentColors: {},
      loading: false,
      sector_meta_data,
      meta_data
    };
  },
  computed: {
    ...mapGetters({
      getEuroActive: 'getEuroActive'
    })
  },
  async created() {
    this.portfolio_id =
        this.$route.params.portfolio_id || "5e6896afa8bf5e550fd937a7";

    this.initData();
    await this.loadData();
  },
  methods: {
    changeEuroActive() {
      this.loading = true

      this.renderTargetSegments(this.target_canvasSegments);
      this.renderTargetSegments(this.heatmap_canvasSegments);
      this.renderDeltaSegments(this.delta_canvasSegments);

      this.$refs?.['delta-chart'].updatedData()
      this.$refs?.['heatmap-chart'].updatedData()
      this.$refs?.['target-chart'].updatedData()

      this.loading = false
    },
    initData() {
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value.point = 0;
          value.metrics = "";
          value.explanation = "";
          value._id = null;
          value.itemStyle = null;
          value.sector_meta_data = null;
          value.equity_impact = '';
        }
      }

      this.heatmap_canvasSegments = cloneDeep(this.canvasSegments);
      this.target_canvasSegments = cloneDeep(this.canvasSegments);
      this.delta_canvasSegments = cloneDeep(this.canvasSegments);
    },

    initSegmentColors() {
      this.segmentColors = {};
      if (this.portfolioInfor.client_id["color_code"]) {
        const colorTheme = Color(
            this.portfolioInfor.client_id["color_code"].trim()
        );
        for (let i = 0; i <= 20; i++)
          this.segmentColors[i] = colorTheme
              .lighten(0.8 - (0.8 / 21) * (i + 1))
              .hex();
      } else {
        this.segmentColors = canvasColors;
      }
    },

    async loadData() {
      this.loading = true
      try {
        let response = await this.$http.get("/admin/api/rest/portfolio/" + this.portfolio_id);
        this.portfolioInfor = response.data;
        if (this.portfolioInfor.create_date) {
          this.portfolioInfor.create_date = moment(
              this.portfolioInfor.create_date
          ).format("YYYY-MM-DD");
        }
        if (this.portfolioInfor.start_date) {
          this.portfolioInfor.start_date = moment(
              this.portfolioInfor.start_date
          ).format("YYYY-MM-DD");
        }

        this.initSegmentColors();

        response = await this.$http.get("/admin/api/rest/portfoliocanvas/portfolio-canvas/" + this.portfolio_id);
        const segmentsData = response.data;

        if (segmentsData) {
          segmentsData.map(data => {
            const replaceData = this.target_canvasSegments[
                data.series_name
                ].find(value => value.data_index === data.data_index);

            replaceData.point = data.point;
            replaceData.comments = data.comments;
            replaceData._id = data._id;
            replaceData.equity_impact = data.point;
          });

          this.renderTargetSegments(this.target_canvasSegments);

          //Get Meta Data Client
          response = await this.$http.get(`/metadata/clients/${this.portfolioInfor.client_id._id}`)

          for (const catalog of this.meta_data) {
            for (const item of catalog.data) {
              let value = parseFloat(response.data[item.key])
              if (!value) {
                value = 0
              }
              if (item.prefix.includes('%')) {
                this.$store.state.meta_data[item.key] = value / 100
              } else {
                this.$store.state.meta_data[item.key] = value
              }
            }
          }

          //Get Heatmap
          response = await this.$http.get("/admin/api/rest/canvas/heatmap/" + this.portfolio_id);

          this.portfolioInfor.projects = []
          for (const project of response.data.projects) {
            const canvas = cloneDeep(this.canvasSegments)
            for (const dvcItem of project.dvc) {
              for (const segment of canvas[dvcItem.series_name]) {
                if (segment.data_index === dvcItem.data_index) {
                  segment.point = dvcItem?.point | segment.point
                  segment._id = dvcItem?._id | segment._id
                  segment.sector_meta_data = dvcItem?.sector_meta_data
                  break;
                }
              }
            }
            this.calculatedSectorMetaData(canvas)
            this.portfolioInfor.projects.push({
              // Project impact is optional field. Treat it as 4 (high) if it is absent
              project_impact: project.project_impact ? parseFloat(project.project_impact) : 4,
              canvas
            })
          }

          this.initHeatmapCanvasData();
          this.initDeltaCanvasData();
        }
      } catch (error) {

        this.$message.error(error?.response?.data.message);
      }
      this.loading = false
    },
    initHeatmapCanvasData() {
      let totalPoint = 0;
      let totalEquityImpact = 0;
      for (const key in this.heatmap_canvasSegments) {
        for (let i = 0; i < this.heatmap_canvasSegments[key].length; i++) {
          let point = 0;
          let equity_impact = 0;
          for (const project of this.portfolioInfor.projects) {
            point += project.canvas[key][i].point * project.project_impact
            if(this.portfolioInfor.projects > 1) {
              equity_impact +=  (parseFloat(project.canvas[key][i].equity_impact) * (project.project_impact * 0.25));
            } else {
              equity_impact += project.canvas[key][i].equity_impact > 0 ?  (parseFloat(project.canvas[key][i].equity_impact) * (project.project_impact * 0.25)) : 0;
            }
          }
          totalPoint += point
          this.heatmap_canvasSegments[key][i].point = point
          if (equity_impact) {
            totalEquityImpact += Math.abs(equity_impact);
            this.heatmap_canvasSegments[key][i].equity_impact = equity_impact
          } else {
            this.heatmap_canvasSegments[key][i].equity_impact = ''
          }
        }
      }

      // Calculate weighted proportions (0 - 100) for points and Euro. Note that Status Quo Portfolio Performance should
      // show Euro values for Euro, and weighted points for point. Delta canvas will always take into account weighted
      // values (from weighted Euro or weighted Points depending on Euro/points selector).
      for (const key in this.heatmap_canvasSegments) {
        for (const segment of this.heatmap_canvasSegments[key]) {
          if (segment.equity_impact) {
            // Used only for delta canvas and coloring status quo in Euro mode
            segment.weighted_equity_impact = parseInt(
                parseFloat(
                    (segment.equity_impact / totalEquityImpact).toFixed(2)
                ) * 100
            );
            // Equity impact is no longer needed for further calculation. Format it for better readability in the canvas.
            segment.equity_impact = numeral(segment.equity_impact).format('$ -0.[0]a');
          } else {
            segment.weighted_equity_impact = 0
          }
          if (totalPoint > 0) {
              segment.point = parseInt(
                  parseFloat(
                      (segment.point / totalPoint).toFixed(2)
                  ) * 100
              );
          }
        }
      }

      this.renderTargetSegments(this.heatmap_canvasSegments);
    },
    initDeltaCanvasData() {
      for (const key in this.delta_canvasSegments) {
        for (let i = 0; i < this.delta_canvasSegments[key].length; i++) {
          this.delta_canvasSegments[key][i].point =
              this.heatmap_canvasSegments[key][i].point -
              this.target_canvasSegments[key][i].point;
          // Difference between weighted equity impact and target equity impact
          const equity_impact = parseFloat(
              (this.heatmap_canvasSegments[key][i].weighted_equity_impact -
                  this.target_canvasSegments[key][i].equity_impact).toFixed(2)
          );
          if (equity_impact) {
            this.delta_canvasSegments[key][i].equity_impact = equity_impact
          } else {
            this.delta_canvasSegments[key][i].equity_impact = ''
          }
          this.delta_canvasSegments[key][i].itemStyle = null;
        }
      }

      this.renderDeltaSegments(this.delta_canvasSegments);
    },
    renderDeltaSegments(canvasSegments) {
      const negativeColorTheme = [];
      const colorTheme = Color("#ff0000");
      for (let i = 0; i <= 20; i++) {
        negativeColorTheme[i] = colorTheme
            .lighten(0.8 - (0.8 / 21) * (i + 1))
            .hex();
      }
      const postiveColorThemes = [];
      const  postiveColorTheme =  Color("#75c845");
      for (let i = 0; i <=20; i++) {
        postiveColorThemes[i] = postiveColorTheme
            .lighten(0.8 - (0.8 / 21) * (i + 1))
            .hex();
      }

      for (const key in canvasSegments) {
        for (const segment of canvasSegments[key]) {
          if (this.getEuroActive) {
            if (segment.equity_impact > 0) {
              segment.itemStyle = {
                  color: this.segmentColors[Math.ceil(segment.equity_impact / 5)]
              }
            } else {
              if (segment.equity_impact < 0) {
                segment.itemStyle = {
                    color: negativeColorTheme[Math.abs(Math.ceil(segment.equity_impact / 5))]
                }
              } else {
                segment.itemStyle = null;
              }
            }
          } else {
            if (segment.point > 0) {
                segment.itemStyle = {
                  color: postiveColorThemes[Math.ceil(segment.point / 5)]
              }
              
            } else {
              if (segment.point < 0) {
                segment.itemStyle = {
                    color: negativeColorTheme[Math.abs(Math.ceil(segment.point / 5))]
                }
              } else {
                segment.itemStyle = null;
              }
            }
          }
        }
      }
    },
    renderSegments(canvasSegments) {
      for (const key in canvasSegments) {
        for (const segment of canvasSegments[key]) {
          if (this.getEuroActive) {
            if (segment.weighted_equity_impact) {
              segment.itemStyle = {
                  color: this.segmentColors[Math.abs(Math.ceil(segment.weighted_equity_impact / 5))]
              }
            } else {
              segment.itemStyle = null;
            }
          } else {
            if (segment.point > 0) {
              segment.itemStyle = {
                  color: this.segmentColors[Math.ceil(segment.point / 5)]
              }
            } else {
              segment.itemStyle = null;
            }
          }
        }
      }
    },
    renderTargetSegments(canvasSegments) {
      let segmentPoints = [
        ...canvasSegments["direct"],
        ...canvasSegments["indirect"],
        ...canvasSegments["goodway"]
      ]
          .filter(segment => segment.point > 0)
          .map(x => x.point);
      segmentPoints = uniq(segmentPoints);
      segmentPoints.sort((a, b) => a - b);


      [
        ...canvasSegments["direct"],
        ...canvasSegments["indirect"],
        ...canvasSegments["goodway"]
      ].map(segment => {
        let itemColor = false;
        if (segment.point > 0) {
          let pointIndex = segmentPoints.indexOf(segment.point);
          if (pointIndex > 0 && pointIndex < segmentPoints.length - 1) {
            pointIndex =
                pointIndex * Math.round(20 / (segmentPoints.length - 1));
            pointIndex = pointIndex > 19 ? 19 : pointIndex;
          } else if (pointIndex == 0 && segmentPoints.length > 1)
            pointIndex = 1;
          else if (pointIndex == segmentPoints.length - 1) pointIndex = 20;
          itemColor = this.segmentColors[pointIndex];

        }

        if (itemColor) segment.itemStyle = {  color: itemColor };
        else segment.itemStyle = null;
      });
    },
    calculatedSectorMetaData(canvasSegments) {
      for (const key in canvasSegments) {
        for (const Segment of canvasSegments[key]) {
          for (const sectorKey in this.sector_meta_data) {
            if (Segment.data_index === this.sector_meta_data[sectorKey].data_index) {
              for (const bodyItem of this.sector_meta_data[sectorKey].data.body) {
                for (const bodyItemKey in bodyItem.data) {
                  if (bodyItem.data[bodyItemKey].isEdit) {
                    let value = parseFloat(Segment.sector_meta_data?.[bodyItemKey])
                    if (!value) {
                      value = 0
                    }
                    if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                      this.$store.state.SectorMetaData[sectorKey].data[bodyItemKey] = value / 100
                    } else {
                      this.$store.state.SectorMetaData[sectorKey].data[bodyItemKey] = value
                    }
                  }
                }
              }

              break;
            }
          }
        }
      }

      for (const key in canvasSegments) {
        for (const Segment of canvasSegments[key]) {
          for (const sectorKey in this.sector_meta_data) {
            if (Segment.data_index === this.sector_meta_data[sectorKey].data_index) {
              if (this.$store.getters[`SectorMetaData/${sectorKey}__equity_impact`] !== 0) {
                Segment.equity_impact = this.$store.getters[`SectorMetaData/${sectorKey}__equity_impact`]
              }
            }
          }
        }
      }
    },
  }
};
</script>

<style lang="less">
.portfolio__analysis__delta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: white;
  position: relative;

  &__loading {
    width: 100%;

    & svg {
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;

    &-echarts {
      width: 600px;
      flex-grow: 1;
      height: 600px;
    }

    &-title {
      width: 100%;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.342857px;
      color: rgb(26, 26, 26);
      margin: 0;
    }

    &-text {
      width: 100%;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.342857px;
      color: rgb(96, 96, 96);
      margin: 0;
    }
  }
}

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
