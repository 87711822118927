<template>
  <div class="user-list">
    <div class="user-list__header">
      <div class="user-list__header__title">
        <a-button class="user-list__header__title-btn"
                  type="primary"
                  icon="left"
                  size="large"
                  @click="handleBack"
        />
        <h1 class="user-list__header__title-name">
          User Management
        </h1>
      </div>
      <div class="user-list__header__btns">
        <a-button class="user-list__header__btn"
                  v-if="[this.$userRole.EXCUBATE_ADMIN].includes(this.$store.getters.getUserRole)"
                  type="primary"
                  size="large"
                  @click="handleAdd"
        >
          <a-icon class="user-list__header__btn-svg"
                  type="plus"
          />
          Add User
        </a-button>
      </div>
    </div>
    <a-table class="user-list__table"
             :columns="columns"
             :dataSource="data"
             :loading="loading"
             @change="handleChange"
    >
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#30A679' : undefined }"
      />
      <template slot="customUserName" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template v-for="(fragment, i) in
            text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
            >
              <router-link :to="{
                name: 'user-activity',
                params:{
                  user_id: record._id,
                }
              }">
                {{ fragment }}
              </router-link>
            </mark>
            <template v-else>
              <router-link
                  :key="i"
                  :to="{
                  name: 'user-activity',
                  params:{
                    user_id: record._id,
                  }
                }"
              >
                {{ fragment }}
              </router-link>
            </template>
          </template>
        </span>
        <template v-else>
          <router-link :to="{
              name: 'user-activity',
              params:{
                user_id: record._id
              }
            }"
          >
            {{ text }}
          </router-link>
        </template>
      </template>

      <template slot="customUserEmail" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
              v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <span slot="clientName" slot-scope="clients, record">
        <ul class="items-list">
          <li v-for="client in record.clients" :key="client._id">
            <router-link :to="{name: 'client-portfolio-list', params:{client_id: client._id}}"
            >
            {{ client.client_name }}
            </router-link>
          </li>
        </ul>
      </span>
      <span slot="action" slot-scope="record">
        <div class="user-list__table__action">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Edit User</span>
            </template>
            <a-button class="user-list__table__action-item"
                      type="primary"
                      size="default"
                      @click.prevent="editRecord(record._id)"
            >
              <UserListEditSvg class="user-list__table__action-item-svg"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Delete User</span>
            </template>
            <a-button class="user-list__table__action-item"
                      type="primary"
                      size="default"
                      @click.prevent="deleteRecord(record)"
            >
              <UserListDeleteSvg class="user-list__table__action-item-svg"/>
            </a-button>
          </a-tooltip>
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
import moment from "moment";
import UserListEditSvg from '../../components/AdminPanel/UserListEditSvg'
import UserListDeleteSvg from '../../components/AdminPanel/UserListDeleteSvg'

const data = [];

export default {
  name: 'UserList',
  components: {
    UserListEditSvg,
    UserListDeleteSvg
  },
  data() {
    return {
      data,
      filteredInfo: null,
      userRole: {
        1: "Excubate Admin",
        2: "Excubate Employee",
        3: "DTO/CTO",
        4: "DPO"
      },
      access: {
        0: 'Basic',
        1: 'Pro'
      },
      loading: true
    };
  },
  computed: {
    columns() {
      let { filteredInfo } = this;
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: "User Name",
          dataIndex: "user_name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customUserName"
          },
          onFilter: (value, record) =>
              record.user_name
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "User Email",
          dataIndex: "email",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customUserEmail"
          },
          onFilter: (value, record) => {
            return record.email
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "Role",
          dataIndex: "role",
          filters: [
            { text: "Excubate Admin", value: "Excubate Admin" },
            { text: "Excubate Employee", value: "Excubate Employee" },
            { text: "DTO/CTO", value: "DTO/CTO" },
            { text: "DPO", value: "DPO" }
          ],
          filteredValue: filteredInfo.role || null,
          onFilter: (value, record) => record.role.includes(value)
        },
        {
          title: "Access",
          dataIndex: "limited_access",
        },
        {
          title: "Date Added",
          dataIndex: "create_date"
        },
        {
          title: "Client",
          dataIndex: "clients",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName"
          },
          onFilter: (value, record) => {
            return record.clients
                .map(aa => aa.client_name)
                .join(" ")
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "Action",
          scopedSlots: { customRender: "action" }
        }
      ];
      return columns;
    }
  },
  methods: {
    handleChange(pagination, filters) {
      this.filteredInfo = filters;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    async fetch() {
      this.loading = true;
      const res = await this.$http.get("/admin/api/user-list");
      res.data = res.data.map(value => {
        value.key = value._id;
        value.role = this.userRole[value.role];
        if (value?.limited_access) {
          value.limited_access = this.access[0];
        } else {
          value.limited_access = this.access[1]
        }
        if (value.create_date)
          value.create_date = moment(value.create_date).format("YYYY-MM-DD");
        return value;
      });
      this.data = res.data;

      this.loading = false;
    },
    handleAdd() {
      this.$router.push({ name: "add-user" });
    },
    editRecord(id) {
      this.$router.push({ name: "edit-user", params: { user_id: id } });
    },
    deleteRecord(record) {
      this.$confirm({
        title: "Do you want to delete this user " + record.user_name + "?",
        content:
            "",
        onOk: () => {
          return new Promise(resolve => {
            this.loading = true;
            this.$http
                .delete("/admin/api/rest/user/" + record._id)
                .then(res => {
                  if (res.status) {
                    this.data = this.data.filter(value => value.key != record._id);
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.loading = false;
                })
                .catch(error => {
                  this.$message.error(error.response.data.message);
                  this.loading = false;
                });

            resolve();
          }).catch(() => {
            this.loading = false;

          });
        },
        onCancel() {
        }
      });
    },
    portfolioList(id) {
      this.$router.push({
        name: "client-portfolio-list",
        params: { client_id: id }
      });
    }
  },
  created() {
    this.fetch();
  }
};
</script>

<style lang="less">
.user-list {
  background-color: #F7F9FC;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
        margin-right: 30px;
      }
    }

    &__btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__svg {
      margin-right: 10px;
    }

    &__btn {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;

      &-svg {
        margin-right: 10px;
      }
    }
  }

  &__table {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background: white;
    border-radius: 10px;

    & .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      min-height: 40px;
      height: auto;
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & .ant-table-column-title {
      background-color: #FAFAFA;
      font-family: Helvetica;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & .ant-table-row {
      font-family: Helvetica;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.232143px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-pagination {
      margin-right: 20px;
    }

    & .items-list {
      margin-bottom: 0;
    }

    &__action {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 32px;
        padding: 0 !important;
        margin-right: 10px !important;
        margin-bottom: 5px !important;

        &-svg {
          fill: #FFFFFF;
        }
      }
    }
  }
}
</style>
