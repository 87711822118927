<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-button type="primary" shape="primary" icon="left" size="large" @click="handleBack"></a-button>
        <h1 style="text-align: center; width: 100%;">{{ project_id ? 'Edit' : 'New' }} Project</h1>
        <a-spin :spinning="loading">
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item label="Project Name" :label-col="{ span: 10 }" :wrapper-col="{ span:14 }">
              <a-input
                  v-decorator="['project_name', {rules:[{required:true,message:'Required'}]}]"
              />
            </a-form-item>
            <a-form-item
                label="Digital Project Owner"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
            >
              <a-input
                  v-decorator="['owner_name', {rules:[{required:true,message:'Required'}]}]"
                  :disabled="!this.editOwner"
              />
            </a-form-item>
            <a-form-item label="Email" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
              <a-input
                  v-decorator="['owner_email', {rules:[{type:'email',message:'Email address is malformed'},{required:true,message:'Required'}]}]"
                  :disabled="!this.editOwner"
              />
            </a-form-item>
            <a-form-item
                label="Estimate Project Impact"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
            >
              <a-select name="dd" v-decorator="['project_impact', ]">
                <a-select-option value="1">1 = Low Impact</a-select-option>
                <a-select-option value="2">2</a-select-option>
                <a-select-option value="3">3</a-select-option>
                <a-select-option value="4">4 = High Impact</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
              <a-button type="primary" :loading="confirmLoading" html-type="submit">Submit</a-button>
              <a-button
                  v-if="project_id&&[$userRole.EXCUBATE_ADMIN].includes($store.getters.getUserRole)"
                  type="danger"
                  :loading="confirmLoading"
                  @click.prevent="deleteProject"
                  :style="{'margin-left': '20px'}"
              >Delete Project
              </a-button>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this),
      confirmLoading: false,
      loading: false,
      portfolio_id: '',
      project_id: '',
      editOwner: false
    };
  },
  methods: {
    deleteProject(e) {
      e.preventDefault();

      this.$confirm({
        title: "Do you want to delete this project?",
        content: "",
        onOk: () => {
          return new Promise(resolve => {
            if (this.project_id) {
              this.confirmLoading = true;
              this.$http.post(`/projects/${this.project_id}/portfolios/${this.portfolio_id}/detach`)
                  .then(() => {
                    this.$message.success("Delete Project Success.");
                    this.handleBack();
                    this.confirmLoading = false;
                  })
                  .catch(error => {
                    this.$message.error(error.response.data.message);
                    this.confirmLoading = false;
                  });
            }

            resolve();
          }).catch(() => {
            this.loading = false;

          });
        },
        onCancel() {
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const emailPattern = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
          let emailValidation = true;
          this.confirmLoading = true;

          values["portfolio_id"] = this.portfolio_id;
          values["owner_email"] = values["owner_email"].split("; ");
          values["owner_name"] = values["owner_name"].split("; ");
          if (values["owner_email"].length != values["owner_name"].length) {
            this.$message.error("Digital Project Owner not match with Email");
            this.confirmLoading = false;
            return;
          } else {
            emailValidation = values["owner_email"].every(element => {
              return emailPattern.test(element);
            });
            if (!emailValidation) {
              this.$message.error("Email is malformed");
              this.confirmLoading = false;
              return;
            }
          }
          values["owner_user"] = [];
          values["owner_email"].forEach((element, index) => {
            values["owner_user"].push({
              email: element,
              name: values["owner_name"][index]
            });
          });

          let response = null;
          if (this.project_id) {
            response = await this.$http.put(`/admin/api/rest/project/${this.project_id}`, values);
          } else {
            response = await this.$http.post(`/admin/api/rest/project`, values);
          }
          this.confirmLoading = false;
          if (response) {
            this.$message.success('Success Update Project.')
            this.$router.go(-1);
          }
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },

    //loading data and init form value
    async fetch() {
      this.loading = true;

      let response = await this.$http.get("/admin/api/rest/project/" + this.project_id);

      if (!Array.isArray(response.data.project_user)) {
        response.data.owner_email = response.data.project_user.email;
        response.data.owner_name = response.data.project_user.user_name;
      } else {
        response.data.owner_email = response.data.project_user
            .map(value => value.email)
            .join("; ");
        response.data.owner_name = response.data.project_user
            .map(value => value.user_name)
            .join("; ");
      }

      this.form.setFieldsValue(response.data);

      this.loading = false;
    }
  },
  async created() {
    this.project_id = this.$route.params.project_id;
    this.portfolio_id = this.$route.params.portfolio_id;
    if ([this.$userRole.EXCUBATE_ADMIN, this.$userRole.CLIENT_DTO].includes(this.$store.getters.getUserRole) ||
        (!this.project_id && this.$store.getters.getUserRole === this.$userRole.EXCUBATE_EMPLOYEE)) {
      this.editOwner = true;
    }
    if (this.project_id) {
      await this.fetch();
    }
  },
  async mounted() {
  }
};
</script>
