<template>
  <a-modal title="Upgrade to full version"
           class="limit-access"
           v-model="visible"
           on-ok="handleOk"
           on-cancel="handleCancel"
  >
    <div class="limit-access__content">
      <ul class="limit-access__content__list">
        <li class="limit-access__content__list-item">
          <a-icon class="limit-access__content__list-item-svg"
                  type="check"/>
          Unlimited number of portfolios and projects
        </li>
        <li class="limit-access__content__list-item">
          <a-icon class="limit-access__content__list-item-svg"
                  type="check"/>
          Possibility to use canvas in currency
        </li>
        <li class="limit-access__content__list-item">
          <a-icon class="limit-access__content__list-item-svg"
                  type="check"/>
          Share and donwload canvases
        </li>
        <li class="limit-access__content__list-item">
          <a-icon class="limit-access__content__list-item-svg"
                  type="check"/>
          Ability to copy and distribute canvas
        </li>
        <li class="limit-access__content__list-item">
          <a-icon class="limit-access__content__list-item-svg"
                  type="check"/>
          Possibility to work in a team
        </li>
      </ul>
    </div>
    <template slot="footer">
      <div class="limit-access__footer">
        <p class="limit-access__footer-text">
          If you want to update your profile to a PRO version, just email us
        </p>
        <a class="limit-access__footer-email"
           href="malito:support@valuecanvas.digital">
          support@valuecanvas.digital
        </a>
      </div>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: 'LimitAccessPop',
  data() {
    return {
      visible: false,
      postValue: {},
      loading: false,
    };
  },
  methods: {
    handleKnowMore() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    }
  },
};
</script>

<style lang="less">
.limit-access {
  background-color: rgb(247, 249, 252);
  box-shadow: 0 24px 46px 8px rgba(0, 0, 0, 0.2);
  /* & .ant-modal{
    width: 300px !important;
    height: max-content !important;
  } */

  & .ant-modal {
    width: 625px !important;
    height: 430px !important;

    & .ant-modal-close {
      top: 5px;
      right: 10px;
    }
  }

  & .ant-modal-header {
    background-color: rgb(247, 249, 252);
    padding: 25px 30px;
    border: none;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }
  }

  & .ant-modal-body {
    padding: 0 25px;
    padding-top: 30px;
    background-color: rgb(247, 249, 252);
    border: none;
  }

  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 35px 55px;
    background-color: rgb(247, 249, 252);
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 25px;
    background-color: white;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(42, 47, 55);
    text-align: left;

    &__list {
      margin: 0;
      padding: 0;
      width: 100%;

      &-item {
        list-style-type: none;
        margin-bottom: 15px;

        &-svg {
          width: 18px;
          height: 15px;
          margin-right: 15px;
          color: rgb(37, 164, 30);
        }
      }
    }
  }

  &__footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;

    &-text {
      margin: 0;
      width: 100%;
      text-align: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: rgb(37, 164, 30);
      margin-bottom: 25px;
    }

    &-email {
      margin: 0;
      width: 100%;
      text-align: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: rgb(0, 102, 194);
      text-decoration: underline solid rgb(0, 102, 194);
    }
  }
}
</style>
