<template>
  <a-modal
      :title="title"
      class="portfolio__projects__action-to"
      v-model="visible"
      on-ok="handleOk"
      on-cancel="handleCancel"
  >
    <template slot="footer">
      <a-button class="portfolio__projects__action-to__footer-btn portfolio__projects__action-to__footer-cancel-btn"
                type="default"
                @click="handleCancel"
      >
        СANCEL
      </a-button>
      <a-button class="portfolio__projects__action-to__footer-btn"
                type="primary"
                @click="handleOk"
                :loading="loadingOk"
      >
        {{ textBtnOk }}
      </a-button>
    </template>

    <div class="portfolio__projects__action-to__content">
      <p>Clients</p>

      <a-select
          v-model="clientValue"
          :loading="clientLoading"
          style="width: 100%"
          @change="handleChange"

          
      >
        <a-select-option
            v-for="client in clientActive"
            :key="client._id"
            :value="client._id"
        >
          <a-icon
              type="folder"
              theme='filled'
              style="color: rgb(78, 96, 169)"
          />
          {{ client.client_name }}
        </a-select-option>
      </a-select>
      <span v-if="clientValue">
      <p>Portfolio</p>
      <a-select
          v-model="value"
          :loading="loading"
          style="width: 100%"
      >
        <a-select-option
            v-for="portfolio in portfolioActive"
            :key="portfolio.id"
            :value="portfolio.key"
        >
          <a-icon
              type="folder"
              theme='filled'
              style="color: rgb(78, 96, 169)"
          />
          {{ portfolio.portfolio_name }}
        </a-select-option>
      </a-select>
    </span>
    </div>
  </a-modal>
</template>

<script>
import moment from "moment";

export default {
  name: 'PortfolioProjectsPopActionTo',

  props: {
    title: String,
    textBtnOk: String,
  },

  data() {
    return {
      portfolioList: [],
      portfolioActive: [],
      visible: false,
      clientActive: [],
      clientValue: null,
      clientLoading: false,
      value: '',
      projectInfo: {
        project_name: ''
      },
      loading: false,
      loadingOk: false,
      record: {
        client_id: '',
        portfolio_id: '',
        portfolio_ids: [],
        project_id: '',
        user_id: ''
      }
    };
  },

  methods: {

    handleChange(newValue) {
      console.log(newValue)
      this.clientValue = newValue;
      if(this.clientValue) {
        this.fetch()
      }
    },
    handleKnowMore(record) {
      this.visible = true
      this.value = ''
      this.clientValue = '';
      this.record = record;
      this.fetchClient();
    },

    async fetchClient() {
      const res = await this.$http.get('/admin/api/rest/client/menu');
      console.log(res)
      this.clientActive = res.data;
    },

    async handleOk() {
      try {
        if (this.textBtnOk === 'MOVE') {
          await this.$http.post(`/projects/${this.record.project_id}/move`, {
            oldPortfolioId: this.record.portfolio_id,
            newPortfolioId: this.value
          })

          if (this.projectInfo.portfolio_ids.length <= 1) {
            this.$router.push({
              name: 'portfolio-project-list',
              params: {
                portfolio_id: this.record.portfolio_id
              }
            })
          }
        } else if (this.textBtnOk === 'COPY') {
          const copy = await this.$http.post(`/projects/${this.record.project_id}/portfolios/${this.value}/copy`)

          if (this.projectInfo.portfolio_ids.length <= 1) {
            if (this.value !== this.record.portfolio_id) {
              const params = {
                project_id: copy.data._id,
                user_id: copy.data.project_user[0],
                portfolio_id: this.value
              }
              this.visible = false
              this.$emit('redirect', params)
              return;
            } else {
              this.$router.push({
                name: 'portfolio-project-list',
                params: {
                  portfolio_id: this.record.portfolio_id
                },
              })
            }
          }
        } else {
          await this.$http.put(`/projects/${this.record.project_id}/portfolios/${this.value}/attach`)
        }
        this.$message.success(`Success project ${this.textBtnOk.toLowerCase()}.`)
      } catch (error) {
        this.$message.error(error.response.data.message)
      }
      this.visible = false
      this.$emit('update')
    },

    handleCancel() {
      this.visible = false;
    },

    async fetch() {
      this.loading = true;

      const url = `/admin/api/rest/portfolio/client/${this.clientValue}`;

      const res = await this.$http.get(url);

      res.data = res.data.map(value => {
        value.key = value._id;
        value.start_date = moment(value.start_date).format("YYYY-MM-DD");
        if (value.projects) value.projectsCount = value.projects.length;
        else value.projectsCount = 0;
        if (value.close_date)
          value.close_date = moment(value.close_date).format("YYYY-MM-DD")
        return value;
      });

      this.portfolioList = res.data;
      this.portfolioList.sort((a, b) => a?.portfolio_name.localeCompare(b?.portfolio_name));

      this.portfolioActive = [];
      for (const portfolio of this.portfolioList) {
        let projectInPortfolio = false
        for (const project of portfolio.projects) {
          if (project._id === this.record.project_id) {
            projectInPortfolio = true
            break;
          }
        }

        if (portfolio.projects.length === 0 || this.textBtnOk === 'COPY' || !projectInPortfolio) {
          this.portfolioActive.push(portfolio)
        }
      }

      const { data } = await this.$http.get("/admin/api/rest/project/" + this.record.project_id)
      if (data) {
        this.projectInfo = data
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="less">
.portfolio__projects__action-to {
  background-color: white;
  width: 600px;
  height: 207px;

  & .ant-modal-header {
    background-color: white;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  & .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__footer {
    &-cancel-btn {
      border: none;
      color: black !important;

      &:hover {
        color: #75c845 !important;
      }
    }

    &-btn {
      height: 36px !important;
      padding: 10px 45px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
