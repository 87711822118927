import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import SectorMetaData from "./modules/SectorMetaData"

Vue.use(Vuex);

const vuexConfig = {
    plugins: [createPersistedState()],
    state: {
        isLogin: false,
        userInfor: {},
        role: 0,
        navStack: [],
        clientMetaData: {},
        meta_data: {},
        euroActive: false,
        selectedClientId: null,
    },
    getters: {
        getLoginStatus: state => state.isLogin,
        getUserInfor: state => state.userInfor,
        getUserRole: state => state.role,
        getNavStack: state => state.navStack,
        getClientMetaData: state => state.clientMetaData,
        getEuroActive: state => state.euroActive,
    },

    mutations: {
        updateLoginStatus(state, payload) {
            state.isLogin = payload;
        },
        updateUserInfor(state, payload) {
            state.userInfor = payload;
            state.role = state.userInfor.role;
        },
        updateNavStack(state, payload) {
            state.navStack = payload;
        },
        setClientMetaData(state, data) {
            state.clientMetaData = data
        },
        set_meta_data(state, data) {
            state.meta_data = data
        },
        setEuroActive(state, isActive) {
            state.euroActive = isActive
        },
        SET_STATE(state, { key, value }) {
            state[key] = value
        }
    },

    modules: { SectorMetaData }
}

export default new Vuex.Store(vuexConfig);
