<template>
  <div class="meta-data__project__item">
    <div class="meta-data__project__item__header"
         @click="showContent = !showContent"
    >
      <MetaDataProjectArrowSvg class="meta-data__project__item__header-svg"
                               :class="{ 'meta-data__project__item__header-svg--active': showContent }"
      />
      <h4 class="meta-data__project__item__header-title">
        {{ title }}
      </h4>
    </div>
    <div class="meta-data__project__item__content"
         :class="{ 'meta-data__project__item__content--show': showContent }"
    >
      <div class="meta-data__project__item__content__item"
           v-for="item in data"
           :key="item.id"
      >
        <p class="meta-data__project__item__content__item-label">
          {{ item.label }}
        </p>
        <NotSet v-if="!item.initialValue"/>
        <p class="meta-data__project__item__content__item-value"
           v-if="item.initialValue"
        >
          {{ item.prefix + formatValue(item.prefix, item.initialValue) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MetaDataProjectArrowSvg from './MetaDataProjectArrowSvg'
import NotSet from '../NotSet'
import format from 'number-format.js'

export default {
  name: 'MetaDataProject',
  components: {
    MetaDataProjectArrowSvg,
    NotSet
  },
  props: {
    title: String,
    data: Array
  },
  data() {
    return {
      showContent: false
    }
  },
  methods: {
    formatValue(prefix, value) {
      // Do not show fractional part for percent and integer
      if (prefix.includes('%') || prefix === '') {
        return format(
            '##0',
            value
        )
      } else {
        let roundedValue = parseFloat(value).toFixed(2)
        return format(
            '###.###.###.##0,00',
            roundedValue
        )
      }
    },
  }
}
</script>

<style lang="less">
.meta-data__project__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 15px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;

    &-svg {
      margin: 0 10px 0 5px;

      &--active {
        transform: rotate(-90deg);
      }
    }

    &-title {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.385714px;
      line-height: 22px;
      color: #1A1A1A;
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #F7F9FC;
    width: 100%;
    transition: all 0.3s linear;
    height: 0;
    overflow: hidden;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &-label {
        width: 70%;
        flex-shrink: 0;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #2A2F37;
        margin: 0;
      }

      &-value {
        width: 100%;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.15px;
        line-height: 20px;
        color: #05825A;
        margin: 0;
      }
    }

    &--show {
      padding: 20px 15px;
      height: auto;
    }
  }
}
</style>
