<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-button type="primary" shape="primary" icon="left" size="large" @click="handleBack"></a-button>
        <h1 style="text-align: center; width: 100%;">{{ this.id ? 'Edit' : 'New' }} Portfolio</h1>
        <a-spin :spinning="loading">
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item
                label="Portfolio Name"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span:14 }"
            >
              <a-input
                  v-decorator="['portfolio_name', {rules:[{required:true,message:'Required'}]}]"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
              <a-button type="primary" :loading="confirmLoading" html-type="submit">Submit</a-button>
              <a-button
                  v-if="id&&[$userRole.EXCUBATE_ADMIN].includes($store.getters.getUserRole)"
                  type="danger"
                  :loading="confirmLoading"
                  @click.prevent="deletePortfolio"
                  :style="{'margin-left': '20px'}"
              >Delete Portfolio
              </a-button>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import moment from 'moment';

export default {
  // props: ['title', 'id'],
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this),
      confirmLoading: false,
      loading: false,
      client_id: false,
      datePickerConfig: {
        rules: [
          { type: "object", required: true, message: "Please select time!" }
        ]
      },
      dateFormat: "YYYY-MM-DD"
    };
  },
  methods: {
    deletePortfolio(e) {
      e.preventDefault();

      this.$confirm({
        title: "Do you want to delete this portfolio?",
        content: "",
        onOk: () => {
          return new Promise(resolve => {
            if (this.id) {
              this.confirmLoading = true;
              this.$http
                  .delete("/admin/api/rest/portfolio/" + this.id)
                  .then(res => {
                    if (res.status) {
                      this.$message.success("Delete Portfolio Success");
                      this.handleBack();
                    } else {
                      this.$message.error(res.msg);
                    }
                    this.confirmLoading = false;
                  })
                  .catch(error => {
                    this.$message.error(error.response.data.message);
                    this.confirmLoading = false;
                  });
            }

            resolve();
          }).catch(() => {
            this.loading = false;

          });
        },
        onCancel() {
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let model = null;
          values["client_id"] = this.client_id;
          if (this.id)
            model = await this.$http.put(`/admin/api/rest/portfolio/${this.id}`, values);
          else model = await this.$http.post(`/admin/api/rest/portfolio`, values);
          this.confirmLoading = false;
          if (model) this.$router.go(-1);
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },

    //loading data and init form value
    async fetch() {
      if (this.id) {
        this.loading = true;
        let model = await this.$http.get("/admin/api/rest/portfolio/" + this.id);

        // for (let i = 0; i < 100; i++)
        //   model = await this.$http.get("/admin/api/rest/project/" + this.id);
        // model.data.start_date = moment(model.data.start_date, this.dateFormat)
        this.client_id = model.data.client_id["_id"];
        this.form.setFieldsValue(model.data);
        this.loading = false;
      }
    }
  },
  async created() {
    this.id = this.$route.params.portfolio_id || "";
    this.client_id = this.$route.params.client_id || "";
    await this.fetch();
  },
  async mounted() {
  }
};
</script>
