import { render, staticRenderFns } from "./ProjectCanvas.vue?vue&type=template&id=3eb62217&"
import script from "./ProjectCanvas.vue?vue&type=script&lang=js&"
export * from "./ProjectCanvas.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCanvas.vue?vue&type=style&index=0&id=3eb62217&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports