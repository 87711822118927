const data = [
    {
        title: 'Revenue & Profits',
        data: [
            {
                key: 'total_revenue',
                label: 'Total Revenue',
                labelInfo: 'The yearly, overall revenue of your company.',
                prefix: '€',
                initialValue: null
            },
            {
                key: 'profit_impact',
                label: 'Profit impact',
                labelInfo: 'The profit (EBITDA) you make p.a. relative to your revenue (EBITDA-Margin)',
                prefix: '%',
                initialValue: null
            },
            {
                key: 'sold_products_total_number',
                label: 'Total number of products sold',
                labelInfo: 'The yearly total number of core products and services you sell. (e.g. 1500 machines)',
                prefix: '',
                initialValue: null
            },
            {
                key: 'customers_total_number',
                label: 'Total number of customers',
                labelInfo: 'The total (or average if services) number of customers your company has.',
                prefix: '',
                initialValue: null
            },
            {
                key: 'customer_retention_rate',
                label: 'Customer retention rate',
                labelInfo: 'The yearly customer retentation rate of your company. The % of active customers you could hold without loosing them.',
                prefix: '%',
                initialValue: null
            },
            {
                key: 'customer_recommendations_amount',
                label: 'Amount of customer recommendations p.a.',
                labelInfo: 'The number of times p.a. that your clients recommend your services/products to other, potential clients.',
                prefix: '',
                initialValue: null
            },
        ]
    },
    {
        title: 'Cost',
        data: [
            {
                key: 'total_cost_base',
                label: 'Total Cost Base',
                labelInfo: 'The yearly total cost base of your company.',
                prefix: '€',
                initialValue: null
            },
            {
                key: 'fte_total_number',
                label: 'Total number of FTE (employees)',
                labelInfo: 'The number of employees your company has on ist payroll - time point: end of the year.',
                prefix: '',
                initialValue: null
            },
            {
                key: 'fte_total_cost',
                label: 'Total FTE cost',
                labelInfo: "The total amount of cost, resulting from employees on your company's payroll.",
                prefix: '€',
                initialValue: null
            },
            {
                key: 'avg_acquisition_cost_per_hire',
                label: 'Average acquisition and onboarding cost per hire',
                labelInfo: 'The average amount of money your company pays, to hire a new employee (from first contact to contract signed).',
                prefix: '€',
                initialValue: null
            },
            {
                key: 'employee_churn_rate',
                label: 'Employee "churn rate"',
                labelInfo: 'The overall percentage of employees leaving your company in ratio to the total number of employees your company has.',
                prefix: '%',
                initialValue: null
            },
            {
                key: 'all_projects_total_cost',
                label: 'Total cost across all projects',
                labelInfo: 'The total cost base for all projects your company engages in.',
                prefix: '€',
                initialValue: null
            },
            {
                key: 'unsuccessful_projects_percentage',
                label: 'Percentage of unsuccessful projects',
                labelInfo: 'The percentage of projects that fail / are unsuccssful in ration to succesfully conducted projects in all areas of your company.',
                prefix: '%',
                initialValue: null
            },
            {
                key: 'penalty_total_cost',
                label: 'Total penalty cost',
                labelInfo: 'The total penalty cost for all projects your company engages in.',
                prefix: '€',
                initialValue: null
            }
        ]
    },
    {
        title: 'Assets',
        data: [
            {
                key: 'asset_total_cost',
                label: 'Total asset / equipment cost',
                labelInfo: 'The total cost base of your company to maintain its assets, required for successful value creation in the core business. For a manufacturer these can be production machines, for a hotel it can be hotel rooms.',
                prefix: '€',
                initialValue: null
            },
            {
                key: 'asset_utilization',
                label: 'Asset utilization',
                labelInfo: 'The percentage a certain asset (e.g. a machine) is in actual use, in ratio to its potential 100% uptime (=24/7).',
                prefix: '%',
                initialValue: null
            },
            {
                key: 'estimated_brand_awareness',
                label: 'Estimated brand awareness in target market',
                labelInfo: "The percentage of customers in a market, that are familiar with your company's brand.",
                prefix: '%',
                initialValue: null
            },
        ]
    },
    {
        title: 'Market',
        data: [
            {
                key: 'equity_impact_industry_multiplier',
                label: 'Industry Multiple for Equity Impact',
                labelInfo: "Your industry's standard multiplier. It follows the logic: Industry multiplier x EBITDA = equity value.",
                prefix: '',
                initialValue: null
            },
        ]
    }
]

export default data
