<template>
  <a-form-item>
    <span class="meta-data__item__label"
          slot="label"
    >
      {{ label }}
      <a-tooltip overlayClassName="meta-data__item__label-tooltip"
                 v-if="labelInfo"
                 placement="topLeft"
                 trigger="hover | focus"
      >
        <span slot="title">
          {{ labelInfo }}
        </span>
        <MetaDataItemInfoSvg class="meta-data__item__label-info"/>
      </a-tooltip>
    </span>
    <MetaDataItemContentItemInputMask class="meta-data__item__input"
                                      v-decorator="decorator"
                                      :prefix="prefix"
                                      @change="changeInput"
    />
    <a-button class="meta-data__item__reset-btn"
              v-if="resetActive"
              type="primary"
              :disabled="disabledResetBtn"
              @click="resetData"
    >
      <MetaDataResetSvg class="meta-data__item__reset-svg"/>
    </a-button>
  </a-form-item>
</template>

<script>
import MetaDataResetSvg from '../../components/MetaData/MetaDataResetSvg'
import MetaDataItemInfoSvg from './MetaDataItemInfoSvg'
import MetaDataItemContentItemInputMask from './MetaDataItemContentItemInputMask'
import { mapGetters } from 'vuex'
import numeral from 'numeral'

export default {
  name: 'MetaDataItemContentItem',
  components: {
    MetaDataResetSvg,
    MetaDataItemInfoSvg,
    MetaDataItemContentItemInputMask
  },
  props: {
    name: String,
    label: String,
    labelInfo: String,
    prefix: String,
    initialValue: Number,
    resetActive: Boolean
  },
  data() {
    return {
      disabledResetBtn: true
    }
  },
  computed: {
    ...mapGetters({
      getClientMetaData: 'getClientMetaData'
    }),
    formatValue() {
      if (!this.prefix.includes('€')) {
        return numeral(this.initialValue).format('0,0.[00]')
      }
      return numeral(this.initialValue).format('0,0.00')
    },
    decorator() {
      let rules = [
        {
          pattern: /(^\d+)|(^\d+\.*\d+,*\d+$)/,
          message: 'Incorrect value'
        }
      ]

      if (this.prefix.includes('%')) {
        rules = [
          {
            pattern: /(^\d?\d$)|(^\d?\d,\d\d?$)|(^100$)/,
            message: 'Incorrect value'
          }
        ]
      }

      const dec = [
        this.name,
        {
          initialValue: this.formatValue,
          rules: rules
        }
      ]
      return dec
    }
  },
  created() {
    for (const key in this.getClientMetaData) {
      if (key === this.name && this.initialValue !== this.getClientMetaData[key]) {
        this.disabledResetBtn = false
      }
    }
  },
  methods: {
    changeInput() {
      this.disabledResetBtn = false
    },
    resetData() {
      this.$emit('resetField', this.name)
      this.disabledResetBtn = true
    }
  }
}
</script>

<style lang="less">
.meta-data__item {
  &__label {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #585858;

    &-info {
      width: 14px;
      height: 14px;
      filter: invert(75%);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        filter: invert(20%);
      }
    }

    &-tooltip {
      & .ant-tooltip-content {
        margin-left: -14px;

        & .ant-tooltip-arrow {
          left: 0px;
        }
      }
    }
  }

  &__input, &__input input {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: rgb(57, 62, 70)
  }

  &__prefix {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6)
  }

  &__reset {
    &-btn {
      padding: 0;
      margin: 0;
      margin-left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      flex-shrink: 0;
      border: none;
      background-color: rgb(84, 88, 93);

      &[disabled="disabled"] {
        background-color: rgba(84, 88, 93, 0.6);

        &:hover {
          background-color: rgba(84, 88, 93, 0.6);
        }
      }
    }

    &-svg {
      width: 16, 25px;
      height: 15, 63px;
      fill: white;
    }
  }

}
</style>
