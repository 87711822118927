<template>
  <router-view/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminPanel',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.name !== 'edit-user' && vm.getUserRole !== vm.$userRole.EXCUBATE_ADMIN) {
        vm.$router.push({ name: 'login' })
      }
    })
  },
  computed: {
    ...mapGetters({
      getUserRole: 'getUserRole'
    })
  }
}
</script>
