<template>
  <a-table v-show="visible" :columns="columns" :dataSource="data" :loading="loading">
    <span slot="projectName" slot-scope="record">
        <a @click.prevent="updateCanvas(record.key)">{{ record.project_name }}</a>
      </span>
  </a-table>
</template>
<script>
export default {
  data() {
    return {

      columns: [
        {
          title: "Project Name",
          scopedSlots: { customRender: "projectName" }
        },
        {
          title: "Points Awarded",
          dataIndex: "point"
        },
        {
          title: "Explanation for Score",
          dataIndex: "explanation"
        },
        {
          title: "Relevant Metrics",
          dataIndex: "metrics"
        }
      ],
      loading: false,
    };
  },
  props: {
    visible: Boolean,
    data: Array
  },
  methods: {
    hideForm() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = false;
      this.$emit("hide");
    },
    showForm() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = true;
    },
    updateCanvas(id) {
      this.$emit("updateCanvas", id);
    }
  }
};
</script>
