<template>
  <div class="project">
    <div class="project__header">
      <div class="project__header__content">
        <div class="project__header__title">
          <a-button
              class="project__header__title-btn"
              type="primary"
              icon="left"
              size="large"
              @click="handleBack"
          />

          <ProjectSvg class="project__header__title-svg"/>

          <h1
              class="project__header__title-name"
              @click="redirect('client')"
          >
            {{ projectInfor.client_id.client_name }}
          </h1>
          &nbsp;>&nbsp;
          <h1
              class="project__header__title-name"
              @click="redirect('portfolio')"
          >
            {{ title }}
          </h1>
          &nbsp;>&nbsp;
          <h1 class="project__header__title-name">
            {{ projectInfor.project_name }}
          </h1>
        </div>
        <div class="project__header-btns">
          <a-button class="project__header-btn"
                    v-if="isEdit && !isAttach && !isPrevious"
                    v-show="!finishCanvas"
                    type="primary"
                    size="large"
                    @click="saveProcess"
          >
            <a-icon class="project__header-btn-svg"
                    type="save"/>
            Create Version Snapshot
          </a-button>
          <a-button class="project__header-btn"
                    v-if="(isEdit && !isAttach && !isPrevious)"
                    v-show="finishCanvas"
                    type="primary"
                    size="large"
                    @click="completeCanvas"
          >
            <a-icon class="project__header-btn-svg"
                    type="check"
            />
            Finished filling in Digital Value Canvas
          </a-button>
          <span v-if="this.getUserRole !== this.$userRole.DPO">
          <a-button class="project__header-btn project__header__attach-btn"
                    v-if="(isEdit && !limitAccess && isAttach && !isPrevious)"
                    type="primary"
                    size="large"
                    @click="AttachToHandleKnow"
          >
            <ProjectAttachSvg class="project__header-btn-svg project__header__attach-btn-svg"/>
            ATTACH TO
          </a-button>
        </span>
        </div>
        
      </div>
      <div class="project__nav">
        <a-button class="project__nav-btn"
                  :class="{ 'project__nav-btn--deactive': canvasdeDeactive }"
                  type="primary"
                  @click="toCanvas"
        >
          <ProjectCanvasSvg class="project__nav-btn-svg"/>
          Value Canvas
        </a-button>
        <span v-if="this.getUserRole !== this.$userRole.DPO">
        <a-button class="project__nav-btn"
                  :class="{ 'project__nav-btn--deactive': attachdeDeactive }"
                  v-if="(isEdit && !limitAccess)"
                  type="primary"
                  @click="toAttach"

        >
          <ProjectAttachToSvg class="project__nav-btn-svg"/>
          Attach to
        </a-button>
        </span>
        <a-button class="project__nav-btn"
                  :class="{ 'project__nav-btn--deactive': previousDeactive }"
                  type="primary"
                  @click="toPrevious"
        >
          <ProjectPreviousSvg class="project__nav-btn-svg"/>
          Previous versions
        </a-button>
      </div>
    </div>
    <div class="project__content">
      <router-view ref="activePage"/>
    </div>
    <ProjectsPopActionTo
        v-if="!limitAccess || this.getUserRole !== this.$userRole.DPO"
        title="Attach To"
        textBtnOk="ATTACH"
        @update="update"
        ref="attachTo"/>
  </div>
</template>

<script>
import ProjectCanvasSvg from '../../components/Project/ProjectCanvas/ProjectCanvasSvg'
import ProjectAttachToSvg from '../../components/Project/ProjectAttach/ProjectAttachToSvg'
import ProjectAttachSvg from '../../components/Project/ProjectAttach/ProjectAttachSvg'
import ProjectSvg from '../../components/Project/ProjectSvg'
import ProjectPreviousSvg from '../../components/Project/ProjectPrevious/ProjectPreviousSvg'
import ProjectsPopActionTo from '../../components/Project/ProjectsPopActionTo.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Project',

  components: {
    ProjectCanvasSvg, ProjectAttachToSvg, ProjectAttachSvg, ProjectSvg, ProjectsPopActionTo, ProjectPreviousSvg
  },

  data() {
    return {
      isEdit: false,
      isAttach: false,
      isPrevious: false,
      canvasdeDeactive: false,
      attachdeDeactive: true,
      previousDeactive: true,
      finishCanvas: false,
      project_id: "",
      projectInfor: {
        client_id: { client_name: "", logo: "" },
        portfolio_id: { portfolio_name: "" },
        project_name: ""
      },
      title: ''
    };
  },

  computed: {
    ...mapGetters(['getUserInfor']),
    ...mapGetters([ 'getUserRole']),

    limitAccess() {
      return this.getUserInfor?.limited_access === true
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.limitAccess && to.name === "dpo-projects-assement-attach") {
        vm.$router.push({
          name: "dpo-projects-assement-canvas",
          params: {
            user_id: vm.$route.params.user_id,
            project_id: vm.$route.params.project_id
          }
        })
      }
      if (to.name === "dpo-projects-assement-attach") {
        vm.canvasdeDeactive = true
        vm.attachdeDeactive = false
        vm.previousDeactive = true
        vm.isAttach = true
      } else {
        vm.isAttach = false
        if (to.name === "dpo-projects-assement-previous") {
          vm.canvasdeDeactive = true
          vm.attachdeDeactive = true
          vm.previousDeactive = false
          vm.isPrevious = true
        }
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (this.limitAccess && to.name === "dpo-projects-assement-attach") {
      this.$router.push({
        name: "dpo-projects-assement-canvas",
        params: {
          user_id: this.$route.params.user_id,
          project_id: this.$route.params.project_id
        }
      })
    }
    if (to.name === "dpo-projects-assement-attach") {
      this.canvasdeDeactive = true
      this.attachdeDeactive = false
      this.previousDeactive = true
      this.isAttach = true
    } else {
      this.isAttach = false
      if (to.name === "dpo-projects-assement-previous") {
        this.canvasdeDeactive = true
        this.attachdeDeactive = true
        this.previousDeactive = false
        this.isPrevious = true
      } else {
        this.isPrevious = false
        if (to.name === "dpo-projects-assement-canvas") {
          this.canvasdeDeactive = false
          this.attachdeDeactive = true
          this.previousDeactive = true
        }
      }
    }
    next()
  },

  async created() {
    this.project_id = this.$route.params.project_id || "5e6896afa8bf5e550fd937a7"

    if (this.$route.name === "dpo-projects-assement-view") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      if (this.$route.name === "dpo-projects-assement-attach") {
        this.isAttach = true;
      }
    }

    await this.loadData()
  },

  methods: {
    update() {
      this.$refs.activePage?.fetch()
    },

    AttachToHandleKnow() {
      this.$refs.attachTo.handleKnowMore({
        client_id: this.projectInfor.client_id._id,
        portfolio_id: this.$route.params.portfolio_id,
        project_id: this.projectInfor._id,
        user_id: this.projectInfor.client_id.client_user[0]._id
      })
    },

    redirect(path) {
      if (this.$route.params.portfolio_id) {
        if (path === 'client') {
                const { _id } = this.projectInfor.client_id

                this.$router.push(`/client/${_id}/portfolios`)
              } else if (path === 'portfolio') {
                const { portfolio_id } = this.$route.params

                this.$router.push(`/portfolio/${portfolio_id}/projects`)
          }
      }
    },

    hideSideMenu () {
        let element = document.getElementsByClassName('menu-tree');
        element[0].style.display = 'none';
    },

    async loadData() {
      try {
        const { data } = await this.$http.get(`/admin/api/rest/project/${this.project_id}`)

        this.projectInfor = data
        let portfolio;
        if(this.$route.params.portfolio_id) {
         portfolio = this.projectInfor.portfolio_ids.find(({ _id }) => this.$route.params.portfolio_id === _id)
        } else {
          this.hideSideMenu()
          portfolio = this.projectInfor.portfolio_ids[0]
        }
        this.title = portfolio.portfolio_name

      } catch (error) {
        console.error(error)
      }
    },

    toCanvas() {
      this.canvasdeDeactive = false
      this.attachdeDeactive = true
      this.previousDeactive = true
      this.isAttach = false
      this.isPrevious = false

      const { user_id, project_id, portfolio_id } = this.$route.params

      this.$router.push({
        name: 'dpo-projects-assement-canvas',
        params: { user_id, project_id, portfolio_id }
      })
    },

    toAttach() {
      this.canvasdeDeactive = true
      this.attachdeDeactive = false
      this.previousDeactive = true
      this.isAttach = true
      this.isPrevious = false

      const { user_id, project_id, portfolio_id } = this.$route.params

      this.$router.push({
        name: 'dpo-projects-assement-attach',
        params: { user_id, project_id, portfolio_id }
      })
    },

    toPrevious() {
      this.canvasdeDeactive = true
      this.attachdeDeactive = true
      this.previousDeactive = false
      this.isAttach = false
      this.isPrevious = true

      const { user_id, project_id } = this.$route.params

      this.$router.push({
        name: 'dpo-projects-assement-previous',
        params: { user_id, project_id }
      })
    },

    handleBack() {
      if(this.$route.params.portfolio_id) {
          this.$router.go(-1)
      } else {
        this.$router.push({
          name: "dpo-projects",
        });
      }
    },

    saveProcess() {
      this.$refs.activePage?.saveProcess()
    },

    async completeCanvas() {
      const { project_id } = this

      await this.$http.post('/admin/api/rest/project/project-dvc-complete', { project_id })

      this.$message.success('Create Version Snapshot Success.')
    }
  }
}
</script>

<style lang="less">
.project {
  background-color: #F7F9FC;

  &__header {
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
        cursor: pointer;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-svg {
      margin-right: 10px;
    }

    &-btn {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;

      &-svg {
        margin-right: 10px;
      }
    }

    &__attach {
      &-btn {
        padding-right: 40px;

        &-svg {
          filter: invert(100%);
          margin-right: 30px;
        }
      }
    }
  }

  &__nav {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-btn {
      margin-right: 15px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--deactive {
        color: rgb(57, 62, 70);
        font-weight: 400;
        background-color: #EBEBEB;
        border-color: #EBEBEB;

        & .project__nav-btn-svg {
          filter: invert(0);
        }

        &:hover .project__nav-btn-svg {
          filter: invert(100%);
        }
      }

      &-svg {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 16px;
        height: 16px;
        margin-right: 5px;
        filter: invert(100%);
      }
    }
  }

  &__content {
    padding-top: 20px;
    box-shadow: 0 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
  }
}
</style>
