var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_vm._m(0),_c('a-form',{staticClass:"login__form",attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',[_c('h2',{staticClass:"login__form__header"},[_vm._v(" Log in ")])]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        {rules:[{type:'email',message:'Email address is malformed'},{required:true,message:'Required'}]}
      ]),expression:"[\n        'email',\n        {rules:[{type:'email',message:'Email address is malformed'},{required:true,message:'Required'}]}\n      ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {rules:[{required:true,message:'Required'}]}
      ]),expression:"[\n        'password',\n        {rules:[{required:true,message:'Required'}]}\n      ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1),_c('a',{staticClass:"login__form__forgot",on:{"click":_vm.toForgotPassword}},[_vm._v(" Forgot your password? ")])],1),_c('a-form-item',[_c('a-button',{staticClass:"login__form__log-in",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitLoading}},[_vm._v(" Log in ")]),(!_vm.isPrivateInstance)?_c('div',{staticClass:"login__form__sign-up"},[_c('p',{staticClass:"login__form__sign-up-text"},[_vm._v(" Dont have an account? ")]),_c('a',{staticClass:"login__form__sign-up-link",on:{"click":_vm.toSignUp}},[_vm._v(" Sign Up ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login__logo"},[_c('a',{attrs:{"href":"https://www.excubate.de","target":"_blank"}},[_c('img',{staticClass:"login__logo-img",attrs:{"src":require("../assets/excubate-logo-full-high-resolution-min.png")}})])])
}]

export { render, staticRenderFns }