<template>
  <div class="portfolio">
    <div class="portfolio__header">
      <div class="portfolio__header__content">
        <div class="portfolio__header__title">
          <a-button
              class="portfolio__header__title-btn"
              type="primary"
              icon="left"
              size="large"
              @click="handleBack"
          />

          <project-svg class="portfolio__header__title-svg"/>

          <h1
              class="portfolio__header__title-name cursor-pointer"
              @click="redirect"
          >
            {{ portfolioProfile.client_id.client_name }}
          </h1>
          &nbsp;>&nbsp;
          <h1 class="portfolio__header__title-name">
            {{ portfolioProfile.portfolio_name }} Analysis
          </h1>
        </div>

        <div class="portfolio__header__btns">
          <a-button
              class="portfolio__header__btn portfolio__header__add-btn"
              v-if="addRecordRight && !reponPortfolioRight"
              type="primary"
              size="large"
              :style="{ 'overflow': 'hidden'}"
              @click="handleAdd"
          >
            <a-icon type="plus"/>
            Add Project
            <LimitAccess/>
          </a-button>

          <a-button class="portfolio__header__btn"
                    v-if="closePortfolioRight"
                    type="primary"
                    size="large"
                    @click="closePortfolio"
          >
            <a-icon type="save"/>
            Close editing and create timestamp
          </a-button>

          <a-button
              class="portfolio__header__btn"
              v-if="reponPortfolioRight"
              type="primary"
              size="large"
              @click="reopenPortfolio"
          >
            <a-icon type="save"/>
            Delete timestamp and re-open portfolio
          </a-button>
        </div>
      </div>
      <div class="portfolio__nav">
        <a-button class="portfolio__nav-btn"
                  :class="{ 'portfolio__nav-btn--deactive': analysisDeactive }"
                  type="primary"
                  @click="toAnalysis"
        >
          <PortfolioAnalysisSvg class="portfolio__nav-btn-svg"/>
          Portfolio Analysis
        </a-button>
        <a-button
            class="portfolio__nav-btn"
            :class="{ 'portfolio__nav-btn--deactive': projectsDeactive }"
            type="primary"
            @click="toProjects"
        >
          <PortfolioProjectsSvg class="portfolio__nav-btn-svg"/>
          Projects
        </a-button>
      </div>
    </div>
    <div class="portfolio__content">
      <router-view ref="pageActive"/>
    </div>

    <limit-access-pop ref="limitAccess"/>
  </div>
</template>

<script>
import LimitAccessPop from '../../components/LimitAccess/LimitAccessPop'
import LimitAccess from '../../components/LimitAccess/LimitAccess'
import ProjectSvg from '../../components/Project/ProjectSvg'
import PortfolioProjectsSvg from '../../components/Portfolio/PortfolioProjects/PortfolioProjectsSvg'
import PortfolioAnalysisSvg from '../../components/Portfolio/PortfolioAnalysis/PortfolioAnalysisSvg'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Portfolio',

  components: {
    LimitAccessPop,
    LimitAccess,
    ProjectSvg,
    PortfolioProjectsSvg,
    PortfolioAnalysisSvg
  },

  data() {
    return {
      portfolio_id: this.$route.params.portfolio_id,
      portfolioProfile: {
        portfolio_name: '',
        client_id: {
          client_name: ''
        }
      },
      closePortfolioRight: false,
      reponPortfolioRight: false,
      analysisDeactive: false,
      projectsDeactive: true,
      addRecordRight: false
    }
  },

  created() {
    this.addRecordRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.EXCUBATE_EMPLOYEE,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole);

    this.fetch()
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.name === 'portfolio-project-list') {
        vm.analysisDeactive = true
        vm.projectsDeactive = false
      }
    })
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === 'portfolio-project-list') {
      this.analysisDeactive = true
      this.projectsDeactive = false
    } else {
      if (to.name === 'portfolio-delta') {
        this.analysisDeactive = false
        this.projectsDeactive = true
      }
    }
    next()
  },

  computed: {
    ...mapGetters(['getUserRole', 'getUserInfor']),

    limitAccess() {
      return this.getUserInfor?.limited_access === true
    }
  },

  methods: {
    ...mapMutations(['SET_STATE']),

    redirect() {
      const { _id } = this.portfolioProfile.client_id

      this.$router.push(`/client/${_id}/portfolios`)
    },

    toAnalysis() {
      this.projectsDeactive = true
      this.analysisDeactive = false
      this.$router.push({
        name: 'portfolio-delta',
        params: {
          portfolio_id: this.$route.params.portfolio_id
        }
      })
    },
    toProjects() {
      this.projectsDeactive = false
      this.analysisDeactive = true
      this.$router.push({
        name: 'portfolio-project-list',
        params: {
          portfolio_id: this.$route.params.portfolio_id
        }
      })
    },

    handleBack() {
      this.$router.go(-1)
    },

    handleAdd() {
      if (this.limitAccess) {
        this.$refs.limitAccess.handleKnowMore()
      } else {
        this.$router.push({
          name: "add-project",
          params: { portfolio_id: this.portfolio_id }
        });
      }
    },
    async closePortfolio() {
      const res = await this.$http.put("/admin/api/rest/portfolio/update-status", {
        portfolio_id: this.portfolio_id,
        status: "close"
      });
      if (res.data) {
        this.closePortfolioRight = false;
        this.reponPortfolioRight = true;
        this.$message.success("Close success.");
      } else {
        this.$message.error("Opps error");
      }
      this.$refs.pageActive.fetch()
    },

    async reopenPortfolio() {
      const { portfolio_id } = this

      const { data } = await this.$http.put("/admin/api/rest/portfolio/update-status", {
        portfolio_id,
        status: "reopen"
      })

      if (data) {
        this.closePortfolioRight = true;
        this.reponPortfolioRight = false;
        this.$message.success("Reopen success.");
      } else {
        this.$message.error("Opps error");
      }

      this.$refs.pageActive.fetch()
    },

    async fetch() {
      // if (this.getUserRole != this.$userRole.DPO) {
      try {
        const { data } = await this.$http.get(`/admin/api/rest/portfolio/${this.portfolio_id}`)

        this.portfolioProfile = data;

        this.SET_STATE({ key: 'selectedClientId', value: this.portfolioProfile.client_id._id })

        const { EXCUBATE_ADMIN, EXCUBATE_EMPLOYEE, CLIENT_DTO } = this.$userRole

        this.closePortfolioRight = false
        this.reponPortfolioRight = false

        if ([EXCUBATE_ADMIN, EXCUBATE_EMPLOYEE, CLIENT_DTO].includes(this.getUserRole)) {
          this.closePortfolioRight = this.portfolioProfile.status == 2

          this.reponPortfolioRight = this.portfolioProfile?.status == 4
        }
      } catch (error) {
        console.error(error)
      }
      // }
    }
  }
}
</script>

<style lang="less">
.cursor-pointer {
  cursor: pointer;
}

.portfolio {
  background-color: #F7F9FC;

  &__header {
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
      }
    }

    &__btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__svg {
      margin-right: 10px;
    }

    &__btn {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;

      &-svg {
        margin-right: 10px;
      }
    }

    &__add-btn {
      padding-right: 30px;
    }
  }

  &__nav {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-btn {
      margin-right: 15px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--deactive {
        color: rgb(57, 62, 70);
        font-weight: 400;
        background-color: #EBEBEB;
        border-color: #EBEBEB;

        & .portfolio__nav-btn-svg {
          filter: invert(0);
        }

        &:hover .portfolio__nav-btn-svg {
          filter: invert(100%);
        }
      }

      &-svg {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 16px;
        height: 16px;
        margin-right: 5px;
        filter: invert(100%);
      }
    }
  }

  &__content {
    padding-top: 20px;
    box-shadow: 0 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
  }
}
</style>
