<template>
  <div class="meta-data__item">
    <h3 class="meta-data__item__title">
      {{ title }}
    </h3>
    <div class="meta-data__item__content">
      <MetaDataItemContentItem v-for="item in data"
                               :key="item.key"
                               :name="item.key"
                               :label="item.label"
                               :labelInfo="item.labelInfo"
                               :prefix="item.prefix"
                               :initialValue="item.initialValue"
                               :resetActive="resetActive"
                               @resetField="resetField"
                               ref="metaDataItem"
      />
    </div>
  </div>
</template>

<script>
import MetaDataItemContentItem from './MetaDataItemContentItem'

export default {
  name: 'MetaDataItem',
  components: {
    MetaDataItemContentItem
  },
  props: {
    title: String,
    data: Array,
    resetActive: Boolean
  },
  methods: {
    resetField(nameField) {
      this.$emit('resetField', nameField)
    }
  }
}
</script>

<style lang="less">
.meta-data__item {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &__content {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #FFFFFF;

    & .ant-form-item {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & .ant-form-item-label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 30%;
        flex-grow: 1;

        & > label::after, & > label::before {
          display: none;
        }
      }

      & .ant-form-item-children {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & .ant-input {
          width: 200px;
          height: 34px;
        }
      }
    }
  }

  &__title {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.385714px;
    color: #1A1A1A;
  }
}

</style>
