<template>
  <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
      class="form-reset-password"
  >
    <a-form-item label="New password">
      <a-input
          v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }]"
          type="password"
      />
    </a-form-item>
    <a-form-item label="Please enter again">
      <a-input
          v-decorator="['password_again', { rules: [{ required: true, message: 'Please input your password!' }] }]"
          type="password"
      />
    </a-form-item>
    <a-form-item>
      <a-input v-decorator="['user_id']" type="hidden"/>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
      <a-button type="primary" html-type="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "set_password" })
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      // info();

      this.loading = false;
      const res = await this.$http.get(`/admin/api/getuser/${this.$route.params.id}`);

      if (res.data) {
        this.form.setFieldsValue({ user_id: res.data });
        // this.loading = false;
      } else {
        alert("Sorry, Something is wrong");
      }
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (values["password"] !== values["password_again"]) {
            this.form.setFields({
              password_again: {
                value: values["password_again"],
                errors: [new Error("Passwords entered twice are inconsistent.")]
              }
            });
          } else {
            const model = await this.$http.post("/admin/api/resetpassword", values);

            if (model.data) {
              this.$message.success("Your password was changed successfully.");
            }
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.form-reset-password {
  width: 680px;
  margin: auto;
  padding-top: 100px;
}
</style>
