<template>
  <div class="sign-up__form-content">
    <a-form
        id="components__form-demo-normal__sign-up"
        :form="form"
        class="sign-up__form"
        @submit.prevent="handleSubmit"
    >
      <a-form-item>
        <p class="sign-up__form-title">
          Sign Up
        </p>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'firstName',
            {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  pattern: /^([A-Z][a-z]+)$/,
                  message: 'You need to enter your first name.'
                }
              ]
            }
          ]"
            placeholder="First Name"
        >
          <a-icon slot="prefix"
                  type="user"
                  style="color: rgba(0,0,0,.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'lastName',
            {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  pattern: /^([A-Z][a-z]+)$/,
                  message: 'You need to enter your last name.'
                }
              ]
            }
          ]"
            placeholder="Last Name"
        >
          <a-icon slot="prefix"
                  type="user"
                  style="color: rgba(0,0,0,.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'organization',
            {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'You need to enter your organization.'
                }
              ]
            }
          ]"
            placeholder="Your Organization"
        >
          <register-organization-svg slot="prefix"
                                     style="filter: opacity(25%);"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'You need to enter your email.'
                }
              ]
            }
          ]"
            placeholder="Expl@gmail.com"
        >
          <a-icon slot="prefix"
                  type="user"
                  style="color: rgba(0,0,0,.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'password',
            {
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: 'You need to enter a password.'
                }
              ]
            }
          ]"
            type="password"
            placeholder="***********"
        >
          <a-icon slot="prefix"
                  type="lock"
                  style="color: rgba(0,0,0,.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'confirm',
            {
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: 'You need to confirm your password.'
                },
                {
                  validator: compareToFirstPassword
                }
              ],
            }
          ]"
            type="password"
            placeholder="***********"
        >
          <a-icon slot="prefix"
                  type="lock"
                  style="color: rgba(0,0,0,.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-checkbox class="sign-up__form-checkbox"
                    v-decorator="[
            'agree',
            {
              valuePropName: 'checked',
              rules:[
                {
                  required: true,
                  validator: isAgreeTrue,
                  message: 'You must accept the privacy policy before continuing.'
                },
              ],
            },
          ]"
        >
          I agree to
          <a class="sign-up__form-checkbox-text"
             @click="handleKnowTerms"
          >
            Terms of Use
          </a>
          and
          <a class="sign-up__form-checkbox-text"
             @click="handleKnowPrivacy"
          >
            Privacy Policy
          </a>
        </a-checkbox>
      </a-form-item>
      <a-form-item>
        <a-button class="sign-up__form-button"
                  type="primary"
                  html-type="submit"
                  :loading="submitLoading"
        >
          Sign Up
        </a-button>
      </a-form-item>
      <div class="sign-up__form-footer">
        <p class="sign-up__form-footer-text">
          Already have an account?
        </p>
        &nbsp;
        <a class="sign-up__form-sign-in"
           @click="toSignIn"
        >
          Sign In
        </a>
      </div>
    </a-form>
    <RegisterPopInfo title="Terms of Use" ref="popTerms"/>
    <RegisterPopInfo title="Privacy Policy" ref="popPrivacy"/>
  </div>
</template>

<script>
import RegisterPopInfo from '../../components/Register/RegisterPopInfo'
import RegisterOrganizationSvg from '../../components/Register/RegisterOrganizationSvg'

export default {
  name: 'Register',
  components: {
    RegisterPopInfo, RegisterOrganizationSvg
  },
  data() {
    return {
      submitLoading: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_register' });
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('You need to confirm your password.');
      } else {
        callback();
      }
    },
    isAgreeTrue(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback('You must accept the privacy policy before continuing.');
      }
    },
    toSignIn() {
      this.$router.push({ name: "login" });
    },
    handleKnowTerms() {
      this.$refs.popTerms.handleKnowMore();
    },
    handleKnowPrivacy() {
      this.$refs.popPrivacy.handleKnowMore();
    },
    handleSubmit() {

      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitLoading = true;
          const postValues = {
            email: values.email,
            password: values.password,
            user_name: `${values.firstName} ${values.lastName}`,
            client_name: values.organization
          }

          this.$http
              .post("/auth/signup", postValues)
              .then(() => {
                this.$router.push({ name: "register-verify" });
              })
              .catch(error => {
                // handle error
                if (error.response.data.message.includes('exists')) {
                  if (error.response.data.message.includes('email')) {
                    this.form.setFields({
                      email: {
                        value: values.email,
                        errors: [new Error('This email is already connected to an account. Log in.')]
                      }
                    })
                  }
                  if (error.response.data.message.includes('Client')) {
                    this.form.setFields({
                      organization: {
                        value: values.organization,
                        errors: [new Error('This client is already connected to an account. Log in or change name.')]
                      }
                    })
                  }
                }
              })
              .finally(() => {
                this.submitLoading = false;
              });
        }
      });
    }
  }
};
</script>

<style lang="less">
.sign-up {
  &__form {
    width: 100%;
    height: 100%;

    &-content {
      width: 100%;
      height: 100%;
    }

    &-title {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      text-align: center;
      letter-spacing: 0.75px;
      margin: 0;
    }

    &-checkbox {
      font-family: 'Roboto';
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #393E46;

      &-text {
        color: #09409B;
        text-decoration: none;
      }
    }

    &-button {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.583333px;
      line-height: 20px;
      width: 100%;
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      &-text {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: fade(#2A2F37, 60%);
        margin: 0;
      }
    }

    &-sign-in {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #004CA2
    }
  }
}
</style>
