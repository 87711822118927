import Vue from 'vue'
import App from './App.vue'
import './plugins/ant-design-vue.js'
import router from './router'
import http from './http'
import store from './store/index'
import * as UserRole from './staticData/UserRole'

import './assets/roboto-fontface.css'
import './assets/ibm-plex.css'

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$userRole = UserRole

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
