<template>
  <div class="portfolio__projects__more">
    <a-tooltip placement="bottom">
      <template slot="title">
        <span>Show More</span>
      </template>
      <a-button class="portfolio__projects__more-btn"
                type="primary"
                size="default"
                @click="activeShowMore"
      >
        <a-icon class="portfolio__projects__more-btn-svg"
                type="more"/>
      </a-button>
    </a-tooltip>
    <a-menu class="portfolio__projects__more__menu"
            v-if="showMenu"
    >
      <a-menu-item class="portfolio__projects__more__menu-item"
                   @click="toCanvas"
      >
        Value сanvas
      </a-menu-item>
      <a-menu-item
          class="portfolio__projects__more__menu-item"
          @click="activeCopyTo"
      >
        Copy to
      </a-menu-item>
      <a-menu-item class="portfolio__projects__more__menu-item"
                   @click="activeMoveTo"
      >
        Move to
      </a-menu-item>
      <a-menu-item class="portfolio__projects__more__menu-item"
                   @click="activeAttachTo"
      >
        Attach to
      </a-menu-item>
      <a-menu-item
          v-if="record.portfolio_ids.length > 1"
          class="portfolio__projects__more__menu-item"
          @click="activeDetach"
      >
        Detach
      </a-menu-item>

      <a-menu-item
          v-else
          class="portfolio__projects__more__menu-item portfolio__projects__more__menu-item--delete"
          @click="activeDelete"
      >
        Delete
      </a-menu-item>
    </a-menu>

    <ProjectsPopActionTo
        title="Copy To"
        textBtnOk="COPY"
        ref="popCopyTo"
        @update="update"
        @redirect="showRedirectModal"
    />

    <ProjectsPopActionTo
        title="Move To"
        textBtnOk="MOVE"
        ref="popMoveTo"
        @update="update"
    />

    <project-redirect-modal
        title="Open"
        textBtnOk="OPEN"
        ref="popOpenConfirm"
    />

    <ProjectsPopConfirm
        title="Detach"
        textBtnOk="DETACH"
        ref="popDetachConfirm"
        @update="update"
    />

    <ProjectsPopConfirm
        title="Delete"
        textBtnOk="DELETE"
        ref="popDeleteConfirm"
        @update="update"
    />
  </div>
</template>

<script>
import ProjectsPopActionTo from '../../Project/ProjectsPopActionTo.vue'
import ProjectsPopConfirm from '../../Project/ProjectsPopConfirm.vue'
import ProjectRedirectModal from '../../Project/ProjectRedirectModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PortfolioProjectsMore',

  components: {
    ProjectsPopActionTo,
    ProjectsPopConfirm,
    ProjectRedirectModal
  },

  props: {
    record: Object
  },
  computed: {
    ...mapGetters([ 'getUserRole']),
  },


  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    showRedirectModal(params) {
      this.showMenu = false
      this.$refs.popOpenConfirm.handleKnowMore(params)
    },

    update() {
      this.$emit('update')
    },

    deactiveShowMore() {
      this.showMenu = false
    },

    activeShowMore() {
      this.$emit('activeShowMore', this)
      this.showMenu = !this.showMenu
    },

    toCanvas() {
      this.showMenu = false
      this.$router.push({
        name: "dpo-projects-assement",
        params: {
          project_id: this.record.project_id,
          user_id: this.record.user_id,
          portfolio_id: this.record.portfolio_id
        }
      });
    },

    activeCopyTo() {
      this.showMenu = false
      this.$refs.popCopyTo.handleKnowMore(this.record)
    },
    activeCopy() {
      this.showMenu = false
      this.$refs.popCopyConfirm.handleKnowMore(this.record)
    },
    activeMoveTo() {
      this.showMenu = false
      this.$refs.popMoveTo.handleKnowMore(this.record)
    },
    activeAttachTo() {
      this.$router.push({
        name: 'dpo-projects-assement-attach',
        params: {
          project_id: this.record.project_id,
          user_id: this.record.user_id,
          portfolio_id: this.record.portfolio_id
        }
      })
    },
    activeDetach() {
      this.showMenu = false
      this.$refs.popDetachConfirm.handleKnowMore(this.record)
    },
    activeDelete() {
      this.showMenu = false
      this.$refs.popDeleteConfirm.handleKnowMore(this.record)
    }
  }
}
</script>

<style lang="less">
.portfolio__projects__more {
  margin: 0 !important;
  position: relative;
  width: 46px;
  height: 32px;

  &-btn {
    margin: 0 !important;
    width: 46px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-svg {
      flex-shrink: 0;
      transform: rotate(90deg);

      & svg {
        width: 26px;
        height: auto;
      }
    }
  }

  &__menu {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 5px;
    position: absolute;
    width: 180px;
    height: max-content;
    padding: 0 15px;
    z-index: 3;
    right: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 1);

    &-item {
      margin: 0 !important;
      padding: 0 !important;
      border-bottom: 1px solid rgb(224, 224, 224);
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.16px;
      line-height: 18px;
      color: rgb(82, 82, 82);

      &--delete {
        color: rgb(194, 27, 59);
      }

      &:last-of-type {
        border: none;
      }
    }
  }
}
</style>
