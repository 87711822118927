<template>
  <div
      class="portfolio__project-list"
      @click="deactiveShowMore($event)"
  >
    <a-table class="portfolio__project-list__table"
             :columns="columns"
             :dataSource="data"
             :loading="loading"
    >
      <span class="portfolio__project-list__table__title"
            v-for="column in columns"
            :key="column.id"
            :slot="column.slots.title"
      >
        <span class="portfolio__project-list__table__title-word"
              v-for="word in column.title_text.split(' ')"
              :key="word.id"
        >
          {{ word }}
        </span>
      </span>
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
          Reset
        </a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
              v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <span slot="projectName" slot-scope="project_name, record">
        <a @click.prevent="portfolioProfile.status == 4 ? viewDVC(record.owner_id, record._id) : editDVC(record.owner_id, record._id)">
          {{ project_name }}
        </a>
      </span>
      <span slot="invitation" slot-scope="invitation">
        <a-tag
            class="portfolio__project-list__table__project-status"
            style="cursor: default"
            v-for="tag in invitation"
            :color="'geekblue'"
            :key="tag"
        >
          <span
              v-for="char in tag.split(' ')"
              :key="char.id"
          >
            {{ char }}
          </span>
        </a-tag>
      </span>

      <a-tag
          class="portfolio__project-list__table__project-status"
          slot="status"
          slot-scope="status"
          style="cursor: default"
          :color="'geekblue'"
      >
        <span
            v-for="char in projectStatus[status].split(' ')"
            :key="char.id"
        >
          {{ char }}
        </span>
      </a-tag>
      <span slot="action" slot-scope="record">
        <div class="portfolio__project-list__table__action">
          <a-tooltip placement="bottom">
            <template slot="title">
              Send invitation to {{ record.owner_name }}
            </template>
            <a-button class="portfolio__project-list__table__action-item"
                      type="primary"
                      size="default"
                      v-if="sendInvitationRight && !reponPortfolioRight"
                      @click.prevent="sendInvitation(record._id)"
            >
              <a-icon type="notification"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template slot="title">
              Edit Project
            </template>
            <a-button class="portfolio__project-list__table__action-item"
                      type="primary"
                      size="default"
                      v-if="editRecordRight && !reponPortfolioRight"
                      @click.prevent="editRecord(record._id)"
            >
              <a-icon type="edit"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template slot="title">
              Show Digital Value Canvas
            </template>

            <a-button class="portfolio__project-list__table__action-item"
                      type="primary"
                      size="default"
                      v-if="viewDVCRight || editDVCRight"
                      @click.prevent="portfolioProfile.status == 4 ? viewDVC(record.owner_id, record._id) : editDVC(record.owner_id, record._id)"
            >
              <a-icon type="eye"/>
            </a-button>
          </a-tooltip>

          <portfolio-projects-more
              class="portfolio__project-list__table__action-item"
              v-if="!limitAccess && portfolioProfile"
              :record="{
              client_id: portfolioProfile.client_id._id,
              portfolio_id: portfolioProfile._id,
              project_id: record._id,
              user_id: record.owner_id,
              portfolio_ids: record.portfolio_ids
            }"
              @activeShowMore="activeShowMore"
              @update="fetch"
              :ref="`showMenu_${record._id}`"
          />
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
import { projectStatus } from "../../staticData/ProjectStatus";
import moment from "moment";
import PortfolioProjectsMore from '../../components/Portfolio/PortfolioProjects/PortfolioProjectsMore'
import { mapGetters } from 'vuex'

const data = [];

export default {
  name: 'PortfolioProjectList',

  components: { PortfolioProjectsMore },

  data() {
    return {
      projectStatus,
      data,
      columns: [
        {
          title_text: 'Project Name',
          dataIndex: "project_name",
          slots: {
            title: "project_nameTitle",
          },
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "projectName"
          },
          onFilter: (value, record) => {
            return record.project_name.toString().toLowerCase().includes(value.toLowerCase())
          },

          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => this.searchInput.focus(), 0)
            }
          }
        },
        {
          title_text: 'Digital Project Owner',
          dataIndex: "owner_name",
          slots: {
            title: "owner_nameTitle",
          },
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName"
          },

          onFilter: (value, record) => {
            return record.owner_name.toString().toLowerCase().includes(value.toLowerCase())
          },

          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => this.searchInput.focus(), 0)
            }
          }
        },
        {
          title_text: 'Email',
          dataIndex: "owner_email",
          slots: {
            title: "emailTitle",
          },
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName"
          },
          onFilter: (value, record) => {
            return record.owner_email
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title_text: 'Estimated Project Impact',
          dataIndex: "project_impact",
          slots: {
            title: "project_impactTitle",
          },
          ellipsis: true,
          filters: [
            { text: "1", value: "1" },
            { text: "2", value: "2" },
            { text: "3", value: "3" },
            { text: "4", value: "4" }
          ],
          onFilter: (value, record) =>
              record.project_impact.toString().includes(value)
        },
        {
          title_text: 'Last Invitation Sent',
          dataIndex: "invitation",
          slots: {
            title: "invitationTitle",
          },
          scopedSlots: { customRender: "invitation" },
          ellipsis: true
        },
        {
          title_text: 'Status',
          dataIndex: "status",
          slots: {
            title: "statusTitle",
          },
          scopedSlots: {
            customRender: "status"
          },
          filters: [
            { text: "No Invitation", value: "0" },
            { text: "Invitation Sent", value: "1" },
            { text: "DVC Started and Saved", value: "2" },
            { text: "DVC Completed", value: "3" }
          ],
          onFilter: (value, record) => record.status.toString().includes(value)
        },
        {
          title_text: 'Action',
          slots: {
            title: "actionTitle",
          },
          scopedSlots: { customRender: "action" }
        }
      ],
      loading: true,
      portfolio_id: this.$route.params.portfolio_id,
      portfolioProfile: null,
      editRecordRight: false,
      addRecordRight: false,
      closePortfolioRight: false,
      reponPortfolioRight: false,
      viewDVCRight: false,
      editDVCRight: false,
      sendInvitationRight: false,
      showProjectLandingPage: false,
      showMore: null
    };
  },

  computed: {
    ...mapGetters(['getUserRole', 'getUserInfor']),

    limitAccess() {
      return this.getUserInfor?.limited_access === true
    }
  },

  methods: {
    deactiveShowMore(event) {
      let isShowMore = false

      for (const el of event.composedPath()) {
        if (el.className?.includes('portfolio__projects__more')) {
          isShowMore = true
          break
        }
      }

      for (const itemRefs in this.$refs) {
        if (this.$refs[itemRefs]) {
          if (isShowMore) {
            if (this.$refs[itemRefs]._uid !== this.showMore._uid) {
              this.$refs[itemRefs].deactiveShowMore()
            }
          } else {
            this.$refs[itemRefs].deactiveShowMore()
          }
        }
      }
    },
    activeShowMore(showMore) {
      this.showMore = showMore
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async fetch() {
      this.loading = true;

      let url = `/admin/api/rest/project/portfolio/${this.portfolio_id}`;

      // todo: check logic
      // if (this.getUserRole == this.$userRole.DPO) {
      //   url = '/admin/api/rest/project/my-project';
      // }

      const res = await this.$http.get(url)

      res.data = res.data.map(value => {
        value.key = value._id

        if (!Array.isArray(value.project_user)) {
          value.owner_email = value.project_user.email
          value.owner_name = value.project_user.user_name
        } else {
          value.owner_email = value.project_user.map(({ email }) => email).join("; ")
          value.owner_name = value.project_user.map(({ user_name }) => user_name).join("; ")
        }

        if (value.project_user.length > 0) {
          value.owner_id = value.project_user[0]._id;
        } else {
          value.owner_id = value.create_user;
        }

        if (!value.status) {
          value.status = 0
        }

        if (value.invitation.length > 0) {
          value.invitation = [value.invitation[value.invitation.length - 1]]
          value.invitation = value.invitation.map(({ invitation_time }) => moment(invitation_time).format("YYYY-MM-DD HH:mm:ss"))
        }

        return value
      })

      this.data = res.data;
      this.loading = false;
      // if (this.getUserRole != this.$userRole.DPO) {
      this.$http
          .get("/admin/api/rest/portfolio/" + this.portfolio_id)
          .then(({ data }) => {
            this.portfolioProfile = data

            this.closePortfolioRight =
                [
                  this.$userRole.EXCUBATE_ADMIN,
                  this.$userRole.EXCUBATE_EMPLOYEE,
                  this.$userRole.CLIENT_DTO
                ].includes(this.getUserRole) &&
                this.portfolioProfile["status"] == 2;

            this.reponPortfolioRight =
                [
                  this.$userRole.EXCUBATE_ADMIN,
                  this.$userRole.EXCUBATE_EMPLOYEE,
                  this.$userRole.CLIENT_DTO
                ].includes(this.getUserRole) &&
                this.portfolioProfile &&
                this.portfolioProfile["status"] == 4;
          })
          .catch(console.error);
      // }
    },

    editRecord(id) {
      this.$router.push({
        name: "edit-project",
        params: {
          project_id: id,
          portfolio_id: this.portfolio_id
        }
      });
    },

    viewDVC(user_id, project_id) {
      this.$router.push({
        name: "dpo-projects-assement-view",
        params: { project_id, user_id, portfolio_id: this.portfolio_id }
      });
    },

    editDVC(user_id, project_id) {
      const { portfolio_id } = this

      this.$router.push({
        name: 'dpo-projects-assement',
        params: { project_id, user_id, portfolio_id }
      })
    },

    sendInvitation(project_id) {
      this.$http.post(`/admin/api/rest/project/invitation/${project_id}`)
          .then(() => {

            this.$message.success("Send invitation success.");
            this.fetch();
          })
          .catch(err => {
            this.$message.error(err?.response?.data.message)
          });
    },
    projectLandingPage(project_id) {
      this.$router.push({
        name: "dpo-project-landing-page",
        params: { project_id }
      });
    }
  },
  created() {
    this.addRecordRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.EXCUBATE_EMPLOYEE,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole);

    this.editRecordRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole);
    this.sendInvitationRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.EXCUBATE_EMPLOYEE,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole);
    this.viewDVCRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.EXCUBATE_EMPLOYEE,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole);
    this.editDVCRight = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.DPO
    ].includes(this.getUserRole);
    this.showProjectLandingPage = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.DPO
    ].includes(this.getUserRole);
    this.fetch();
  },
  beforeUpdate() {
    this.itemRefs = []
  },
};
</script>

<style lang="less">
.portfolio__project-list {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 10px;

  &__table {
    border-radius: 10px;

    & .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & .ant-table-column-title {
      background-color: #FAFAFA;
      font-family: Helvetica;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & .ant-table-row {
      font-family: Helvetica;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.232143px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-pagination {
      margin-right: 20px;
    }

    &__title {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      &-word {
        white-space: pre-wrap;
      }
    }

    &__action {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 36px;
        padding: 0 !important;
        margin-right: 10px !important;
        margin-bottom: 5px !important;
      }
    }

    &__project-status {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-shrink: 0;
      white-space: pre-wrap;
    }
  }
}
</style>
