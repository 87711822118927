<template>
  <a-modal title="Please log in again."
           class="session-completed"
           v-model="visible"
           :closable="false"
           :maskClosable="false"
           on-ok="handleOk"
           on-cancel="handleCancel"
  >
    <div class="session-completed__content">
      <p class="session-completed__content-text">
        To keep your work secure, your Exubate session has timed out. To log back in, please refresh your browser, or
        hit Log In below.
      </p>
    </div>
    <template slot="footer">
      <div class="session-completed__footer">
        <a-button class="session-completed__footer-btn"
                  type="primary"
                  @click="handleOk"
        >
          Log In
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: 'SessionCompletedPop',
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleKnowMore() {
      this.visible = true;
    },
    handleOk() {
      this.$router.push({ name: 'login' })
    },
    handleCancel() {
    }
  },
};
</script>

<style lang="less">
.session-completed {
  background-color: rgb(247, 249, 252);
  box-shadow: 0 24px 46px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  & .ant-modal {
    width: 625px !important;
    min-height: 290px !important;
  }

  & .ant-modal-header {
    background-color: rgb(247, 249, 252);
    padding: 25px 25px;
    border: none;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.43px;
      color: #393E46;
      text-align: center;
    }
  }

  & .ant-modal-body {
    padding: 10px 25px;
    background-color: rgb(247, 249, 252);
    border: none;
  }

  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 25px 25px;
    background-color: rgb(247, 249, 252);
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 25px;
    background-color: white;

    &-text {
      margin: 0;
      width: 100%;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2A2F37;
      text-align: center;
    }
  }

  &__footer {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    &-btn {
      padding: 10px 25px;
      height: max-content !important;
      width: 180px;
      font-family: "Roboto";
      font-weight: 500;
      letter-spacing: 0.583333px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}
</style>
