<template>
  <a-modal
      :visible="visible"
      :title="modalData.title"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      style="margin-right: 15%; top: 50px;"
      :mask="false"
      :maskStyle="{'background-color': 'rgba(132, 132, 132, 0.25)'}"
      okText="Submit"
      @cancel="() => { $emit('cancel') }"
      @ok="handleSubmit"
  >
    <a-form layout="vertical" :form="form">
      <a-form-item label="Assigned Points">
        <!-- <a-input :defaultValue="modalData.totalAssignedPoint" disabled="true" /> -->
        <span>{{ modalData.totalAssignedPoint }}</span>
      </a-form-item>
      <a-form-item label="Available Points">
        <!-- <a-input :defaultValue="100-modalData.totalAssignedPoint" disabled="true"/> -->
        <span>{{ 100 - modalData.totalAssignedPoint }}</span>
      </a-form-item>
      <a-form-item label="Points Awarded">
        <a-input
            v-decorator="[
              'point',
              {
                rules: [{ required: true, message: 'Required' }],
                initialValue: modalData.point
              }
            ]"
        />
      </a-form-item>
      <a-form-item label="Explanation for Score">
        <a-input
            type="textarea"
            :autosize="{ minRows: 3}"
            v-decorator="[
                'explanation',
                {
                    rules: [{ required: true, message: 'Required' }],
                    initialValue: modalData.explanation
                }
              ]"
        />
      </a-form-item>
      <a-form-item label="Relevant Metrics">
        <a-input
            type="textarea"
            :autosize="{ minRows: 3}"
            v-decorator="[
                'metrics',
                {
                    rules: [{ required: true, message: 'Required' }],
                    initialValue: modalData.metrics
                }
            ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    modalData: Object,
    confirmLoading: Boolean
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "form_in_modal" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {

        if (!err) {

          this.$emit('submit', values)
        }

      });
    }
  }
};
</script>
