<template>
  <a-input-number class="project__canvas__segment__form__item__input"
                  :value="value"
                  @change="change"
                  :max="maxPoint"
                  :min="minPoint"
  />
</template>

<script>

export default {
  name: "ProjectCanvasSegmentFormInputMask",
  props: {
    value: String,
    maxPoint: Number,
    minPoint: Number,
  },
  methods: {
    change(e) {
      let value = e
      if (e === null) {
        value = this.minPoint
      }
      this.$emit('change', String(value));
    },
  },
}
</script>
