<template>
  <div class="forgot-password__form-content">
    <a-form
        id="components__form-demo-normal__forgot-password"
        :form="form"
        class="forgot-password__form"
        @submit.prevent="handleSubmit"
    >
      <a-form-item>
        <p class="forgot-password__form-title">
          Forgot your password?
        </p>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'You need to enter your email.'
                }
              ]
            }
          ]"
            placeholder="Email Address"
        >
          <forgot-password-email-svg slot="prefix"
                                     style="filter: opacity(25%);"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button class="forgot-password__form-button"
                  type="primary"
                  html-type="submit"
                  :loading="submitLoading"
        >
          Reset Password
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import ForgotPasswordEmailSvg from '../../components/ForgotPassword/ForgotPasswordEmailSvg'

export default {
  name: 'ForgotPasswordForm',
  components: {
    ForgotPasswordEmailSvg
  },
  data() {
    return {
      submitLoading: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_forgot-password' });
  },
  methods: {
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitLoading = true;
          this.$http
              .post("/auth/request-reset-password", values)
              .then(() => {
                // handle success
                this.$router.push({ name: "forgot-password-verify" });
              })
              .catch(error => {
                // handle error
                this.form.setFields({
                  email: {
                    value: values.email,
                    errors: [new Error(error.response.data.message)]
                  }
                })

              })
              .finally(() => {
                this.submitLoading = false;
              });
        }
      });
    }
  }
};
</script>

<style lang="less">
.forgot-password {
  &__form {
    width: 100%;
    min-height: 173px;
    height: max-content;

    & .ant-form-item:last-of-type {
      margin-top: 45px;
      margin-bottom: 5px;
    }

    &-content {
      width: 100%;
      height: 100%;
    }

    &-title {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      text-align: center;
      letter-spacing: 0.75px;
      margin: 0;
    }

    &-button {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.583333px;
      line-height: 20px;
      width: 100%;
    }
  }
}
</style>
