<template>
  <div class="forgot-password">
    <div class="forgot-password__logo">
      <a href="https://www.excubate.de"
         target="_blank"
      >
        <img class="forgot-password__logo-img"
             src="../../assets/excubate-logo-full-high-resolution-min.png"
        >
      </a>
    </div>
    <div class="forgot-password__content">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgotPassword'
};
</script>

<style lang="less">
.forgot-password {
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 120px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__logo {
    height: 100px;
    width: 300px;
    margin-bottom: 25px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    border: 1px solid #001529;
    width: 535px;
    min-height: 223px;
    height: max-content;
    border-radius: 5px;
    padding: 25px 70px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 2px 10px 2px rgba(188, 202, 224, 0.5);
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
}
</style>
