var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up__form-content"},[_c('a-form',{staticClass:"sign-up__form",attrs:{"id":"components__form-demo-normal__sign-up","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',[_c('p',{staticClass:"sign-up__form-title"},[_vm._v(" Sign Up ")])]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'firstName',
          {
            rules: [
              {
                required: true,
                whitespace: true,
                pattern: /^([A-Z][a-z]+)$/,
                message: 'You need to enter your first name.'
              }
            ]
          }
        ]),expression:"[\n          'firstName',\n          {\n            rules: [\n              {\n                required: true,\n                whitespace: true,\n                pattern: /^([A-Z][a-z]+)$/,\n                message: 'You need to enter your first name.'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"First Name"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'lastName',
          {
            rules: [
              {
                required: true,
                whitespace: true,
                pattern: /^([A-Z][a-z]+)$/,
                message: 'You need to enter your last name.'
              }
            ]
          }
        ]),expression:"[\n          'lastName',\n          {\n            rules: [\n              {\n                required: true,\n                whitespace: true,\n                pattern: /^([A-Z][a-z]+)$/,\n                message: 'You need to enter your last name.'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"Last Name"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'organization',
          {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'You need to enter your organization.'
              }
            ]
          }
        ]),expression:"[\n          'organization',\n          {\n            rules: [\n              {\n                required: true,\n                whitespace: true,\n                message: 'You need to enter your organization.'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"Your Organization"}},[_c('register-organization-svg',{staticStyle:{"filter":"opacity(25%)"},attrs:{"slot":"prefix"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                type: 'email',
                message: 'You need to enter your email.'
              }
            ]
          }
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                type: 'email',\n                message: 'You need to enter your email.'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"Expl@gmail.com"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                whitespace: true,
                required: true,
                message: 'You need to enter a password.'
              }
            ]
          }
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                whitespace: true,\n                required: true,\n                message: 'You need to enter a password.'\n              }\n            ]\n          }\n        ]"}],attrs:{"type":"password","placeholder":"***********"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirm',
          {
            rules: [
              {
                whitespace: true,
                required: true,
                message: 'You need to confirm your password.'
              },
              {
                validator: _vm.compareToFirstPassword
              }
            ],
          }
        ]),expression:"[\n          'confirm',\n          {\n            rules: [\n              {\n                whitespace: true,\n                required: true,\n                message: 'You need to confirm your password.'\n              },\n              {\n                validator: compareToFirstPassword\n              }\n            ],\n          }\n        ]"}],attrs:{"type":"password","placeholder":"***********"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'agree',
          {
            valuePropName: 'checked',
            rules:[
              {
                required: true,
                validator: _vm.isAgreeTrue,
                message: 'You must accept the privacy policy before continuing.'
              },
            ],
          },
        ]),expression:"[\n          'agree',\n          {\n            valuePropName: 'checked',\n            rules:[\n              {\n                required: true,\n                validator: isAgreeTrue,\n                message: 'You must accept the privacy policy before continuing.'\n              },\n            ],\n          },\n        ]"}],staticClass:"sign-up__form-checkbox"},[_vm._v(" I agree to "),_c('a',{staticClass:"sign-up__form-checkbox-text",on:{"click":_vm.handleKnowTerms}},[_vm._v(" Terms of Use ")]),_vm._v(" and "),_c('a',{staticClass:"sign-up__form-checkbox-text",on:{"click":_vm.handleKnowPrivacy}},[_vm._v(" Privacy Policy ")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"sign-up__form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitLoading}},[_vm._v(" Sign Up ")])],1),_c('div',{staticClass:"sign-up__form-footer"},[_c('p',{staticClass:"sign-up__form-footer-text"},[_vm._v(" Already have an account? ")]),_vm._v("   "),_c('a',{staticClass:"sign-up__form-sign-in",on:{"click":_vm.toSignIn}},[_vm._v(" Sign In ")])])],1),_c('RegisterPopInfo',{ref:"popTerms",attrs:{"title":"Terms of Use"}}),_c('RegisterPopInfo',{ref:"popPrivacy",attrs:{"title":"Privacy Policy"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }