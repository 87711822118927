var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password__form-content"},[_c('a-form',{staticClass:"forgot-password__form",attrs:{"id":"components__form-demo-normal__forgot-password","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',[_c('p',{staticClass:"forgot-password__form-title"},[_vm._v(" Forgot your password? ")])]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                type: 'email',
                message: 'You need to enter your email.'
              }
            ]
          }
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                type: 'email',\n                message: 'You need to enter your email.'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"Email Address"}},[_c('forgot-password-email-svg',{staticStyle:{"filter":"opacity(25%)"},attrs:{"slot":"prefix"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"forgot-password__form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitLoading}},[_vm._v(" Reset Password ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }