import Vue from 'vue'
import VueRouter from 'vue-router'

import MainEmpty from '../views/MainEmpty.vue'

import Login from '../views/Login.vue'

import Register from '../views/Register/Register.vue'
import RegisterForm from '../views/Register/RegisterForm.vue'
import RegisterVerify from '../views/Register/RegisterVerify.vue'
import RegisterVerified from '../views/Register/RegisterVerified.vue'

import ForgotPassword from '../views/ForgotPassword/ForgotPassword.vue'
import ForgotPasswordForm from '../views/ForgotPassword/ForgotPasswordForm.vue'
import ForgotPasswordVerify from '../views/ForgotPassword/ForgotPasswordVerify.vue'
import ForgotPasswordVerified from '../views/ForgotPassword/ForgotPasswordVerified.vue'

import Main from '../views/Main.vue'

import Password from '../views/SetPassword.vue'
import ModalChart from '../views/ModalChart.vue'
import TestComponent from '../views/TestComponent'

import ClientList from '../views/Clients/ClientList.vue'
import ClientEdit from '../views/Clients/ClientEdit.vue'

import AdminPanel from '../views/AdminPanel/AdminPanel.vue'
import UserList from '../views/AdminPanel/UserList.vue'
import UserEdit from '../views/AdminPanel/UserEdit.vue'
import UserActivity from '../views/AdminPanel/UserActivity.vue'
import FeedbackList from '../views/AdminPanel/FeedbackList.vue';

import Project from '../views/Project/Project.vue'
import DpoProject from '../views/Project/DpoProject.vue'

import ProjectCanvas from '../views/Project/ProjectCanvas.vue'
import ProjectAttach from '../views/Project/ProjectAttach.vue'
import ProjectPrevious from '../views/Project/ProjectPrevious.vue'

import Portfolio from '../views/Portfolio/Portfolio.vue'
import PortfolioAnalysis from '../views/Portfolio/PortfolioAnalysis.vue'
import PortfolioAnalysisDelta from '../views/Portfolio/PortfolioAnalysisDelta'
import PortfolioProjectList from '../views/Portfolio/PortfolioProjectList.vue'
import PortfolioProjectEdit from '../views/Portfolio/PortfolioProjectEdit.vue'

import Client from '../views/ClientPortfolio/Client.vue'
import ClientPortfolioList from '../views/ClientPortfolio/ClientPortfolioList.vue'
import ClientPortfolioEdit from '../views/ClientPortfolio/ClientPortfolioEdit.vue'
import ClientPortfolioTargetState from '../views/ClientPortfolio/ClientPortfolioTargetState'

import Tutorial from '../views/Tutorial/Tutorial.vue'
import TutorialDVCIntroduction from '../views/Tutorial/TutorialDVCIntroduction.vue'
import TutorialProjectLandingPage from '../views/Tutorial/TutorialProjectLandingPage.vue'

import MetaData from '../views/MetaData/MetaData.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: { name: 'login' },
        name: 'MainEmpty',
        component: MainEmpty,
        children: [
            {
                path: '/login',
                name: 'login',
                component: Login,
                meta: {
                    title: 'Login'
                }
            },
            {
                path: '/reset/:id',
                name: 'password',
                component: Password,
                meta: {
                    title: 'Set Password'
                }
            },
            {
                //Register
                path: '/register',
                redirect: { name: 'register-form' },
                name: 'register',
                component: Register,
                meta: {
                    title: 'Register'
                },
                children: [
                    {
                        path: '',
                        name: 'register-form',
                        component: RegisterForm,
                        meta: {
                            title: 'Register User'
                        }
                    },
                    {
                        path: '',
                        name: 'register-verify',
                        component: RegisterVerify,
                        meta: {
                            title: 'Register Verify'
                        }
                    }
                ]
            },
            {
                path: '/register-verified',
                name: 'register-verified',
                component: RegisterVerified,
                meta: {
                    title: 'Register Verified'
                }
            },
            {
                //Forgot Password
                path: '/forgot-password',
                redirect: { name: 'forgot-password-form' },
                name: 'forgot-password',
                component: ForgotPassword,
                meta: {
                    title: 'Forgot Password'
                },
                children: [
                    {
                        path: '',
                        name: 'forgot-password-form',
                        component: ForgotPasswordForm,
                        meta: {
                            title: 'Forgot Password'
                        }
                    },
                    {
                        path: '',
                        name: 'forgot-password-verify',
                        component: ForgotPasswordVerify,
                        meta: {
                            title: 'Forgot Password Verify'
                        }
                    }
                ]
            },
            {
                path: '/reset-password',
                name: 'reset-password',
                component: ForgotPasswordVerified,
                meta: {
                    title: 'Create New Password'
                }
            }
        ]
    },
    {
        path: '/',
        redirect: { name: 'login' },
        name: 'Main',
        component: Main,
        children: [
            //Client list
            {
                path: '/clients',
                name: 'client-list',
                component: ClientList,
                meta: {
                    title: 'Client',
                    menu_name: 'client-list',
                    metaTags: [
                        {
                            name: 'description',
                            content: 'The home page of our example app.'
                        }
                    ]
                }
            },
            //Add Client
            {
                path: '/client/add',
                name: 'add-client',
                component: ClientEdit,
                meta: {
                    title: 'Add Client',
                    menu_name: 'client-list'
                },
            },
            //Edit Client
            {
                path: '/client/edit/:client_id',
                name: 'edit-client',
                component: ClientEdit,
                meta: {
                    title: 'Edit Client',
                    menu_name: 'client-list'
                },
            },
            //Portfolio list of DTO
            {
                path: '/my-portfolio',
                name: 'dto-portfolio-list',
                component: ClientPortfolioList,
                meta: {
                    title: 'Client Portfolio',
                    menu_name: 'client-portfolio-list'
                },
            },
            //Client
            {
                path: '/client/:client_id/',
                redirect: { name: 'client-portfolio-list' },
                name: 'client',
                component: Client,
                meta: {
                    title: 'Client',
                    menu_name: 'client-portfolio-list'
                },
                children: [
                    //Portfolio list of client
                    {
                        path: '/client/:client_id/portfolios',
                        name: 'client-portfolio-list',
                        component: ClientPortfolioList,
                        meta: {
                            title: 'Portfolios',
                            menu_name: 'client-portfolio-list'
                        },
                    },
                    //Add Portfolio
                    {
                        path: '/portfolio/add/:client_id',
                        name: 'add-portfolio',
                        component: ClientPortfolioEdit,
                        meta: {
                            title: 'Add Portfolio',
                            menu_name: 'client-portfolio-list'
                        },
                    },
                    //Edit Portfolio
                    {
                        path: '/portfolio/edit/:portfolio_id',
                        name: 'edit-portfolio',
                        component: ClientPortfolioEdit,
                        meta: {
                            title: 'Edit Portfolio',
                            menu_name: 'client-portfolio-list'
                        },
                    },
                    //portfolio target state
                    {
                        path: '/portfolio-target-state/:portfolio_id',
                        name: 'portfolio-target-state',
                        component: ClientPortfolioTargetState,
                        meta: {
                            title: 'Define Target State',
                            menu_name: 'portfolio-target-state'
                        },
                    }
                ]
            },
            {
                //DpoProject
                path: '/dpo-projects',
                name: 'dpo-projects',
                component: DpoProject,
                meta: {
                    title: 'dpo-projects'
                },
            },
            //Portfolio
            {
                path: '/portfolio/:portfolio_id/',
                redirect: { name: 'portfolio-delta' },
                name: 'portfolio',
                component: Portfolio,
                meta: {
                    title: 'Portfolio',
                    menu_name: 'portfolio'
                },
                children: [
                    //Portfolio Analysis Delta
                    {
                        path: 'analysis',
                        name: 'portfolio-delta',
                        meta: {
                            title: 'Portfolio Delta',
                            menu_name: 'portfolio-delta'
                        },
                        component: PortfolioAnalysisDelta,
                    },
                    //Portfolio Analysis
                    {
                        path: 'analysis',
                        name: 'portfolio-heatmap',
                        meta: {
                            title: 'Portfolio Analysis',
                            menu_name: 'portfolio-heatmap'
                        },
                        component: PortfolioAnalysis,
                    },
                    //Project list of portfolio
                    {
                        path: 'projects',
                        name: 'portfolio-project-list',
                        component: PortfolioProjectList,
                        meta: {
                            title: 'Projects',
                            menu_name: 'portfolio-project-list'
                        }
                    },
                ]
            },
            //Portfolio: Add Project
            {
                path: '/portfolio/:portfolio_id/project/add/',
                name: 'add-project',
                component: PortfolioProjectEdit,
                meta: {
                    title: 'Add Project',
                    menu_name: 'portfolio-project-list'
                },
            },
            //Portfolio: Edit Project
            {
                path: '/portfolio/:portfolio_id/project/edit/:project_id',
                name: 'edit-project',
                component: PortfolioProjectEdit,
                meta: {
                    title: 'Edit Project',
                    menu_name: 'portfolio-project-list'
                },
            },
            //Tutorial
            {
                path: '/tutorial/',
                redirect: { name: 'tutorial-dvc-introduction' },
                name: 'tutorial',
                component: Tutorial,
                meta: {
                    title: 'tutorial',
                    menu_name: 'tutorial'
                },
                children: [
                    //Digital Project Owner: digital value canvas introduction
                    {
                        path: '/tutorial/digital-value-canvas-introduction',
                        name: 'tutorial-dvc-introduction',
                        component: TutorialDVCIntroduction,
                        meta: {
                            title: 'Digital Value Canvas Introduction',
                            menu_name: 'dpo-project-landing-page'
                        },
                    },
                    //Digital Project Owner: Project Landing page
                    {
                        path: '/tutorial/dpo-project-landing-page',
                        name: 'tutorial-dpo-project-landing-page',
                        component: TutorialProjectLandingPage,
                        meta: {
                            title: 'Digital Value Canvas Introduction',
                            menu_name: 'dpo-project-landing-page'
                        },
                    }
                ]
            },
            //Client: Projects Heatmap
            {
                path: '/client/:id/heatmap',
                name: 'client-heatmap',
                component: ClientEdit,
                meta: {
                    title: 'Heatmap',
                    menu_name: 'client-heatmap'
                },
            },
            //EDIT Project
            {
                path: '/dpo/:user_id/portfolio/:portfolio_id/project/:project_id/assessment',
                redirect: { name: 'dpo-projects-assement-canvas' },
                name: 'dpo-projects-assement',
                component: Project,
                meta: {
                    title: 'Project',
                    menu_name: 'dpo-projects-assement'
                },
                children: [
                    //EDIT Digital Value Canvas
                    {
                        path: 'canvas',
                        name: 'dpo-projects-assement-canvas',
                        component: ProjectCanvas,
                        meta: {
                            title: 'Digital Value Canvas',
                            menu_name: 'dpo-projects-assement-canvas'
                        },
                    },
                    //View Digital Value Canvas
                    {
                        path: 'view',
                        name: 'dpo-projects-assement-view',
                        meta: {
                            title: 'Digital Value Canvas',
                            menu_name: 'dpo-projects-assement-canvas'
                        },
                        component: ProjectCanvas,
                    },
                    //Project Attach
                    {
                        path: 'attach',
                        name: 'dpo-projects-assement-attach',
                        meta: {
                            title: 'Project Attach',
                            menu_name: 'dpo-projects-assement-attach'
                        },
                        component: ProjectAttach,
                    },
                    // Project Previous
                    {
                        path: 'previous',
                        name: 'dpo-projects-assement-previous',
                        meta: {
                            title: 'Previous Versions',
                            menu_name: 'dpo-projects-assement-attach'
                        },
                        component: ProjectPrevious,
                    },
                ]
            },
            //Admin Panel
            {
                path: '',
                name: 'admin-panel',
                meta: {
                    title: 'Admin Panel',
                    menu_name: 'user-list'
                },
                component: AdminPanel,
                children: [
                    //User Active
                    {
                        path: '/user/activity/:user_id/',
                        name: 'user-activity',
                        meta: {
                            title: 'User Activity',
                            menu_name: 'user-list'
                        },
                        component: UserActivity,
                    },
                    //User-list
                    {
                        path: '/user-list',
                        name: 'user-list',
                        meta: {
                            title: 'User Management',
                            menu_name: 'user-list'
                        },
                        component: UserList,
                    },
                    //Add User
                    {
                        path: '/user/add',
                        name: 'add-user',
                        component: UserEdit,
                        meta: {
                            title: 'Add User',
                            menu_name: 'user-list'
                        },
                    },
                    //Edit User
                    {
                        path: '/user/edit/:user_id',
                        name: 'edit-user',
                        component: UserEdit,
                        meta: {
                            title: 'Edit User',
                            menu_name: 'user-list'
                        },
                    },
                    //Feedback List
                    {
                        path: '/feedback-list',
                        name: 'feedback-list',
                        component: FeedbackList,
                        meta: {
                            title: 'Feedback List',
                            menu_name: 'feedback-list'
                        },
                    },
                ]
            },
            //Meta Data
            {
                path: '/client/:client_id/meta-data-define',
                name: 'meta-data',
                component: MetaData,
                meta: {
                    title: 'Define Meta Data',
                    menu_name: 'client-portfolio-list'
                },
            },
            //Meta Data Project
            {
                path: '/dpo/:user_id/project/:project_id/meta-data-overwrite',
                name: 'meta-data-project',
                component: MetaData,
                meta: {
                    title: 'Overwrite Meta Data',
                    menu_name: 'dpo-projects-assement'
                },
            },
            //test chart
            {
                path: '/test-chart',
                name: 'test-chart',
                component: ProjectCanvas,
            },
            //modal chart
            {
                path: '/modal-chart',
                name: 'modal-chart',
                component: ModalChart,
            },
            //test component
            {
                path: '/test-component',
                name: 'test-component',
                component: TestComponent,
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    // to.meta.title = to.meta.title !== undefined ? to.meta.title + ' - ' : ''
    document.title = to.meta.title + ' - Excubate Digital Value Canvas'
    next()
})

export default router
