<template>
  <a-layout
      id="components-layout-demo-custom-trigger"
      style="height: 100vh"
  >
    <a-layout>
      <a-layout-sider
          :trigger="null"
          collapsible
          v-model="collapsed"
      >
        <div class="logo"/>

        <ul class="menu-tree">
          <li
              v-for="nav in navStack"
              :key="nav.title"
          >
            <router-link :to="{ name: nav.route.name, params: { client_id: selectedClientId } }">
              <a-icon :type="navStackIcon[nav.route.name]"/>
              <span>{{ nav.title }}</span>
            </router-link>
          </li>
        </ul>

        <a-menu theme="dark" :style="{position:'fixed', bottom: '0', width:'200px'}">
          <a-menu-item @click="showBugModal">
            <a-icon type="search"/>
            <span>Found a bug?</span>
          </a-menu-item>
          <a-menu-item>
            <router-link :to="{name: 'edit-user', params: { user_id: this.getUserInfor._id }}">
              <a-icon type="user"/>
              {{ getUserInfor.user_name }}
            </router-link>
          </a-menu-item>
          <a-menu-item v-if=" this.getUserRole == this.$userRole.EXCUBATE_ADMIN">
            <router-link :to="{name: 'feedback-list'}">
              <a-icon type="unordered-list"/>
              <span>User Feedback/Bugs</span>
            </router-link>
          </a-menu-item>
          <a-menu-item v-if=" this.getUserRole == this.$userRole.EXCUBATE_ADMIN">
            <router-link :to="{name: 'user-list'}">
              <a-icon type="usergroup-add"/>
              <span>User Management</span>
            </router-link>
          </a-menu-item>
          <a-menu-item @click="logout">
            <a-icon type="logout"/>
            <span>Log Out</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout id="main" style="background: #F7F9FC">
        <a-layout-content style="margin: 24px 16px; padding: 24px; minHeight: auto;">
          <router-view/>
        </a-layout-content>
        <a-layout-footer style="textAlign: center">
          <p>
            We use cookies to improve your website experience. By using this website, you agree to our privacy policy.
            <br/>©2023 Excubate
          </p>
        </a-layout-footer>
      </a-layout>
    </a-layout>

    <submit-bug-modal
        ref="submit-bug-modal"
        :visible="bugModalVisible"
    />

    <session-completed-pop ref="sessionCompletedPop"/>
  </a-layout>
</template>

<script>
import SubmitBugModal from "../components/SubmitBugModal";
import SessionCompletedPop from '../components/SessionCompletedPop'
import { mapGetters, mapMutations, mapState } from 'vuex'
import numeral from 'numeral'

export default {
  name: 'Main',

  components: { SubmitBugModal, SessionCompletedPop },

  data() {
    return {
      bugModalVisible: false,
      collapsed: false,
      navStack: [],
      navStackIcon: {
        "client-list": "idcard",
        "client-portfolio-list": "database",
        "portfolio-project-list": "project",
        "dto-portfolio-list": "project",
        "dpo-project-landing-page": "project",
        "dpo-project-list": "project",
        "portfolio-heatmap": "pie-chart",
        "dpo-projects-assement": "pie-chart",
        "dpo-projects-assement-view": "pie-chart",
        'dpo-projects-assement-canvas': "pie-chart",
        'dpo-projects-assement-attach': "pie-chart",
        "portfolio-target-state": "pie-chart",
        "portfolio-delta": "pie-chart"
      }
    };
  },

  created() {
    numeral.register('locale', 'de', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T'
      },
      currency: {
        symbol: '€'
      }
    });
    numeral.locale('de');
  },

  mounted() {
    window.onstorage = (event) => {
      if (event.key === 'token' || event.key === null) {
        this.$refs.sessionCompletedPop.handleKnowMore();
      }
    };
  },

  computed: {
    ...mapState(['selectedClientId']),
    ...mapGetters(['getUserInfor', 'getUserRole', 'getNavStack']),
  },

  methods: {
    ...mapMutations(['updateNavStack']),

    showBugModal() {
      this.$refs["submit-bug-modal"].visible = true;
    },

    logout() {
      localStorage.clear()
      this.$router.replace({ name: 'login' })
    },

    navigationMenu(to, from) {
      let navStack = this.getNavStack;

      if (to.name == "user-list") {
        navStack = [];
        navStack.push({
          title: "Client Overview",
          route: { name: "client-list" }
        });
      } else if (
          to.name == "edit-user" ||
          to.name == "add-user"
      ) {
        navStack = [];
      } else if (to.name == "client-list") {
        navStack = [];

        navStack.push({
          title: "Client Overview",
          route: { name: "client-list" }
        });
      } else if (to.name == "client-portfolio-list") {
        navStack = [];

        navStack.push({
          title: "Client Overview",
          route: { name: "client-list" }
        });
        this.$http.get("/admin/api/rest/client/" + to.params.client_id).then(res => {
          navStack.push({
            title: res.data["client_name"],
            route: { name: to.name, params: to.params }
          });
        });
      } else if (to.name == "dto-portfolio-list") {
        navStack = [];

        this.$http.get("/admin/api/my-client").then(res => {
          navStack.push({
            title: res.data[0]["client_name"] + " Portfolio",
            route: { name: to.name, params: to.params }
          });
        });
      } else if (
          to.name == "portfolio-heatmap" ||
          to.name == "portfolio-target-state" ||
          to.name == "portfolio-delta"
      ) {
        navStack = [];

        if (
            [
              this.$userRole.EXCUBATE_ADMIN,
              this.$userRole.EXCUBATE_EMPLOYEE,
              this.$userRole.CLIENT_DTO
            ].includes(this.getUserRole)
        )
          navStack.push({
            title: "Client Overview",
            route: { name: "client-list" }
          });
        this.$http
            .get("/admin/api/rest/portfolio/" + to.params.portfolio_id)
            .then(res => {
              if (
                  [
                    this.$userRole.EXCUBATE_ADMIN,
                    this.$userRole.EXCUBATE_EMPLOYEE,
                    this.$userRole.CLIENT_DTO
                  ].includes(this.getUserRole)
              ) {
                navStack.push({
                  title: res.data.client_id["client_name"],
                  route: {
                    name: "client-portfolio-list",
                    params: { client_id: res.data.client_id["_id"] }
                  }
                });
              } else {
                navStack.push({
                  title: res.data.client_id["client_name"],
                  route: {
                    name: "dto-portfolio-list"
                  }
                });
              }
              if (to.name == "portfolio-heatmap") {
                navStack.push({
                  // title: res.data["portfolio_name"] + " Heatmap",
                  title: "Portfolio Heatmap",
                  route: { name: to.name, params: to.params }
                });
              } else if (to.name == "portfolio-target-state") {
                navStack.push({
                  // title: res.data["portfolio_name"] + " Define Target State",
                  title: "Porfolio Define Target State",
                  route: { name: to.name, params: to.params }
                });
              } else if (to.name == "portfolio-delta") {
                navStack.push({
                  // title: res.data["portfolio_name"] + " Determine Delta",
                  title: "Portfolio Determine Delta",
                  route: { name: to.name, params: to.params }
                });
              }
            });
      } else if (to.name == "portfolio-project-list") {
        navStack = [];

        if (
            [
              this.$userRole.EXCUBATE_ADMIN,
              this.$userRole.EXCUBATE_EMPLOYEE,
              this.$userRole.CLIENT_DTO
            ].includes(this.getUserRole)
        )
          navStack.push({
            title: "Client Overview",
            route: { name: "client-list" }
          });

        this.$http
            .get("/admin/api/rest/portfolio/" + to.params.portfolio_id)
            .then(res => {
              if (
                  [
                    this.$userRole.EXCUBATE_ADMIN,
                    this.$userRole.EXCUBATE_EMPLOYEE,
                    this.$userRole.CLIENT_DTO
                  ].includes(this.getUserRole)
              ) {
                navStack.push({
                  title: res.data.client_id["client_name"],
                  route: {
                    name: "client-portfolio-list",
                    params: { client_id: res.data.client_id["_id"] }
                  }
                });
              } else {
                navStack.push({
                  title: res.data.client_id["client_name"],
                  route: {
                    name: "dto-portfolio-list"
                  }
                });
              }
              navStack.push({
                // title: res.data.portfolio_name + " Project",
                title: "Portfolio Overview",
                route: { name: to.name, params: to.params }
              });
            });
      } else if (
          to.name == "dpo-projects-assement-view" ||
          to.name == "dpo-projects-assement" ||
          to.name == 'dpo-projects-assement-canvas' ||
          to.name == 'dpo-projects-assement-attach'
      ) {
        navStack = [];
        if ([this.$userRole.DPO].includes(this.getUserRole)) {
          navStack.push({
            title: "Digital Value Canvas Introduction",
            route: { name: "dpo-project-landing-page" }
          });
          navStack.push({
            title: "My Project",
            route: { name: "dpo-project-list" }
          });
          navStack.push({
            title: "Digital Value Canvas",
            route: { name: to.name, params: to.params }
          });
        } else {
          if (
              [
                this.$userRole.EXCUBATE_ADMIN,
                this.$userRole.EXCUBATE_EMPLOYEE,
                this.$userRole.CLIENT_DTO
              ].includes(this.getUserRole)
          )
            navStack.push({
              title: "Client Overview",
              route: { name: "client-list" }
            });

          this.$http.get("/admin/api/rest/project/" + to.params.project_id)
              .then(resProject => {
                if (
                    [
                      this.$userRole.EXCUBATE_ADMIN,
                      this.$userRole.EXCUBATE_EMPLOYEE,
                      this.$userRole.CLIENT_DTO
                    ].includes(this.getUserRole)
                ) {
                  navStack.push({
                    title: resProject.data.client_id["client_name"] + " Overview",
                    route: {
                      name: "client-portfolio-list",
                      params: { client_id: resProject.data.client_id._id }
                    }
                  });
                } else {
                  navStack.push({
                    title:
                        resProject.data.client_id.client_name + " Portfolio",
                    route: {
                      name: "dto-portfolio-list"
                    }
                  });
                }

                if (from.name == "portfolio-heatmap") {
                  let portfolio_id
                  if (this.$route.params?.portfolio_id) {
                    portfolio_id = this.$route.params.portfolio_id
                  } else {
                    portfolio_id = resProject.data.portfolio_ids[0]._id
                  }
                  navStack.push({
                    // title: resProject.data.portfolio_id["portfolio_name"] + " Heatmap",
                    title: "Portfolio Heatmap",
                    route: {
                      name: "portfolio-heatmap",
                      params: { portfolio_id }
                    }
                  });
                } else {
                  let portfolio_id = resProject.data.portfolio_ids[0]._id

                  if (this.$route.params?.portfolio_id) {
                    portfolio_id = this.$route.params.portfolio_id
                  }

                  navStack.push({
                    // title: resProject.data.portfolio_id["portfolio_name"] + " Project",
                    title: "Portfolio Overview",
                    route: {
                      name: "portfolio-project-list",
                      params: { portfolio_id }
                    }
                  });
                }

                navStack.push({
                  // title: resProject.data["project_name"] + " Digital Value Canvas",
                  title: "Digital Value Canvas",
                  route: { name: to.name, params: to.params }
                });
              });
        }
      } else if (to.name == "dvc-introduction") {
        navStack = [];
        navStack.push({
          title: "Digital Value Canvas Introduction",
          route: { name: "dvc-introduction" }
        });
      } else if (to.name == "dpo-project-landing-page") {
        navStack = [];
        navStack.push({
          title: "Digital Value Canvas Introduction",
          route: { name: "dpo-project-landing-page" }
        });
      } else if (to.name == "dpo-project-list") {
        navStack = [];
        navStack.push({
          title: "Digital Value Canvas Introduction",
          route: { name: "dpo-project-landing-page" }
        });
        navStack.push({
          title: "My Project",
          route: { name: to.name, params: to.params }
        });
      }

      this.updateNavStack(navStack);
      this.navStack = this.getNavStack;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.navigationMenu(to, from))
  },
  beforeRouteUpdate(to, from, next) {
    this.navigationMenu(to, from);
    next();
  }
};
</script>

<style>
ul.menu-tree {
  padding-left: 0;
}

ul.menu-tree li {
  list-style: none;
  padding: 0 16px;
  margin: 4px 0;
  background-color: #75c845;
  min-height: 40px;
}

ul.menu-tree li a {
  color: #ffffff;
  min-height: 40px;
  display: flex;
  align-items: center;
}

ul.menu-tree li a span {
  margin-left: 10px;
}

#app .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 61px;
  background: url("../assets/excubate-logo.png") no-repeat;
  margin: 16px;
}

#components-layout-demo-custom-trigger .ant-layout-footer {
  padding: 10px;
}

.user_login {
  position: fixed;
  bottom: 0;
  color: white;
  width: 200px;
  height: 48px;
  line-height: 48px;
}

.user_login span {
  margin-left: 30px;
}

.ant-btn-danger:focus,
.ant-btn-danger:hover {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
</style>
