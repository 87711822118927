<template>
  <div class="project__previous">
    <PointsEuroToggle
        @changeEuroActive="changeEuroActive"
    />
    <a-select class="project__previous__select"
              v-model="projectVersion"
              @change="getCanvasProjectVersion"
    >
      <a-select-option class="project__previous__select-option"
                       v-for="(version, index) in projectVersions"
                       :key="index"
                       :value="index"
      >
        {{ version.date }}
      </a-select-option>
    </a-select>
    <div class="project__previous__item">
      <h2 class="project__previous__item-title">Current canvas</h2>
      <div class="project__previous__item__content">
        <a-icon class="project__previous__item__loading"
                v-if="loadingCurrent"
                type="loading"
        />
        <EchartsDoughnutChart class="project__previous__item__echarts"
                              v-show="!loadingCurrent"
                              ref="current-canvas"
                              :canvasSegments="current_canvasSegments"
        />
      </div>
    </div>
    <div class="project__previous__item">
      <h2 class="project__previous__item-title">Updates</h2>
      <div class="project__previous__item__content">
        <a-icon class="project__previous__item__loading"
                v-if="loadingUpdates"
                type="loading"
        />
        <EchartsDoughnutChart class="project__previous__item__echarts"
                              v-show="!loadingUpdates"
                              ref="updates-canvas"
                              :canvasSegments="updates_canvasSegments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PointsEuroToggle from '../../components/MetaData/PointsEuroToggle'
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart"
import { canvasSegments, canvasColors } from "../../staticData/CanvasLabels"
import Color from "color"
import { cloneDeep } from "lodash/lang"
import moment from 'moment'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import sector_meta_data from '../../staticData/SectorMetaData.js'
import meta_data from '../../staticData/MetaData.js'

export default {
  name: 'ProjectPrevious',
  components: {
    EchartsDoughnutChart,
    PointsEuroToggle
  },
  data() {
    return {
      isEdit: false,
      project_id: "",
      selectedSegment: {},
      tagsGroup: [],
      canvasSegments,
      current_canvasSegments: {},
      updates_canvasSegments: {},
      loadingCurrent: false,
      loadingUpdates: false,
      finishCanvas: false,
      projectInfor: {
        client_id: { client_name: "", logo: "" },
        portfolio_id: { portfolio_name: "" },
        project_name: ""
      },
      segmentColors: {},
      projectVersion: 0,
      projectVersions: [],
      sector_meta_data,
      meta_data
    };
  },
  computed: {
    ...mapGetters({
      getEuroActive: 'getEuroActive'
    })
  },
  async created() {
    this.project_id =
        this.$route.params.project_id || "5e6896afa8bf5e550fd937a7";
    if (this.$route.name == "dpo-projects-assement-canvas-view") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
    this.initData();
    await this.loadData();
  },
  methods: {
    changeEuroActive() {
      this.loadingCurrent = true
      this.loadingUpdates = true

      this.renderSegments(this.current_canvasSegments)
      this.renderSegments(this.updates_canvasSegments)

      this.$refs?.['current-canvas'].updatedData()
      this.$refs?.['updates-canvas'].updatedData()
      this.loadingCurrent = false
      this.loadingUpdates = false
    },
    initData() {
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value.point = 0;
          value.metrics = "";
          value.explanation = "";
          value._id = null;
          value.itemStyle = null;
          value.sector_meta_data = null;
          value.equity_impact = '';
        }
      }
      this.current_canvasSegments = cloneDeep(this.canvasSegments)
      this.updates_canvasSegments = cloneDeep(this.canvasSegments)
    },
    initSegmentColor() {
      this.segmentColors = {};
      if (this.projectInfor.client_id["color_code"]) {
        const colorTheme = Color(
            this.projectInfor.client_id["color_code"].trim()
        );
        for (let i = 0; i <= 20; i++) {
          this.segmentColors[i] =
              colorTheme.lighten(0.8 - (0.8 / 21) * (i + 1)).hex();
        }
      } else {
        this.segmentColors = canvasColors;
      }


    },
    async loadData() {
      this.loadingCurrent = true
      this.loadingUpdates = true

      try {
        let response = await this.$http.get("/admin/api/rest/project/" + this.project_id)
        this.projectInfor = response.data;
        this.initSegmentColor();

        //Get Meta Data Client
        response = await this.$http.get(`/metadata/clients/${this.projectInfor.client_id._id}`)

        for (const catalog of this.meta_data) {
          for (const item of catalog.data) {
            let value = parseFloat(response.data[item.key])
            if (!value) {
              value = 0
            }
            if (item.prefix.includes('%')) {
              this.$store.state.meta_data[item.key] = value / 100
            } else {
              this.$store.state.meta_data[item.key] = value
            }
          }
        }

        //Get Meta Data Project
        response = await this.$http.get(`/metadata/projects/${this.project_id}`)

        for (const catalog of this.meta_data) {
          for (const item of catalog.data) {
            if (response.data[item.key]) {
              let value = parseFloat(response.data[item.key])
              if (!value) {
                value = 0
              }
              if (item.prefix.includes('%')) {
                this.$store.state.meta_data[item.key] = value / 100
              } else {
                this.$store.state.meta_data[item.key] = value
              }
            }
          }
        }

        response = await this.$http.get("/admin/api/rest/canvas/project/" + this.project_id)
        if (response.data) {
          for (const data of response.data) {
            for (const item of this.current_canvasSegments[data.series_name]) {
              if (item.data_index === data.data_index) {
                if (data?.explanation) {
                  item.explanation = data.explanation
                }
                if (data?.metrics) {
                  item.metrics = data.metrics
                }
                if (data?.point) {
                  item.point = data.point
                }
                if (data?._id) {
                  item._id = data._id
                }
                if (data?.sector_meta_data) {
                  item.sector_meta_data = data.sector_meta_data
                }
              }
            }
          }
          this.calculatedSectorMetaData(this.current_canvasSegments);
          this.changeEuroActive();
        }

        response = await this.$http.get(`/canvas/projects/${this.project_id}/history`);
        if (response.data.length) {
          this.projectVersions = response.data.map(item => {
            item.date = moment(item.date).format('DD/MM/YYYY - HH:mm:ss')
            return item;
          });
          this.projectVersions[0].date = moment().format('DD/MM/YYYY - HH:mm:ss');

          await this.getCanvasProjectVersion();
        } else {
          this.projectVersion = null;
          this.$message.error("No Project Versions!");
        }
      } catch (error) {
        this.$message.error(error?.response?.data.message);
      }

      this.loadingCurrent = false
      this.loadingUpdates = false
    },
    async getCanvasProjectVersion() {
      this.loadingUpdates = true
      for (const key in this.updates_canvasSegments) {
        for (const item of this.updates_canvasSegments[key]) {
          item.metrics = ''
          item.explanation = ''
          item.point = 0,
              item.equity_impact = '',
              item.sector_meta_data = null
        }
      }
      try {
        let response
        if (this.projectVersion !== 0) {
          response = await this.$http.get(`/canvas/projects/${this.project_id}/versions/${this.projectVersions[this.projectVersion].version}`)
          if (response.data) {
            for (const data of response.data) {
              for (const item of this.updates_canvasSegments[data.series_name]) {
                if (item.data_index === data.data_index) {
                  if (data?.explanation) {
                    item.explanation = data.explanation
                  }
                  if (data?.metrics) {
                    item.metrics = data.metrics
                  }
                  if (data?.point) {
                    item.point = data.point
                  }
                  if (data?._id) {
                    item._id = data._id
                  }
                  if (data?.sector_meta_data) {
                    item.sector_meta_data = data.sector_meta_data
                  }
                }
              }
            }
          }
        } else {
          response = await this.$http.get("/admin/api/rest/canvas/project/" + this.project_id)
          if (response.data) {
            for (const data of response.data) {
              for (const item of this.updates_canvasSegments[data.series_name]) {
                if (item.data_index === data.data_index) {
                  if (data?.explanation) {
                    item.explanation = data.explanation
                  }
                  if (data?.metrics) {
                    item.metrics = data.metrics
                  }
                  if (data?.point) {
                    item.point = data.point
                  }
                  if (data?._id) {
                    item._id = data._id
                  }
                  if (data?.sector_meta_data) {
                    item.sector_meta_data = data.sector_meta_data
                  }
                }
              }
            }
          }
        }

        this.calculatedSectorMetaData(this.updates_canvasSegments)
        this.changeEuroActive();

      } catch (error) {

        this.$message.error(error?.response?.data.message);
      }
      this.loadingUpdates = false
    },
    calculatedSectorMetaData(canvasSegments) {
      for (const key in canvasSegments) {
        for (const Segment of canvasSegments[key]) {
          for (const sectorKey in this.sector_meta_data) {
            if (Segment.data_index === this.sector_meta_data[sectorKey].data_index) {
              for (const bodyItem of this.sector_meta_data[sectorKey].data.body) {
                for (const bodyItemKey in bodyItem.data) {
                  if (bodyItem.data[bodyItemKey].isEdit) {
                    let value = parseFloat(Segment.sector_meta_data?.[bodyItemKey])
                    if (!value) {
                      value = 0
                    }
                    if (bodyItem.data[bodyItemKey].prefix.includes('%')) {
                      this.$store.state.SectorMetaData[sectorKey].data[bodyItemKey] = value / 100
                    } else {
                      this.$store.state.SectorMetaData[sectorKey].data[bodyItemKey] = value
                    }
                  }
                }
              }

              break;
            }
          }
        }
      }

      for (const key in canvasSegments) {
        for (const Segment of canvasSegments[key]) {
          for (const sectorKey in this.sector_meta_data) {
            if (Segment.data_index === this.sector_meta_data[sectorKey].data_index) {
              if (parseFloat(this.$store.getters[`SectorMetaData/${sectorKey}__equity_impact`]) !== 0) {
                Segment.equity_impact = numeral(this.$store.getters[`SectorMetaData/${sectorKey}__equity_impact`]).format('$ -0.[0]a')
              }
            }
          }
        }
      }
    },
    renderSegments(canvasSegments) {
      let ArrayEquity_impact = []
      let SymmEquity_impact = 0;

      for (const key in canvasSegments) {
        for (const segment of canvasSegments[key]) {
          if (this.getEuroActive) {
            if (numeral(segment.equity_impact).value() !== null) {
              let value = Math.abs(numeral(segment.equity_impact).value())
              SymmEquity_impact += value
              ArrayEquity_impact.push({
                value: value,
                segment: segment
              })
            } else {
              segment.itemStyle = null;
            }
          } else {
            if (segment.point > 0) {
              segment.itemStyle = {
                  color: this.segmentColors[Math.ceil(segment.point / 5)]
              }
            } else {
              segment.itemStyle = null;
            }
          }
        }
      }

      for (const item of ArrayEquity_impact) {
        const color = this.segmentColors[Math.ceil(item.value / SymmEquity_impact * 20)]
        if (color) {
          item.segment.itemStyle = {
              color: color
          }
        } else {
          item.segment.itemStyle = null
        }
      }
    }
  }
};
</script>

<style lang="less">
/**
 * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
 * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
 */
.project__previous {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  &__select {
    position: absolute;
    right: 0;
    top: 0;
    width: 205px;
    height: 40px;

    &-option {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.16px;
      line-height: 40px;
      color: rgb(82, 82, 82);
      padding: 0 15px;

      &[aria-selected="true"] {
        background-color: rgb(233, 236, 244);
        font-weight: 500;
      }

      &:hover {
        background-color: rgb(247, 255, 240) !important;
      }
    }

    & .ant-select-selection {
      width: 100%;
      height: 100%;

      & .ant-select-selection__rendered {
        line-height: 40px;

        & .ant-select-selection-selected-value {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.15px;
          color: rgb(82, 82, 82);
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &:first-of-type {
      margin-right: 25px;
    }

    &-title {
      width: 100%;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: rgb(57, 62, 70);
      margin-bottom: 15px;
    }

    &__content {
      position: relative;
      background-color: white;
      padding: 25px;
      border-radius: 10px;
      flex-grow: 1;
    }

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      width: 480px;
      height: 555px;

      & svg {
        width: 100px;
        height: 100px;
      }
    }

    &__echarts {
      flex-grow: 1;
      width: 600px;
      height: 600px;
    }
  }
}

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
