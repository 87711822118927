<template>
  <div class="limit-access-label" v-if="limitAccess">
    PRO
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LimitAccess',
  computed: {
    ...mapGetters({
      getUserInfor: "getUserInfor"
    }),
    limitAccess() {
      if (this.getUserInfor?.limited_access === true) {
        return true
      }
      return false
    }
  }
};
</script>

<style lang="less">
.limit-access-label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13px;
  width: 60px;
  background-color: rgb(0, 102, 194);
  color: white;
  font-size: 9.9px;
  font-family: "Roboto";
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 5px;
  right: -20px;
  transform: rotate(45deg);
}
</style>
