<template>
  <div class="ant-modal-content" v-show="visible" style="width: 420px;">
    <button type="button" aria-label="Close" class="ant-modal-close" @click="hideForm">
      <span class="ant-modal-close-x">
        <a-icon type="close"/>
      </span>
    </button>
    <div class="ant-modal-header">
      <div class="ant-modal-title">{{ this.modalData.title }}</div>
    </div>
    <div class="ant-modal-body">
      <div v-html="modalData.introduction"></div>
    </div>
    <div class="ant-modal-footer">
      <a-button
          type="primary"
          @click="hideForm"
      >Ok
      </a-button>
    </div>
  </div>
</template>

<style scoped>
.ant-modal-title {
  color: white;
}

.ant-modal-close {
  color: white;
}

</style>
<script>
export default {
  props: {
    visible: Boolean,
    modalData: Object,
  },
  data() {
    return {};
  },
  beforeCreate() {
  },

  methods: {

    handleSubmit() {
      this.hideForm();
    },
    hideForm() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = false;
      this.$emit("hide");
    },
    showForm() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = true;

    }

  }
};
</script>
