<template>
  <div class="verified">
    <div class="verified__logo">
      <a href="https://www.excubate.de"
         target="_blank"
      >
        <img class="verified__logo-img"
             src="../../assets/excubate-logo-full-high-resolution-min.png"
        >
      </a>
    </div>
    <div class="verified__content">
      <a-result class="verified__content-success"
                status="success"
                title="Verified"
                sub-title="You have successfully verified the account"
      >
        <template #extra>
          <a-button class="verified__content-button"
                    type="primary"
                    @click="toStarted"
          >
            Get Started
          </a-button>
        </template>
      </a-result>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RegisterVerified',
  mounted() {
    if (this.$route.query?.token) {
      const postValues = {
        verification_token: this.$route.query.token
      }
      this.$http
          .post("/auth/verify", postValues)
          .then(() => {
            // handle success
          })
          .catch(() => {
            // handle error
            this.$router.push({ name: "login" });
          });
    } else {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    toStarted() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="less">
.verified {
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 120px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__logo {
    height: 100px;
    width: 300px;
    margin-bottom: 25px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    width: 535px;
    min-height: 373px;
    height: max-content;
    border: 1px solid #001529;
    border-radius: 5px;
    padding: 25px 70px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 2 10 0 rgba(188, 202, 224, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    &-button {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.583333px;
      line-height: 20px;
      width: 100%;
    }

    &-success {
      padding: 0;
      width: 100%;
    }
  }
}
</style>
