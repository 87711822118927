<template>
  <a-modal title="Your changes will be not saved"
           class="meta-data__dont-save"
           v-model="visible"
           on-ok="handleOk"
           on-cancel="handleCancel"
  >
    <template slot="footer">
      <a-button class="meta-data__dont-save__footer-btn
        meta-data__dont-save__footer-cancel-btn"
                type="default"
                @click="handleCancel"
      >
        СANCEL
      </a-button>
      <a-button class="meta-data__dont-save__footer-btn
        meta-data__dont-save__footer-btn--active"
                type="primary"
                @click="handleOk"
      >
        DONT SAVE
      </a-button>
    </template>
    <div class="meta-data__dont-save__content">
      Do you really want to leave this page without saving?
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'MetaDataPopDontSave',
  data() {
    return {
      visible: false,
      toPage: ''
    };
  },
  methods: {
    handleKnowMore(toPage) {
      this.visible = true
      this.toPage = toPage
    },
    handleOk() {
      this.visible = false
      this.$emit('dontSave')
      if (this.toPage === '-1') {
        this.$router.go(-1)
      } else {
        this.$router.push({
          name: this.toPage.name,
          params: this.toPage.params
        })
      }
    },
    handleCancel() {
      this.visible = false
    },
  }
};
</script>

<style lang="less">
.meta-data__dont-save {
  background-color: white;
  width: max-content;
  height: max-content;

  & .ant-modal {
    width: 600px !important;
    height: max-content !important;
  }

  & .ant-modal-header {
    background-color: white;
    border: none;

    & .ant-modal-title {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      text-align: left;
    }
  }

  & .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 28px;
    border: none;
  }

  & .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    width: 100%;
    height: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
  }

  &__footer {
    &-cancel-btn {
      border: none;
      color: black !important;

      &:hover {
        color: #75c845 !important;
      }
    }

    &-btn {
      height: 36px !important;
      padding: 10px 45px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;

      &--active {
        border-color: #E22639;
        background-color: #E22639;

        &:hover {
          border-color: saturate(#E22639, 40%) !important;
          background-color: saturate(#E22639, 40%) !important;
        }

        &:focus {
          border-color: saturate(#E22639, 40%) !important;
          background-color: saturate(#E22639, 40%) !important;
        }
      }
    }
  }
}
</style>
