<template>
  <div>
    <a-row type="flex" justify="space-around" align="top">
      <a-col :span="12">
        <div style="width:100%; height: 100%">
          <echarts-doughnut-chart
              :canvasSegments="canvasSegments"
              v-on:onClickSegment="onClickSegment"
          ></echarts-doughnut-chart>
        </div>
      </a-col>
      <a-col :span="12">
        <canvas-segment-modal
            :modalData="modalData"
            :visible="visible"
            @submit="handleSubmit"
            :confirmLoading="confirmLoading"
            @cancel="handleCancel"
        >
          <p>{{ modalData.text }}</p>
        </canvas-segment-modal>
        <div class="card-container" v-show="false">
          <a-tabs type="card">
            <a-tab-pane
                :tab="`Summary ${modalData.totalAssignedPoint>0 ? '('+modalData.totalAssignedPoint+')': ''}`"
                key="summary"
            >
              <p>
                Digital Value Canvas Summary
                <br/>[Description]
              </p>
            </a-tab-pane>
            <a-tab-pane
                v-for="tag in tagsGroup"
                :tab="`${tag.name} (${tag.point})`"
                :key="tag.data_index"
            >
              <div v-html="segmentDescription(tag.point, tag.explanation, tag.metrics)"></div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import DoughnutChart from "../components/DoughnutChart"
import EchartsDoughnutChart from "../components/EchartsDoughnutChart";
import CanvasSegmentModal from "../components/CanvasSegmentModal";
import { canvasSegments } from "../staticData/CanvasLabels";

export default {
  components: {
    EchartsDoughnutChart,
    CanvasSegmentModal
  },
  data() {
    return {
      project_id: "5e5f169e9a7f1b5190231191",
      selectedSegment: {},
      tagsGroup: [],
      canvasSegments,
      modalData: {
        title: "Awesome!!",
        point: "",
        metrics: "",
        explanation: "",
        totalAssignedPoint: 0
      },
      visible: false,
      confirmLoading: false
    };
  },
  async created() {
    await this.loadData();
  },
  mounted() {
    // this.fillData();
  },
  methods: {
    onClickSegment(...args) {
      this.selectedSegment = args[0][0];
      // this.modalData.title = this.dataLabels[ele._datasetIndex][ele._index];
      this.modalData.title = this.selectedSegment.data.name;
      this.modalData.point = this.selectedSegment.data.point;
      this.modalData.metrics = this.selectedSegment.data.metrics;
      this.modalData.explanation = this.selectedSegment.data.explanation;
      this.showModal();
    },
    showModal() {
      this.visible = true;
    },
    handleSubmit(formValues) {
      this.confirmLoading = true;

      setTimeout(() => {
        const segment = this.canvasSegments[
            this.selectedSegment.seriesName
            ].find(
            value => value.data_index == this.selectedSegment.data.data_index
        );
        segment.point = formValues.point;
        segment.explanation = formValues.explanation;
        segment.metrics = formValues.metrics;

        //post data
        this.submitData(segment);
        //update segment item style
        this.updateSegmentStyle(segment);
        //update tags
        this.updateTags();

        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {

      this.visible = false;
    },
    segmentDescription(score, explanation, metrics) {
      let template = "";
      if (score) {
        template += `
          <h3>Points Awarded:</h3><p>
          ${score}
          </p>`;
      }

      if (explanation) {
        template += `
          <h3>Explanation for score:</h3><p>
          ${explanation.replace(/\n/g, "<br />")}
          </p>`;
      }

      if (metrics) {
        template += `
          <h3>Relative metrics:</h3>
          <p>
          ${metrics}
          </p>`;
      }
      return template;
    },
    async loadData() {
      this.$http
          .get("/admin/api/rest/canvas/project/" + this.project_id)
          .then(response => {
            const segmentsData = response.data;
            if (segmentsData) {
              segmentsData.map(data => {
                const replaceData = this.canvasSegments[data.series_name].find(
                    value => value.data_index === data.data_index
                );
                replaceData.point = data.point;
                replaceData.metrics = data.metrics;
                replaceData.explanation = data.explanation;
                replaceData._id = data._id;
                this.updateSegmentStyle(replaceData);
              });
              this.updateTags();
            }
          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          });
    },
    submitData(segment) {
      const data = {
        project: this.project_id,
        series_name: this.selectedSegment.seriesName,
        data_index: segment.data_index,
        metrics: segment.metrics,
        explanation: segment.explanation,
        point: segment.point
      };

      //update segment data on server
      if (segment._id) {
        this.$http
            .put("/admin/api/rest/canvas/" + segment._id, data)
            .then(() => {
              this.$message.success("Update Success.");
            })
            .catch(error => {
              this.$message.error(error.response.data.message);
            });
      } else {
        //create segment data on server
        this.$http
            .post("/admin/api/rest/canvas", data)
            .then(response => {
              this.$message.success("Save Success.");
              segment._id = response.data._id;

            })
            .catch(error => {
              this.$message.error(error.response.data.message);
            });
      }
    },
    updateSegmentStyle(segment) {
      let itemColor = false;
      if (segment.point >= 20) {
        itemColor = "#498525";
      } else if (segment.point >= 10) {
        itemColor = "#75C945";
      } else if (segment.point > 0) {
        itemColor = "#ABDF8F";
      } else {
        segment.itemStyle = null;
      }

      if (itemColor) segment.itemStyle = {  color: itemColor  };
    },
    updateTags() {
      this.tagsGroup = [
        ...this.canvasSegments["direct"],
        ...this.canvasSegments["indirect"],
        ...this.canvasSegments["goodway"]
      ].filter(segment => segment.point > 0);

      this.modalData.totalAssignedPoint = this.tagsGroup.reduce(
          (prev, curr) => prev + parseInt(curr.point),
          0
      );
    }
  }
};
</script>

<style>
/**
 * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
 * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
 */

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
