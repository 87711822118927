<template>
  <div class="forgot-password__verified">
    <div class="forgot-password__verified__logo">
      <a href="https://www.excubate.de"
         target="_blank"
      >
        <img class="forgot-password__verified__logo-img"
             src="../../assets/excubate-logo-full-high-resolution-min.png"
        >
      </a>
    </div>
    <div class="forgot-password__verified__content">
      <a-form
          id="components__form-demo-normal__forgot-password__verified"
          :form="form"
          class="forgot-password__verified__form"
          @submit.prevent="handleSubmit"
      >
        <a-form-item>
          <p class="forgot-password__verified__form-title">
            Create new password
          </p>
        </a-form-item>
        <a-form-item>
          <a-input-password
              v-decorator="[
              'password',
              {
                rules: [
                  {
                    whitespace: true,
                    required: true,
                    message: 'You need to enter a password.'
                  },
                ]
              }
            ]"
              placeholder="Password"
          >
            <a-icon slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25)"
            />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-input-password
              v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    whitespace: true,
                    required: true,
                    message: 'You need to confirm your password.'
                  },
                  {
                    validator: compareToFirstPassword
                  },
                ]
              }
            ]"
              placeholder="Confirm password"
          >
            <a-icon slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25)"
            />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button class="forgot-password__verified__form-button"
                    type="primary"
                    html-type="submit"
                    :loading="submitLoading"
          >
            Create
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgotPasswordVerified',
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_reset-password' });
  },
  mounted() {
    if (this.$route.query?.token) {
      this.$http
          .get(`/auth/reset-password/token-status/${this.$route.query.token}`)
          .then((response) => {
            // handle success
            this.$message.success(response.data.message)
          })
          .catch((error) => {
            // handle error
            this.$message.error(error.response.data.message)
            this.$router.push({ name: "login" });
          })
    } else {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('You need to confirm your password.');
      } else {
        callback();
      }
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitLoading = true;
          const postValues = {
            token: this.$route.query.token,
            password: values.password
          }
          this.$http
              .post("/auth/reset-password", postValues)
              .then(() => {
                // handle success
                this.$message.success('Success Forgot Password');
                this.$router.push({ name: "login" });
              })
              .catch(error => {
                // handle error
                this.form.setFields({
                  password: {
                    value: values.password,
                    errors: [new Error(error.response.data.message)]
                  }
                })
              })
              .finally(() => {
                this.submitLoading = false;
              });
        }
      });
    }
  }
};
</script>

<style lang="less">
.forgot-password__verified {
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 120px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__logo {
    height: 100px;
    width: 300px;
    margin-bottom: 25px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    width: 535px;
    min-height: 373px;
    height: max-content;
    border: 1px solid #001529;
    border-radius: 5px;
    padding: 25px 70px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 2 10 0 rgba(188, 202, 224, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }

  &__form {
    width: 100%;
    min-height: 323px;
    height: max-content;

    & .ant-form-item:last-of-type {
      margin-top: 125px;
      margin-bottom: 35px;
    }

    &-content {
      width: 100%;
      height: 100%;
    }

    &-title {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      text-align: center;
      letter-spacing: 0.75px;
      margin: 0;
    }

    &-button {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.583333px;
      line-height: 20px;
      width: 100%;
    }
  }
}
</style>
