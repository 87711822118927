var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":'Report an issue to our admins',"confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"okText":"Submit","wrapClassName":'bug-modal'},on:{"cancel":_vm.hideBugModal,"ok":_vm.handleSubmit}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Subject"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'subject',
            {
              rules: [{ required: true, message: 'Required' }],
            }
          ]),expression:"[\n            'subject',\n            {\n              rules: [{ required: true, message: 'Required' }],\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Description the bug"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                  rules: [{ required: true, message: 'Required' }],
              }
            ]),expression:"[\n              'description',\n              {\n                  rules: [{ required: true, message: 'Required' }],\n              }\n            ]"}],attrs:{"type":"textarea","autosize":{ minRows: 3}}})],1),_c('a-form-item',{attrs:{"label":"Attach a screenshot"}},[_c('upload-image',{ref:"screenshot"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }