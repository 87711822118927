<template>
  <a-input
      :value="value"
      @change="change"
      @paste="pasteEvent"
      ref="inputItem"
  >
      <span class="project__canvas__segment__form__item__prefix"
            v-if="prefix"
            slot="prefix"
      >
        {{ prefix }}
      </span>
  </a-input>
</template>

<script>
import Inputmask from 'inputmask';

export default {
  name: "ProjectCanvasSegmentFormInputMask",
  props: {
    value: String,
    prefix: String
  },
  data() {
    return {
      inputElement: null,
    }
  },
  async mounted() {
    const element = this.$refs.inputItem.$el;
    this.inputElement = element.tagName.toLowerCase() === 'input' ? element : element.querySelector('.ant-input');

    if (this.inputElement) {
      let im;

      if (this.prefix === '€') {
        // Euro mask
        im = new Inputmask(undefined, {
          alias: 'numeric',
          placeholder: '0',
          digitsOptional: false,
          digits: 2,
          groupSeparator: '.',
          radixPoint: ',',
          rightAlign: false
        });
      } else {
        // Regular integer
        im = new Inputmask(undefined, {
          alias: 'numeric',
          placeholder: '0',
          digitsOptional: true,
          digits: 0,
          groupSeparator: '.',
          radixPoint: ',',
          rightAlign: false
        });
      }

      im.mask(this.inputElement);
    }
  },
  methods: {
    change(e) {
      this.$emit('change', e.target.value);
    },
    pasteEvent() {
      if (this.inputElement) {
        setTimeout(() => {
          this.inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        });
      }
    },
  },
}
</script>
