<template>
  <div class="portfolio__analysis">
    <PointsEuroToggle
        @changeEuroActive="changeEuroActive"
    />
    <div class="portfolio__analysis__echarts">
      <img class="portfolio__analysis__logo"
           :src="logoUrl"
           alt="logo"
      >
      <echarts-doughnut-chart
          ref="chart"
          :canvasSegments="canvasSegments"
          v-on:onClickSegment="onClickSegment"
      ></echarts-doughnut-chart>
    </div>
    <div class="portfolio__analysis__form">
      <heatmap-table
          ref="table"
          :visible="visible"
          :data="heatmapData"
          @hide="hideModal"
          @updateCanvas="updateCanvas"
      ></heatmap-table>
    </div>
  </div>
</template>

<script>
// import DoughnutChart from "../components/DoughnutChart"
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart"
import HeatmapTable from "../../components/HeatmapTable"
import PointsEuroToggle from '../../components/MetaData/PointsEuroToggle'
import { canvasSegments, canvasColors } from "../../staticData/CanvasLabels"
import Color from "color"
import { mapGetters } from 'vuex'

export default {
  name: 'PortfolioAnalysis',
  components: {
    EchartsDoughnutChart,
    HeatmapTable,
    PointsEuroToggle
  },
  data() {
    return {
      isEdit: false,
      portfolio_id: "",
      selectedSegment: {},
      canvasSegments,
      heatmapData: [],
      visible: false,
      confirmLoading: false,
      portfolioInfor: { client_id: { logo: "" } },
      titleName: "",
      showHeatMapButton: false,
      segmentColors: {},
      logoUrl: ''
    };
  },
  computed: {
    ...mapGetters({
      getEuroActive: 'getEuroActive'
    })
  },
  async created() {
    this.portfolio_id =
        this.$route.params.portfolio_id || "5e6896afa8bf5e550fd937a7";
    this.initData();
    await this.loadData();
    if (this.portfolioInfor?.client_id?.logo !== undefined && this.portfolioInfor?.client_id?.logo !== '') {

      this.logoUrl = this.portfolioInfor.client_id.logo
    } else {
      let { default: logoUrl } = await import('../../assets/excubate-logo.png');
      this.logoUrl = logoUrl
    }
  },
  methods: {
    changeEuroActive() {
    },
    onClickSegment(...args) {
      this.selectedSegment = args[0][0];
      const seletedSegmentIndex = this.selectedSegment.data.data_index;
      let segmentsData = [];
      this.heatmapData = [];
      this.portfolioInfor.project.map(project => {
        segmentsData = project.dvc;
        const replaceData = segmentsData.find(
            value => value.data_index == seletedSegmentIndex
        );
        if (replaceData) {
          this.heatmapData.push({
            key: project._id,
            project_name: project.project_name,
            point: replaceData.point,
            explanation: replaceData.explanation,
            metrics: replaceData.metrics
          });
        }
      });
      // this.modalData.title = this.dataLabels[ele._datasetIndex][ele._index];
      // this.modalData.title = this.selectedSegment.data.name;
      // this.modalData.point = this.selectedSegment.data.point;
      // this.modalData.metrics = this.selectedSegment.data.metrics;
      // this.modalData.explanation = this.selectedSegment.data.explanation;
      // this.$refs["form"].form.setFieldsValue(this.modalData);
      this.showModal();
    },

    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },

    segmentDescription(score, explanation, metrics) {
      let template = "";
      if (score) {
        template += `
          <h3>Points Awarded:</h3><p>
          ${score}
          </p>`;
      }

      if (explanation) {
        template += `
          <h3>Explanation for score:</h3><p>
          ${explanation.replace(/\n/g, "<br />")}
          </p>`;
      }

      if (metrics) {
        template += `
          <h3>Relative metrics:</h3>
          <p>
          ${metrics}
          </p>`;
      }
      return template;
    },
    initData() {
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          value.point = 0;
          value.metrics = "";
          value.explanation = "";
          value._id = null;
          value.itemStyle = null;
        }
      }
    },
    initSegmentColor() {
      this.segmentColors = {};
      if (this.portfolioInfor.client_id["color_code"]) {
        const colorTheme = Color(
            this.portfolioInfor.client_id["color_code"].trim()
        );
        for (let i = 0; i <= 20; i++) {
          this.segmentColors[i] =
              colorTheme.lighten(0.8 - (0.8 / 21) * (i + 1)).hex();
        }
      } else {
        this.segmentColors = canvasColors;
      }
    },
    async loadData() {
      this.$http
          .get("/admin/api/rest/canvas/heatmap/" + this.portfolio_id)
          .then(resonse => {
            this.portfolioInfor = resonse.data;
            this.titleName = this.portfolioInfor["portfolio_name"] + " Heatmap";
            //update logo of client on charts
            // this.$refs['chart'].$refs['echart'].options.graphic.elements[0].style.image = this.portfolioInfor.client_id['logo'];
            this.initSegmentColor();
            this.initHeatmapCanvasData();
          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          });
    },

    initHeatmapCanvasData() {
      this.showHeatMapButton = false;
      this.initData();
      let totalPoint = 0;
      let segmentsData = {};
      this.portfolioInfor.project.map(project => {
        segmentsData = project.dvc;
        segmentsData.map(data => {
          const replaceData = this.canvasSegments[data.series_name].find(
              value => value.data_index === data.data_index
          );

          totalPoint += parseInt(data.point) * parseInt(project.project_impact);

          replaceData.point =
              parseInt(replaceData.point) +
              parseInt(data.point) * parseInt(project.project_impact);

          replaceData.metrics = data.metrics;
          replaceData.explanation = data.explanation;
          this.updateSegmentStyle(replaceData);
        });
      });

      //canvas normed to 100
      if (totalPoint > 0) {
        for (const key of Object.keys(this.canvasSegments)) {
          for (const value of this.canvasSegments[key]) {
            value.point = parseInt(
                parseFloat((value.point / totalPoint).toFixed(2)) * 100
            );
            this.updateSegmentStyle(value);
          }
        }
      }
    },

    initProjectCavnasData(project_id) {
      //
      const projectDVC = this.portfolioInfor.project.find(
          value => value._id === project_id
      );
      this.$router.push({
        name: "dpo-projects-assement-view",
        params: { project_id, user_id: projectDVC.project_user }
      });

      this.showHeatMapButton = true;

      this.titleName = projectDVC["project_name"] + " Canvas";

      projectDVC.dvc.map(data => {
        const replaceData = this.canvasSegments[data.series_name].find(
            value => value.data_index === data.data_index
        );
        replaceData.point = data.point;
        replaceData.metrics = data.metrics;
        replaceData.explanation = data.explanation;
        replaceData._id = data._id;
        this.updateSegmentStyle(replaceData);
      });
    },
    updateSegmentStyle(segment) {
      let itemColor = false;
      if (segment.point > 100) {
        itemColor = this.segmentColors[20];
      } else if (segment.point > 0) {
        itemColor = this.segmentColors[Math.ceil(segment.point / 5)];
      } else {
        segment.itemStyle = null;
      }
      if (itemColor) segment.itemStyle = { color: itemColor };
    },

    updateCanvas(project_id) {
      this.initData();
      this.initProjectCavnasData(project_id);
    }
  }
};
</script>

<style lang="less">
.portfolio__analysis {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__logo {
    position: absolute;
    z-index: 3;
    top: 15px;
    left: 15px;
    width: 180px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__echarts {
    margin-right: 60px;
  }

  &__form {
    flex-grow: 1;
  }
}
</style>
