<template>
  <a-modal
      :visible="visible"
      :title="'Report an issue to our admins'"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      okText="Submit"
      @cancel="hideBugModal"
      @ok="handleSubmit"
      :wrapClassName="'bug-modal'"
  >
    <a-form layout="vertical" :form="form">
      <a-form-item label="Subject">
        <a-input
            v-decorator="[
              'subject',
              {
                rules: [{ required: true, message: 'Required' }],
              }
            ]"
        />
      </a-form-item>
      <a-form-item label="Description the bug">
        <a-input
            type="textarea"
            :autosize="{ minRows: 3}"
            v-decorator="[
                'description',
                {
                    rules: [{ required: true, message: 'Required' }],
                }
              ]"
        />
      </a-form-item>
      <a-form-item label="Attach a screenshot">
        <upload-image ref="screenshot"></upload-image>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style>
.bug-modal .ant-modal-header {
  background-color: white;
}
</style>
<script>
import UploadImage from "../components/UploadImage";

export default {
  components: {
    UploadImage
  },
  props: {
    visible: Boolean,
    confirmLoading: Boolean
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "form_in_modal" });
  },
  methods: {
    showBugModal() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = true;
    },
    hideBugModal() {
      // eslint-disable-next-line vue/no-mutating-props
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {

        if (!err) {
          values["screenshot"] = this.$refs["screenshot"].imageUrl;
          this.$http
              .post("/admin/api/rest/submitbug", values)
              .then(() => {
                this.hideBugModal();
                this.$message.success("Thanks for your submitted.");
              })
              .catch(err => {
                this.$message.error(err);
              });
        }
      });
    }
  }
};
</script>
