<template>
  <a-modal :title="title"
           class="register__pop-info"
           v-model="visible"
           on-ok="handleOk"
  >
    <template slot="footer">
      <a-button type="primary"
                @click="handleOk"
      >
        Ok
      </a-button>
    </template>
    <div class="register__pop-info-text">
      <slot>
        <h2>Privacy Policy</h2>
        <p>&nbsp;</p>
        <p>Excubate GmbH (hereinafter: “<strong>Excubate</strong>“) takes the protection of your personal data very
          seriously. We process your personal data exclusively in accordance with this data protection declaration and
          the relevant legal provisions, in particular the European General Data Protection Regulation (hereinafter:
          “<strong>GDPR</strong>“).</p>
        <p>&nbsp;</p>
        <p>The following data protection declaration relates to the processing of your personal data when using our
          website (hereinafter: “<strong>Website</strong>“).</p>
        <p>
          <strong style="margin-top: 30px;">1. Use of our offers<br>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p><strong>1.1 General information about the use of our website</strong></p>
        <p>&nbsp;</p>
        <p>When using our website, we process such personal data that your browser transmits to our server. This
          includes the IP address, date and time of the request, time zone difference to Greenwich Mean Time (GMT),
          content of the request (the specific page), access status or HTTP status code, the amount of data transferred,
          the website from which the Requirement comes, the browser used, the operating system and its interface as well
          as language and version of the browser software. Processing takes place on the basis of Art. 6 Para. 1 S. 1
          lit. f) GDPR. The processing of the data enables our website to be displayed and in particular also ensures
          its stability and security.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.2. Google-Analytics</strong></p>
        <p>&nbsp;</p>
        <p>On our websites we use Google Analytics with the extension “anonymizeIp”, a web analysis service from Google
          Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (hereinafter: “<strong>Google</strong>“). As a
          result, IP addresses are further processed by Google within the member states of the European Union or in
          other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the
          full IP address be sent to a Google server in the USA and shortened there. A personal reference can thus be
          excluded. If the data collected about you is personally identifiable, it will be excluded immediately and the
          personal data will be deleted immediately.</p>
        <p>&nbsp;</p>
        <p>The legal basis for our use of Google Analytics is Art. 6 Para. 1 S. 1 lit. a) GDPR. On our behalf, Google
          will use the data obtained to evaluate your use of our website, to compile reports on website activities and
          to provide us with other services related to website and internet use. The purpose of the processing is to
          analyze and optimize our website.</p>
        <p>&nbsp;</p>
        <p>You can prevent the storage of cookies by making the appropriate settings in your browser, for example by
          generally deactivating the automatic setting of cookies. In this case, functions of this website may no longer
          be able to be used correctly. In addition, you can prevent the collection of data relating to your use of the
          website (including your IP address) and its transmission to and processing by Google by downloading and
          installing the browser plug-in available under the following link:
          https://tools.google.com/dlpage/gaoptout?hl=de</p>
        <p>&nbsp;</p>
        <p>You can find more information on data collection and processing by Google in Google’s data protection
          information, which you can access at www.google.com/policies/info.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.3 Social media – Facebook, Twitter, Instagram, XING, LinkedIn</strong></p>
        <p>&nbsp;</p>
        <p>At various points on our website, we offer you the opportunity to visit our website on social networks such
          as Facebook, Twitter, Instagram, XING and LinkedIn. If you click on the respective logo or the name of a
          social network, you will be forwarded to our respective website via a link.</p>
        <p>&nbsp;</p>
        <p>Before you click on the respective logos or links, no personal data is transmitted to the social networks.
          The possibility that personal data is transmitted to and processed by the respective social network only
          exists from the time you click on the respective logo on our website and are forwarded to the website of the
          social network. Personal data is processed in particular if you are logged in with your respective social
          media account at the time of forwarding. In addition, however, data – such as your IP address – can also be
          processed if you do not have a social media account.</p>
        <p>&nbsp;</p>
        <p>We have no influence on the data collected and data processing operations, nor are we aware of the full scope
          of data collection, the purposes of processing, the storage periods. We also have no information on the
          deletion of the data collected by the respective social network.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.4 Newsletter</strong></p>
        <p>&nbsp;</p>
        <p>You can order our newsletter on our website and on various other occasions. Our newsletter contains special
          information about our range of services, additional service packages and current information in connection
          with our offer. All you need to register is your email address. You can also voluntarily provide further
          information, such as your name.</p>
        <p>&nbsp;</p>
        <p>Registration for the newsletter is based on the so-called double opt-in procedure. After entering your email
          address and, if applicable, your further data, we will send you an email to this address asking you to confirm
          your registration for the newsletter. Only when you confirm the registration by clicking on a corresponding
          link will you be added to our mailing list and will then receive our newsletter. If the registration is not
          confirmed within 24 hours, we will block your information and delete it after one month.</p>
        <p>&nbsp;</p>
        <p>When registering, we save the IP address you use, the time of registration and the time of confirmation of
          registration. This is done on the basis of Art. 6 para. 1 sentence 1 lit. a) GDPR and pursues the purpose of
          proving your registration and, if necessary, clarifying a possible misuse of your personal data.</p>
        <p>&nbsp;</p>
        <p>If the registration for the newsletter is confirmed, we will save your email address and, if applicable, your
          other voluntary information in accordance with Art. 6 Para. 1 S. 1 lit. a) GDPR in order to be able to send
          you our newsletter.</p>
        <p>&nbsp;</p>
        <p>You can revoke your consent to the sending of the newsletter at any time and unsubscribe from the newsletter.
          You can declare your revocation by clicking on the link provided in every newsletter email, by emailing
          innovate@excubate.de or by sending a message to the contact details given in the imprint.</p>
        <p>&nbsp;</p>
        <p>We would like to point out that when the newsletter is sent, we evaluate your user behavior in order to
          constantly optimize and improve our newsletter. For this evaluation, the emails sent contain so-called web
          beacons or tracking pixels. With the data obtained in this way, we create a user profile in order to tailor
          the newsletter to your individual interests. We record when you read our newsletters, which links you click on
          in them and deduce your personal interests.</p>
        <p>&nbsp;</p>
        <p>Processing takes place on the basis of Art. 6 Para. 1 S. 1 lit. a) GDPR and serves to optimize our services
          and our offer.</p>
        <p>You can prevent tracking with regard to opening our newsletter if you have deactivated the display of images
          in your email program by default. In this case, the newsletter will not be displayed in full and you may not
          be able to use all functions. If you display the images manually, the above-mentioned tracking is carried out
          completely.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.5 Contact via email or contact form</strong></p>
        <p>&nbsp;</p>
        <p>When you contact us by e-mail, the data you provide (your e-mail address, possibly your name and your
          telephone number) will be saved by us to answer your questions. We delete the data arising in this context
          after the storage is no longer necessary, or restrict the processing if there are statutory retention
          requirements. The same applies if you use our contact form. To contact us in this way, all you need to do is
          provide your name and email address. Further information – such as B. Your company and telephone number are
          voluntary. Data that you enter in our contact form is encrypted (SSL) between your browser and our server.</p>
        <p>&nbsp;</p>
        <p>Processing takes place on the basis of Art. 6 Para. 1 S. 1 lit. a) GDPR. It only serves to process and answer
          your request.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.6 Google Ads</strong></p>
        <p>&nbsp;</p>
        <p>We use the offer of Google Ads to draw attention to our attractive offers with the help of advertising
          material (so-called Google Ads) on external websites. In relation to the data of the advertising campaigns, we
          can determine how successful the individual advertising measures are. We are therefore interested in showing
          you advertising that is of interest to you, to make our website more interesting for you and to achieve a fair
          calculation of advertising costs.</p>
        <p>These advertising materials are delivered by Google via so-called “ad servers”. For this purpose, we use ad
          server cookies, through which certain parameters for measuring success, such as the insertion of ads or clicks
          by users, can be measured. If you access our website via a Google ad, Google Ads stores a cookie on your PC.
          These cookies usually lose their validity after 30 days and should not be used to identify you personally. For
          this cookie, the unique cookie ID, number of ad impressions per placement (frequency), last impression
          (relevant for post-view conversions) and opt-out information (marking that the user does not wants to be
          addressed more).</p>
        <p>&nbsp;</p>
        <p>These cookies enable Google to recognize your internet browser. If a user visits certain pages of the website
          of an Ads customer and the cookie stored on their computer has not yet expired, Google and the customer can
          recognize that the user clicked on the ad and was redirected to this page. A different cookie is assigned to
          each Ads customer. Cookies cannot therefore be tracked via the websites of Ads customers. We ourselves do not
          collect and process any personal data in the advertising measures mentioned. We only receive statistical
          evaluations from Google. Based on these evaluations, we can see which of the advertising measures used are
          particularly effective. We do not receive any further data from the use of the advertising material, in
          particular we cannot identify the users based on this information.</p>
        <p>&nbsp;</p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the Google
          server. We have no influence on the scope and further use of the data collected by Google through the use of
          this tool and therefore inform you according to our level of knowledge: By integrating Ads Conversion, Google
          receives the information that you are the corresponding part of our website called up or clicked on one of our
          ads. If you are registered with a Google service, Google can assign the visit to your account. Even if you are
          not registered with Google or have not logged in, there is a possibility that the provider will find out and
          save your IP address.</p>
        <p>&nbsp;</p>
        <p>You can prevent participation in this tracking process in various ways: a) by setting your browser software
          accordingly, in particular the suppression of third-party cookies means that you will not receive any
          advertisements from third-party providers; b) by deactivating the cookies for conversion tracking, by setting
          your browser so that cookies from the domain “www.googleadservices.com” are blocked,
          https://www.google.de/settings/ads, this setting be deleted when you delete your cookies; c) by deactivating
          the interest-based ads of the providers that are part of the self-regulation campaign “About Ads” via the link
          http://www.aboutads.info/choices, this setting being deleted if you delete your cookies; d) by permanent
          deactivation in your browsers Firefox, Internet Explorer or Google Chrome under the link
          http://www.google.com/settings/ads/plugin. We would like to point out that in this case you may not be able to
          use all functions of this offer to their full extent.</p>
        <p>&nbsp;</p>
        <p>The legal basis for the processing of your data is Art. 6 Para. 1 S. 1 lit. f GDPR. Further information on
          data protection at Google can be found here: http://www.google.com/intl/de/policies/info and
          https://services.google.com/sitestats/de.html. Alternatively, you can visit the website of the Network
          Advertising Initiative (NAI) at http://www.networkadvertising.org. Google has submitted to the EU-US info
          Shield, https://www.infoshield.gov/EU-US-Framework.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>1.7 Facebook</strong></p>
        <p>&nbsp;</p>
        <p>This website uses social media features from Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA.
          You can share content on the social network of Facebook using the Facebook Like buttons – Facebook Like
          button. The legal basis for processing the data is Art. 6 para. 1 sentence 1 lit. f GDPR) the legitimate
          interest in optimizing the website. When calling up a page with an integrated Facebook Like button, a
          connection is established between the browser used and the Facebook servers. In order to prevent this
          immediate connection, this website uses a solution in which this transmission only takes place when the
          Facebook Like button is pressed. Data is only transferred to Facebook the second time the Facebook Like button
          is pressed. If you have a Facebook account, this data can be linked to it. If you do not want this data to be
          assigned to your Facebook account, please log out of Facebook before visiting our site and delete the cookies
          in your browser. Facebook has submitted to the data protection agreement “info Shield” between the USA and the
          EU. You can find out more about Facebook’s data protection at https://de-de.facebook.com/info/explanation</p>
        <p>&nbsp;</p>
        <p><strong>1.8 Application process</strong></p>
        <p>We process your personal data in accordance with the provisions of the European General Data Protection
          Regulation (EU GDPR) and the Federal Data Protection Act (BDSG), insofar as this is necessary for the decision
          on establishing an employment relationship with us.</p>
        <p>
          The legal basis is Art. 88 GDPR i. V. m. § 26 BDSG-new and, if necessary, Art. 6 para. 1 lit. b GDPR for the
          initiation or implementation of contractual relationships.<br>
          Furthermore, we can process your personal data if this is necessary to fulfill legal obligations (Art. 6 Para.
          1 lit. c GDPR) or to defend against legal claims asserted against us. The legal basis is Art. 6 Para. 1 lit. f
          GDPR. The legitimate interest is, for example, an obligation to provide evidence in a procedure under the
          General Equal Treatment Act (AGG). If you give us express consent to the processing of personal data for
          certain purposes, the legality of this processing is based on your consent in accordance with Art. 6 Para. 1
          lit. a GDPR. A given consent can be revoked at any time with future effect (see section 9 of this data
          protection information).
        </p>
        <p>If there is an employment relationship between you and us, we can in accordance with Art. 88 GDPR i. V. m.
          Section 26 BDSG-new further process the personal data you have already received for the purposes of the
          employment relationship, insofar as this is necessary for the implementation or termination of the employment
          relationship or for the exercise or fulfillment of a law or a collective agreement, a company or service
          agreement (collective agreement) resulting rights and obligations of employee representation of interests is
          required.</p>
        <p>We only process data related to your application. This can be general data about you (name, address, contact
          details, etc.), information about your professional qualifications and school education, information about
          further vocational training as well as any other data that you send us in connection with your
          application.</p>
        <p>Your personal application data is collected and processed exclusively for the purpose of filling positions
          within our company. As a matter of principle, your data will only be forwarded to the internal departments and
          specialist departments of our company responsible for the specific application process, and processed by our
          service providers for application management JOIN and Consulting Heads if you submit your application via the
          respective provider.</p>
        <p>Your personal data will be processed on our behalf on the basis of order processing contracts according to
          Art. 28 GDPR. In these cases, we ensure that the processing of personal data is carried out in accordance with
          the provisions of the GDPR. There is no further disclosure to third parties.</p>
        <p>
          Your personal application data will generally be deleted automatically six months after the application
          process has been completed. This does not apply if statutory provisions prevent deletion, further storage is
          necessary for the purpose of providing evidence or you have expressly agreed to longer storage.<br>
          If we cannot offer you a position that is currently vacant, but based on your profile we believe that your
          application may be interesting for future job offers, we will save your personal application data for twelve
          months, unless you expressly consent to such storage and use.
        </p>
        <p>Further information on data protection at JOIN &amp; Consulting Heads can be found here:
          https://join.com/de-de/info and https://www.consultingheads.com/de/info.</p>
        <p><strong style="margin-top: 30px;">2. Disclosure of data</strong></p>
        <p>&nbsp;</p>
        <p>We only pass on the personal data we collect to third parties if this is justified by data protection law.
          You will find specific information on the scope and recipients in the above provisions. For the rest, we only
          give your personal data to other companies and individuals that we have commissioned to perform individual
          tasks and services for us. The transfer of your personal data to these companies is limited to what is
          necessary to carry out their work. These companies are, as it were, bound by the data protection regulations,
          have been carefully selected by us and are regularly monitored by us. In addition, we only pass on your
          personal data to third parties if we are legally or by court order obliged to do so.</p>
        <p>&nbsp;</p>
        <p><strong style="margin-top: 30px;">3. Data security</strong></p>
        <p>&nbsp;</p>
        <p>To protect your personal data, we take measures to protect your data against unauthorized access, loss,
          misuse or destruction. However, we would like to point out that data transmission over the Internet (e.g. when
          communicating by email) can have security gaps. It is not possible to completely protect data from access by
          third parties.</p>
        <p>&nbsp;</p>
        <p>Personal data is only stored for the period of time required to achieve the storage purpose, or if this has
          been provided for by law. Accordingly, the personal data will be routinely blocked or deleted if the storage
          purpose no longer applies or if a statutory period expires</p>
        <p>&nbsp;</p>
        <p>
          <strong style="margin-top: 30px;">4. Your rights / contact<br>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>You have the following rights towards Excubate regarding your personal data that we collect:</p>
        <p>&nbsp;</p>
        <ul style="padding-left: 18px;">
          <li>Right to information</li>
          <li>Right to correction or deletion</li>
          <li>Right to restriction of processing</li>
          <li>Right to object to processing</li>
          <li>Right to data portability.</li>
        </ul>
        <p>&nbsp;</p>
        <p>If you have given your consent to the processing of your personal data, you can revoke it at any time. This
          does not affect the lawfulness of processing based on consent before its withdrawal.</p>
        <p>&nbsp;</p>
        <p>Insofar as we base the processing of your personal data on the balancing of interests (Art. 6 Para. 1 Clause
          1 lit. f GDPR), you can object to the processing. When exercising such an objection, we ask you to explain the
          reasons why we should not process your personal data as we have done. In the event of your justified
          objection, we will examine the situation and will either discontinue or adjust the data processing or show you
          our compelling reasons worthy of protection on the basis of which we will continue the processing.</p>
        <p>&nbsp;</p>
        <p>Of course, you can object to the processing of your personal data for advertising and data analysis purposes
          at any time. You can inform us about your objection to advertising using the following contact details:
          Excubate GmbH, Dillenburger Strasse 93, 51105 Cologne, Germany, Tel .: +49 (0) 221 4558 0332, email:
          innovate@excubate.de</p>
        <p>&nbsp;</p>
        <p>You also have the right to complain to a data protection supervisory authority about the processing of your
          personal data by us.</p>
        <p>&nbsp;</p>
        <p>The person responsible within the meaning of Article 4 (7) GDPR is Excubate GmbH, Dr. Markus Anding, Tammo
          Ganders, Dillenburger Straße 93, 51105 Cologne, Germany, Tel .: +49 (0) 221 4558 0332,</p>
        <p>Email: innovate@excubate.de</p>
      </slot>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RegisterPopInfo',
  props: {
    title: String
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    handleKnowMore() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false
    }
  }
};
</script>

<style lang="less">
.register__pop-info {
  background-color: white;

  & .ant-modal-header {
    background-color: white;

    & .ant-modal-title {
      text-align: center;
      font-family: Arial;
      font-weight: 400;
      font-size: 25.93451px;
      color: rgb(0, 0, 0);
    }
  }

  &-text {
    font-family: "Arial";
    font-weight: 400;
    font-size: 10.972292px;
    color: rgb(0, 0, 0);

    & h2 {
      font-family: "Arial";
      font-weight: 400;
      color: rgb(0, 0, 0);
      font-size: 19.949622px;
    }
  }
}
</style>
