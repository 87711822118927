const projectStatus = {
    0: 'No Invitation',
    1: 'Invitation Sent',
    2: 'DVC Started and Saved',
    3: 'DVC Completed',

}


export { projectStatus };
