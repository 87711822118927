<template>
  <div class="client__portfolio-list">
    <div class="client__portfolio-list__header">
      <div class="client__portfolio-list__header__title">
        <a-button class="client__portfolio-list__header__title-btn"
                  type="primary"
                  icon="left"
                  size="large"
                  @click="handleBack"
        />
        <h2 class="client__portfolio-list__header__title-name">
          {{ clientInfor.client_name }}
          Portfolios overview
        </h2>
      </div>
      <div class="client__portfolio-list__header__btns">
        <a-button class="client__portfolio-list__header__btn client__portfolio-list__header__meta-data-btn"
                  type="default"
                  size="large"
                  @click="toDefineMeta"
        >
          <MetaDataSvg class="client__portfolio-list__header__btn-svg"/>
          DEFINE META DATA
        </a-button>
        <a-button class="client__portfolio-list__header__btn client__portfolio-list__header__add-btn"
                  type="primary"
                  size="large"
                  :style="{'overflow': 'hidden'}"
                  @click="handleAdd"
        >
          <a-icon type="plus"/>
          Add Portfolio
          <LimitAccess/>
        </a-button>
      </div>
    </div>
    <a-table class="client__portfolio-list__table"
             :columns="columns"
             :dataSource="data"
             :loading="loading">
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
              v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>{{ text }}</template>
      </template>

      <span slot="portfolio_name" slot-scope="portfolio_name, record">
        <a @click.prevent="projectList(record)">{{ record.portfolio_name }}</a>
      </span>
      <div slot="tags" slot-scope="status, record">
        <a-tag
            style="cursor: default"
            :color="status==0 || status==3? 'volcano' : (status==1 ? 'geekblue' : 'green')"
        >{{ portfolioStatus[status] }}
        </a-tag>
        <div v-if="record.status==4">Closed on: {{ record.close_date }}</div>
      </div>
      <span slot="action" slot-scope="record">
        <div class="action-group">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Define Target State of Portfolio</span>
            </template>
            <a-button
                type="primary"
                size="default"
                v-if="showHeatmap"
                @click.prevent="editTargetState(record._id)"
            >
              <a-icon type="pie-chart"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Edit Portfolio</span>
            </template>
            <a-button type="primary" size="default" @click.prevent="editRecord(record._id)">
              <a-icon type="edit"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Show Heatmap</span>
            </template>
            <a-button
                type="primary"
                size="default"
                v-if="showHeatmap"
                @click.prevent="record.status == 3 ? heatmap(record._id) : delta(record._id)"
            >
              <a-icon type="eye"/>
            </a-button>
          </a-tooltip>
        </div>
      </span>
    </a-table>
    <LimitAccessPop ref="limitAccess"/>
  </div>
</template>

<script>
import { portfolioStatus } from "../../staticData/PortofioStatus";
import LimitAccessPop from '../../components/LimitAccess/LimitAccessPop'
import LimitAccess from '../../components/LimitAccess/LimitAccess'
import MetaDataSvg from '../../components/MetaData/MetaDataSvg.vue';
import moment from "moment";
import { mapGetters } from 'vuex'

export default {
  name: 'ClientPortfolioList',

  components: {
    LimitAccessPop,
    LimitAccess,
    MetaDataSvg
  },

  data() {
    return {
      data: [],
      columns: [
        {
          title: "Portfolio Name",
          dataIndex: "portfolio_name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "portfolio_name"
          },
          onFilter: (value, record) => {
            return record.portfolio_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "Creation Date",
          dataIndex: "create_date"
        },
        {
          title: "Status",
          dataIndex: "status",
          scopedSlots: { customRender: "tags" },
          filters: [
            { text: "No Target State defined", value: "3" },
            { text: "Waiting to begin", value: "0" },
            { text: "Currently Ongoing", value: "1" },
            { text: "All responses received", value: "2" },
            { text: "Portfolio closure", value: "4" }
          ],
          onFilter: (value, record) => record.status.toString().includes(value)
        },
        {
          title: "# of Projects",
          dataIndex: "projectsCount",
        },
        {
          title: "Action",
          scopedSlots: { customRender: "action" },
        }
      ],
      loading: true,
      client_id: this.$route.params.client_id,
      clientInfor: {
        client_name: ''
      },
      portfolioStatus,
      showHeatmap: false,
      editRecordRight: [
        this.$userRole.EXCUBATE_ADMIN,
        this.$userRole.EXCUBATE_EMPLOYEE,
        this.$userRole.CLIENT_DTO
      ].includes(this.getUserRole)
    };
  },

  computed: mapGetters(['getUserInfor', 'getUserRole']),

  methods: {
    toDefineMeta() {
      const { client_id } = this

      this.$router.push({
        name: 'meta-data',
        params: { client_id }
      })
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()

      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async fetch() {
      this.loading = true

      const { client_id } = this

      try {
        const { data } = await this.$http.get(`/admin/api/rest/client/${client_id}`)

        this.clientInfor = data
      } catch (error) {
        this.$message.error(error.response.data.message)
      }

      try {
        const { data } = await this.$http.get(`/admin/api/rest/portfolio/client/${client_id}`)

        this.data = data.map(value => {
          value.key = value._id;
          value.create_date = moment(value.create_date).format("YYYY-MM-DD");
          value.start_date = moment(value.start_date).format("YYYY-MM-DD");
          value.projectsCount = value.projects?.length ?? 0

          if (value.close_date) {
            value.close_date = moment(value.close_date).format("YYYY-MM-DD")
          }

          return value
        })
      } catch (error) {
        this.$message.error(error.response.data.message)
      } finally {
        this.loading = false
      }
    },

    handleAdd() {
      if (this.getUserInfor?.limited_access === true) {
        this.$refs.limitAccess.handleKnowMore()
      } else {
        const { client_id } = this

        this.$router.push({
          name: "add-portfolio",
          params: { client_id }
        })
      }
    },

    editTargetState(portfolio_id) {
      this.$router.push({
        name: "portfolio-target-state",
        params: { portfolio_id }
      })
    },

    editRecord(portfolio_id) {
      this.$router.push({
        name: "edit-portfolio",
        params: { portfolio_id }
      })
    },

    projectList({ _id: portfolio_id }) {
      this.$router.push({
        name: "portfolio",
        params: { portfolio_id }
      })
    },

    handleBack() {
      this.$router.go(-1);
    },

    heatmap(portfolio_id) {
      this.$router.push({
        name: "portfolio-heatmap",
        params: { portfolio_id }
      })
    },

    delta(portfolio_id) {
      this.$router.push({
        name: "portfolio-delta",
        params: { portfolio_id }
      })
    }
  },

  created() {
    if (this.editRecordRight) {
      this.columns.push({
        title: "Action",
        scopedSlots: { customRender: "action" }
      })
    }

    this.fetch()

    this.showHeatmap = [
      this.$userRole.EXCUBATE_ADMIN,
      this.$userRole.EXCUBATE_EMPLOYEE,
      this.$userRole.CLIENT_DTO
    ].includes(this.getUserRole)
  }
};
</script>

<style scoped>
.action-group .ant-btn {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>

<style lang="less">
.client__portfolio-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
        margin-left: 15px;
      }
    }

    &__btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__svg {
      margin-right: 10px;
    }

    &__btn {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;

      &-svg {
        margin-right: 10px;
      }
    }

    &__add-btn {
      padding-right: 30px;
    }

    &__meta-data-btn {
      border-color: rgb(87, 172, 50);
      color: rgb(87, 172, 50);
      background-color: #fff;

      & .client__portfolio-list__header__btn-svg {
        fill: rgb(87, 172, 50) !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover .client__portfolio-list__header__btn-svg {
        fill: #95d46c !important;
      }
    }

    & .ant-btn {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  &__table {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

    & .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      overflow-wrap: anywhere !important;
      hyphens: auto;
      line-break: normal;
    }

    & .ant-table-column-title {
      background-color: #FAFAFA;
      font-family: Helvetica;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-row {
      font-family: Helvetica;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.232143px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-pagination {
      margin-right: 20px;
    }

    & .ant-tag {
      margin: 0 !important;
    }

    &__action {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      &-item {
        margin-right: 10px !important;
        margin-bottom: 5px !important;
      }
    }
  }
}
</style>
