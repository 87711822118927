<template>
  <div>
    <a-row>
      <a-col :span="24"
      >
        <h1>
          Welcome!
        </h1>
      </a-col>
    </a-row>
    <a-row style="margin-top: 20px;">
      <a-col :span="24">
        You have been invited to rate your project’s digital value creation. The Digital Value Canvas is a structured
        approach that can serve as basis for all project evaluations. Click on one of its three rings to find out more
        about the different levels of value creation.
      </a-col>
    </a-row>
    <a-row type="flex" align="middle">
      <a-col :span="12">
        <echarts-doughnut-chart
            ref="chart"
            :canvasSegments="canvasSegments"
            v-on:onClickSegment="onClickSegment"
        ></echarts-doughnut-chart>
      </a-col>
      <a-col :span="12">
        <canvas-introduction-modal
            ref="modal"
            :modalData="modalData"
            :visible="visible"
            @hide="hideModal"
        ></canvas-introduction-modal>
      </a-col>
    </a-row>
    <a-row justify="end">
      <a-col :span="6" :offset="18">
        <a-button
            type="primary"
            shape="primary"
            icon="right"
            size="large"
            @click="myProject()"
        >
          Next
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import EchartsDoughnutChart from "../../components/EchartsDoughnutChart";
import CanvasIntroductionModal from "../../components/CanvasIntroductionModal";

export default {
  name: 'TutorialDVCIntroduction',
  components: {
    EchartsDoughnutChart,
    CanvasIntroductionModal
  },
  data() {
    return {
      canvasSegments: {
        'goodway': [{
          value: 60, name: "", point: 0, introduction: `
        <b>Goodwill-type, less quantifiable benefits</b>
        <ul class="modal-ul">
          <li>Digital products can contribute to overall strategic business elements</li>
          <li>These are typically not easily quantifiable but impact long-term success</li>
        </ul>
        `
        }],
        'indirect': [{
          value: 60, name: "", point: 0, introduction: `
        <b>Indirect, but quantifiable commercial benefits</b>
        <ul class="modal-ul">
          <li>Digital products can have effects on multiple elements of the core value chain, such as processes or core product sales</li>
          <li>Benefits can mostly be quantified</li>
        </ul>
        `
        }],
        'direct': [{
          value: 60, name: "", point: 0, introduction: `
        <b>Direct commercial benefits</b>
        <ul class="modal-ul">
          <li>Digital product is sold for a specific price, generates direct revenue</li>
          <li>Digital product directly reduces cost (e.g. replacing a formerly manual process or more expensive tool)</li>
        </ul>
        `
        }],
      },
      isEdit: false,
      project_id: "",
      projectInfor: {},
      modalData: {
        title: "Awsome!!",
        point: "",
        metrics: "",
        explanation: "",
      },
    };
  },
  async created() {
    this.initSegmentColor();
  },
  methods: {
    initSegmentColor() {
      let segmentBgColor = "";
      for (const key of Object.keys(this.canvasSegments)) {
        for (const value of this.canvasSegments[key]) {
          // value.point = 0;
          // value.metrics = "";
          // value.explanation = "";
          value._id = null;
          if (key == "goodway") segmentBgColor = "#C8E9B5";
          else if (key == "indirect") segmentBgColor = "#75C945";
          else segmentBgColor = "#2C5018";
          value.itemStyle = { color: segmentBgColor, borderWidth: 0  };
        }
      }
    },
    onClickSegment(...args) {
      this.selectedSegment = args[0][0];

      // this.modalData.title = this.dataLabels[ele._datasetIndex][ele._index];
      // const seriseName = args[0][0].seriesName;
      if (args[0][0].seriesName == 'direct')
        this.modalData.title = 'Inner Ring';
      else if (args[0][0].seriesName == 'indirect')
        this.modalData.title = 'Middle Ring';
      else if (args[0][0].seriesName == 'goodway')
        this.modalData.title = 'Outer Ring';

      this.modalData.introduction = this.selectedSegment.data.introduction;
      this.showModal();
    },
    showModal() {
      this.$refs["modal"].showForm();
    },
    hideModal() {
      this.$refs["chart"].unHighlightSegment();
    },
    myProject() {
      this.$router.push({
        name: "tutorial-dpo-project-landing-page"
      });
    },
    handleBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
ul.modal-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

ul.modal-ul li {
  padding-left: 1em;
  text-indent: -.7em;
}

ul.modal-ul li::before {
  content: "• ";
  color: #75c845; /* or whatever color you prefer */
  font-size: 18px;
}
</style>
