const canvasSegments = {
    goodway: [
        {
            value: 60,
            name: "Brand\nAwareness",
            data_index: 7,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Improving stakeholder perception, e.g. through the establishment of a digital lab in Berlin",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Employer\nBranding",
            data_index: 1,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Becoming more attractive to employees, e.g. through a dedicated employer branding app",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Agile Culture &\nOrganizational\nLearning",
            data_index: 2,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Developing new capabilities and expertise through working with new technologies, e.g. learning from MVP-based product development",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Technology &\nData Expertise",
            data_index: 3,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Applying new technologies and insights from data can lead to new digital products, e.g. the adoption of AI technology",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Ecological &\nSocial\nSustainability",
            data_index: 4,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Economic value is increasingly driven by sustainable business practices, e.g. through avoiding future CO2 taxes",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Strategic\nBets",
            data_index: 5,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Generating direct revenue in the future through an early investment e.g. through early investments in blockchain technology",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Equity\nStory",
            data_index: 6,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Highlighting the digital agenda and achievements to increase the company valuation, e.g. through the announcement of a new blockchain business model",
            sector_meta_data: {}
        },
    ],
    indirect: [
        {
            value: 60,
            name: "Core\nProduct\nSales",
            data_index: 12,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Complementing the core products and thus resulting in more sales of the core product, e.g. via additional functionality, data and insights provision that drive more efficient usage",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Employee\nEffectiveness",
            data_index: 8,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Enabling employees to communicate faster and more effectively through agile working modes, e.g. through an online customer training tool",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Process Speed\nand Quality",
            data_index: 9,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Supporting higher efficiency and output quality, e.g. leveraging augmented reality technology for machinery servicing",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Equipment\nUtilization",
            data_index: 10,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Streamlining machinery utilization through new digital products, e.g. via a corporate internal capacity market places to manage 3D printing capacity across production facilities",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Customer\nSatisfaction &\nRetention",
            data_index: 11,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Increasing customer satisfaction and thus retaining the customer for longer, e.g. monitoring machine status and collecting longitudinal data",
            sector_meta_data: {}
        },
    ],
    direct: [
        {
            value: 60,
            name: "Direct\nRevenue",
            data_index: 14,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Selling the product directly either with a recurring fee or a one-time payment, e.g. a status monitoring app for machinery users sold at a monthly fee or data generated with a digital app that can be monetized separately",
            sector_meta_data: {}
        },
        {
            value: 60,
            name: "Direct Cost\nReduction",
            data_index: 13,
            point: 0,
            explanation: "",
            metrics: "",
            equity_impact: "",
            introduction: "Using internal efficiency gains to reduce costs and thus rationalize the provision of the product without a fee, e.g. less service effort due to a predictive maintenance solution",
            sector_meta_data: {}
        },
    ],
};

const canvasColors = {
    1: "#f2faed",
    2: "#e4f4da",
    3: "#d6efc7",
    4: "#c8e9b5",
    5: "#bae4a2",
    6: "#acde8f",
    7: "#9ed97d",
    8: "#90d36a",
    9: "#82ce57",
    10: "#75c945",
    11: "#6dbc3f",
    12: "#65b03a",
    13: "#5da335",
    14: "#559730",
    15: "#4e8b2b",
    16: "#467e25",
    17: "#3e7220",
    18: "#36651b",
    19: "#2e5916",
    20: "#274d11",
};
const segmentLabels = Object.entries(canvasSegments)
        .reduce((occ, [, value]) => {
            occ.push(value);
            return occ;
        }, [])
        .reduce((occ, value) => {
            occ = occ.concat(value);
            return occ;
        }, [])
        .map((value) => value.label);

export { canvasSegments, segmentLabels, canvasColors };
