<template>
  <div class="admin-panel__user-active">
    <div class="admin-panel__user-active__header">
      <div class="admin-panel__user-active__header__title">
        <a-button class="admin-panel__user-active__header__title-btn"
                  type="primary"
                  icon="left"
                  size="large"
                  @click="handleBack"
        />
        <UserActiveSvg class="admin-panel__user-active__header__title-svg"/>
        <h2 class="admin-panel__user-active__header__title-name">
          {{ Userinfor.user_name }}
          Activity
        </h2>
      </div>
    </div>
    <div class="admin-panel__user-active__content">
      <a-table class="admin-panel__user-active__table"
               :columns="columns"
               :dataSource="data"
               :loading="loading">
      </a-table>
    </div>
  </div>
</template>

<script>
import UserActiveSvg from '../../components/AdminPanel/UserActiveSvg'
import { LOG_TYPE } from '../../staticData/LogType.js'
import moment from "moment";

export default {
  name: 'UserActivity',
  components: {
    UserActiveSvg
  },
  data() {
    return {
      data: [],
      columns: [
        {
          title: "Date and Time",
          dataIndex: "create_date",
        },
        {
          title: "Event",
          dataIndex: "type",
        },
        {
          title: "IP",
          dataIndex: "ip"
        },
        {
          title: "Source",
          dataIndex: "user_agent",
        }
      ],
      Userinfor: {
        user_name: ''
      },
      user_id: null,
      LOG_TYPE,
      loading: true
    };
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    async fetch() {
      this.loading = true;
      this.$http.get(`/admin/api/user-list`)
          .then(resonse => {
            this.Userinfor = resonse.data.find(item => item._id === this.user_id);

          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          })
      this.$http.get(`/log/persons/${this.user_id}`)
          .then(resonse => {
            resonse.data.map(value => {
              value.key = value._id;
              if (value.create_date) {
                value.create_date = moment(value.create_date).format("YYYY-MM-DD/ h:mm A");
              }
              if (value.type) {
                for (const item of this.LOG_TYPE) {
                  if (value.type === item.type) {
                    value.type = item.text
                    break
                  }
                }
              }
              return value;
            });
            this.data = resonse.data;

          })
          .catch(error => {
            this.$message.error(error.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          })
    },
  },
  created() {
    this.user_id = this.$route.params.user_id
    this.fetch();
  }
};
</script>

<style lang="less">
.admin-panel__user-active {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        line-height: 16px;
        color: #FFFFFF;
      }

      &-svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      &-name {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        color: #2A2F37;
        width: max-content;
        height: max-content;
        margin: 0;
        margin-right: 30px;
      }
    }

    &-svg {
      margin-right: 10px;
    }

    &-btn {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 16px;
      color: #FFFFFF;

      &-svg {
        margin-right: 10px;
      }
    }
  }

  &__content {
    padding-top: 20px;
  }

  &__table {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px 3px 2px rgba(0, 0, 0, 0.2);

    & .ant-table-column-title {
      background-color: #FAFAFA;
      font-family: Helvetica;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-row {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.232143px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    & .ant-table-pagination {
      margin-right: 20px;
    }

    & .ant-tag {
      margin: 0 !important;
    }
  }
}
</style>
