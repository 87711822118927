var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password__verified"},[_vm._m(0),_c('div',{staticClass:"forgot-password__verified__content"},[_c('a-form',{staticClass:"forgot-password__verified__form",attrs:{"id":"components__form-demo-normal__forgot-password__verified","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',[_c('p',{staticClass:"forgot-password__verified__form-title"},[_vm._v(" Create new password ")])]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: 'You need to enter a password.'
                },
              ]
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  whitespace: true,\n                  required: true,\n                  message: 'You need to enter a password.'\n                },\n              ]\n            }\n          ]"}],attrs:{"placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: 'You need to confirm your password.'
                },
                {
                  validator: _vm.compareToFirstPassword
                },
              ]
            }
          ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  whitespace: true,\n                  required: true,\n                  message: 'You need to confirm your password.'\n                },\n                {\n                  validator: compareToFirstPassword\n                },\n              ]\n            }\n          ]"}],attrs:{"placeholder":"Confirm password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"forgot-password__verified__form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitLoading}},[_vm._v(" Create ")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password__verified__logo"},[_c('a',{attrs:{"href":"https://www.excubate.de","target":"_blank"}},[_c('img',{staticClass:"forgot-password__verified__logo-img",attrs:{"src":require("../../assets/excubate-logo-full-high-resolution-min.png")}})])])
}]

export { render, staticRenderFns }